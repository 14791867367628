import React, { ReactElement, useCallback, useState } from 'react';
import { Feather } from '@expo/vector-icons';
import { useFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { Platform } from 'react-native';
import { FormattedMessage } from 'react-intl';
import ModalHeader from '../../Component/ModalHeader';
import { intl } from '../../../Infrastructure/Intl';
import { CareshipColors } from '../../Component/CSTheme';
import FulfillBookingForm from './FulfillBookingForm/FulfillBookingForm';
import KeyboardAvoidingSafeScrollView from '../../Component/KeyboardAvoidingSafeScrollView';
import { useShouldShowBookingFeedback } from '../Hook';
import { FulfillBookingFlowFragment$key } from './__generated__/FulfillBookingFlowFragment.graphql';
import FulfillBookingFeedbackForm from './FulfillBookingFeedback/FulfillBookingFeedbackForm';
import { Container, Title } from '../../Component';

export default function FulfillBookingFlow({
  bookingFragment,
  onSubmit,
  ...props
}: {
  onSubmit: () => void;
  bookingFragment: FulfillBookingFlowFragment$key;
}): ReactElement {
  const [title, setTitle] = useState(intl.formatMessage({ id: 'LOG_APPOINTMENT_MODAL_TITLE' }));
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const booking = useFragment(
    graphql`
      fragment FulfillBookingFlowFragment on Booking {
        bookingId
        status
        startDate
        endDate
        paymentMethod
        surveyStatus
        contract {
          __typename
          careReceiver {
            name {
              firstName
              lastName
            }
          }
        }
      }
    `,
    bookingFragment,
  );
  const { shouldShowBookingSurvey } = useShouldShowBookingFeedback(
    booking.bookingId,
  );

  const handleSubmit = useCallback(() => {

    if (shouldShowBookingSurvey) {
      setTitle(intl.formatMessage({ id: 'BOOKING_FEEDBACK_MODAL_TITLE' }));

      setShowFeedbackForm(true);
      return;
    }

    onSubmit();
  }, [onSubmit, shouldShowBookingSurvey]);

  const { lastName, firstName } = booking.contract.careReceiver.name;

  return (

    <>
      <ModalHeader
        backButton={<Feather name="x" size={24} color={CareshipColors.primary} />}
        title={title}
      />
      <KeyboardAvoidingSafeScrollView>
        <>
          <Container vertical>
            <Title style={{ marginTop: Platform.OS === 'android' ? 32 : undefined }} center thin>
              <FormattedMessage id="NAME_FULL" values={{ firstName, lastName }} />
            </Title>
          </Container>
          {showFeedbackForm || (booking.status === "fulfilled" && booking.surveyStatus === 'false') ? (
            <FulfillBookingFeedbackForm bookingId={booking.bookingId} onSubmit={onSubmit} />
          ) : (
            <FulfillBookingForm booking={booking} onSubmit={handleSubmit} specialSubmit = {onSubmit} {...props} />
          )}
        </>
      </KeyboardAvoidingSafeScrollView>
    </>
  );
}

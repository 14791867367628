/**
 * @generated SignedSource<<b68a203b3a4c51b9265b1db768d67f0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FulfillBookingScreenQuery$variables = {
  bookingId: string;
};
export type FulfillBookingScreenQuery$data = {
  readonly booking: {
    readonly " $fragmentSpreads": FragmentRefs<"FulfillBookingFlowFragment">;
  } | null;
};
export type FulfillBookingScreenQuery = {
  variables: FulfillBookingScreenQuery$variables;
  response: FulfillBookingScreenQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bookingId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "bookingId",
    "variableName": "bookingId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FulfillBookingScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Booking",
        "kind": "LinkedField",
        "name": "booking",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FulfillBookingFlowFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FulfillBookingScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Booking",
        "kind": "LinkedField",
        "name": "booking",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bookingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentMethod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "surveyStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Contract",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CareReceiver",
                "kind": "LinkedField",
                "name": "careReceiver",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Name",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "668c97cf3eec07a85e7ba4042a4a5465",
    "id": null,
    "metadata": {},
    "name": "FulfillBookingScreenQuery",
    "operationKind": "query",
    "text": "query FulfillBookingScreenQuery(\n  $bookingId: ID!\n) {\n  booking(bookingId: $bookingId) {\n    ...FulfillBookingFlowFragment\n    id\n  }\n}\n\nfragment FulfillBookingFlowFragment on Booking {\n  bookingId\n  startDate\n  endDate\n  paymentMethod\n surveyStatus\n contract {\n    __typename\n    careReceiver {\n      name {\n        firstName\n        lastName\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "348346ae8c39ca10beaeb4c897bbaf3e";

export default node;

import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { Platform } from 'react-native';
import { CSTheme } from './Component';
import { CareshipColors } from './Component/CSTheme';

const screenOptions: NativeStackNavigationOptions = {
  headerHideShadow: true,
  headerLargeTitleStyle: {
    ...CSTheme.fonts.medium,
  },
  headerBackTitleStyle: {
    ...CSTheme.fonts.medium,
  },
  headerTitleStyle: {
    ...CSTheme.fonts.medium,
    color: CSTheme.colors.background,
  },
  headerStyle: {
    backgroundColor: CareshipColors.primary,
  },
  ...Platform.select({
    ios: {
      headerLargeTitle: false,
      headerLargeTitleStyle: {
        fontSize: 22,
      },
      headerTintColor: CSTheme.colors.background,
    },
    android: {
      headerTopInsetEnabled: false,
      headerTintColor: CSTheme.colors.background,
      headerTitleStyle: {
        fontSize: 22,
        color: CSTheme.colors.background,
      },
    },
    web: {
      headerShown: true,
      header: () => null,
    },
  }),
};

export default screenOptions;

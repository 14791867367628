import { createIntl, createIntlCache } from 'react-intl';
import * as Localization from 'expo-localization';
import { Text } from 'react-native';
import messages, { MessageKey } from './messages';

export declare interface MessageDescriptor {
  id?: MessageKey;
  description?: string | object;
  defaultMessage?: string;
}

// Cache is optional but prevents memory leak
const cache = createIntlCache();

const availableLocales: Record<keyof typeof messages, string> = { de: 'de-DE', en: 'en-GB' };
type LangCode = keyof typeof availableLocales;
const isValidLangCode = (lc: string): lc is LangCode => lc in availableLocales;

// Fallback to DE if there's no messages for user's locale
let langCode: LangCode = 'de';
const userLanguageCode: string = Localization.locale.split('-')[0];
if (isValidLangCode(userLanguageCode)) {
  langCode = userLanguageCode;
}
/**
 * Providing intl for usage outside react components, such as in the tab navigator
 */
const intl = createIntl(
  {
    locale: availableLocales[langCode],
    defaultLocale: availableLocales.de,
    messages: messages[langCode],
    textComponent: Text,
  },
  cache,
);

export default intl;

import React, { useEffect, useState, useCallback } from 'react';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { isPast } from 'date-fns';
import { useIsFocused } from "@react-navigation/native";

import { BookingStatus } from '../../../Context/Booking/Store/Model/Booking';
import { useFocusEffect } from '../../../Infrastructure/Hook';
import useBookings from '../../../Context/Booking/Hook/useBookings';
import ProposalContractSectionList from '../Component/ProposalContractSectionList';
import { CustomerListScreenQuery } from './__generated__/CustomerListScreenQuery.graphql';
import { withLayout } from '../../HOC';
import DocumentModal from '../../common/DocumentModal';
import useProfile from "../../../Context/Profile/Hook/useProfile";
import PlatformLocalStorage from "../../../Infrastructure/PlatformLocalStorage/PlatformLocalStorage";

const customerListQuery = graphql`
  query CustomerListScreenQuery {
    ...ProposalContractSectionList_query
  }
`;

function CustomerListScreen({
  preloadedQuery,
}: {
  preloadedQuery: PreloadedQuery<CustomerListScreenQuery>;
}) {
  const data = usePreloadedQuery<CustomerListScreenQuery>(customerListQuery, preloadedQuery);

  return <ProposalContractSectionList allProposalsAndContractsFragmentRef={data} />;
}

export default withLayout(() => {
  const { profile } = useProfile();
  const isInFocused = useIsFocused()
  const [preloadedQuery, loadQuery] = useQueryLoader<CustomerListScreenQuery>(customerListQuery);
  const [showModal, setShowModal] = useState(false);
  const [_, setIsDocumented] = useState(true);

  const filterBookings = useCallback(
      ({
         endDate,
         isUndocumented,
       }: {
        endDate: Date;
        status: BookingStatus;
        isUndocumented: boolean;
      }) => {
        return  isPast(endDate) && isUndocumented;
      },
      [setIsDocumented]
  )
  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: 'store-and-network',
      },
    );
  }, [loadQuery]);

  let hideModalUntil = {'hideModalUntil': 0};
  PlatformLocalStorage.getItem('hideModalUntil', hideModalUntil)
  if( profile?.isCareshipEmployee || (!profile?.isCareshipEmployee && Number(hideModalUntil.hideModalUntil) < Date.now()))
  {
    const { bookings: pastBookings, refresh: refreshPastBookings, undocumentedBookingCount } = useBookings(filterBookings);

    useFocusEffect(refreshPastBookings);

    useEffect(() => {
      if (isInFocused && undocumentedBookingCount > 0) {
        setShowModal(true);
      }
    }, [pastBookings.length, undocumentedBookingCount, isInFocused]);

    return (
      <>
        {preloadedQuery && (
          <>
            <DocumentModal
              showModal={showModal}
              setShowModal={setShowModal}
              pastBookings={pastBookings}
            />

            <CustomerListScreen preloadedQuery={preloadedQuery} />
          </>
        )}
      </>
    );
  }
  return (
    <>
      {preloadedQuery && (
        <>
          <CustomerListScreen preloadedQuery={preloadedQuery} />
        </>
      )}
    </>
  );
});

import {Animated, Image, Platform, StyleProp, StyleSheet, TextStyle, View} from 'react-native';
import {isToday, setMonth} from 'date-fns';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FormattedDate, FormattedMessage, FormattedNumber, useIntl} from 'react-intl';
import {AntDesign, Feather} from '@expo/vector-icons';
import { graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import styled from '@emotion/native';
import Booking, {
  BookingUnion,
  FirstBooking,
  NonFirstBooking,
} from '../../../../Context/Booking/Store/Model/Booking';
import Contract from '../../../../Context/Contract/Store/Model/Contract';
import {
  Alert,
  Button,
  CellLabel,
  Container,
  DividingHeader,
  Hr,
  Tag,
  Text,
  Title,
  Card,
  AddressRouteRow, ProgressBar,
} from '../../../Component';
import { CareshipColors } from '../../../Component/CSTheme';
import Row from '../../../Component/Row';
import MapWithMarker from '../MapWithMarker';
import styles from './BookingDetail.styles';
import { events, trackEvent } from '../../../../Infrastructure/Tracking/Tracking';
import { openMap } from '../../../../Infrastructure/LinkHandler';
import ContractCancelationIcon from '../../../Component/Icon/ContractCancelationIcon';
import getLabel from '../getLabel';
import { BookingDetailQuery } from './__generated__/BookingDetailQuery.graphql';
import Tooltip from '../../../Component/Tooltip';
import useProfile from '../../../../Context/Profile/Hook/useProfile';
import {useNavigation, useRoute} from "@react-navigation/native";
import {BookingStackNavigationProp} from "../../Navigator/BookingStackNavigationProp";
import {ActivityIndicator, IconButton} from 'react-native-paper';
import {
  CheckCircleFilledIcon, EyeIcon, UploadShareIcon,
} from '../../../Component/Icon';
import ListItemAction from "../../../Component/ListItemAction";
import {usePickAndUploadFile} from '../../Hook'
import UploadPictureInstructionDialog from "../UploadPictureInstructionDialog";
import {useBookingService} from "../../../../Context/Booking/Hook";
import WebView from "react-native-webview";
import Manifest from "../../../../Infrastructure/Constants/Manifest";
import Constants from "expo-constants";
import {isSuccess} from "../../../../Infrastructure/Service";


type PickBookingProperties<B extends BookingUnion | Booking> = Pick<
  B,
  | 'bookingId'
  | 'endDate'
  | 'status'
  | 'isFirstBooking'
  | 'firstBookingStatus'
  | 'hasNotEnded'
  | 'isDocumentingAllowed'
  | 'isFulfilled'
  | 'startDate'
  | 'pricing'
  | 'services'
  | 'otherServiceDescription'
  | 'paymentMethod'
  | 'surveyStatus'
  | 'isSignedViaPaper'
>;

/*export const bookingDetailQuery = graphql`
  query BookingDetailQuery($contractId: ID!) {
    contract(contractId: $contractId) {
      id
      serviceRequest {
        schedule {
          isFlexible
        }
      }
      ...FirstBookingSurveyButton_firstBookingSurvey
    }
  }
`;*/



const extraStyles = StyleSheet.create({
  buttonCancel: {
    borderWidth: 1,
    borderColor: CareshipColors.red,
    borderStyle: 'solid',
    marginRight: 8,
  },
});

export const bookingDetailQuery = graphql`
  query BookingDetailQuery($contractId: ID!) {
    contract(contractId: $contractId) {
      id
      serviceRequest {
        schedule {
          isFlexible
        }
      }
    }
  }
`;

const TouchableBanner = styled(View)<{
  borderRadiusTop?: boolean;
  borderRadiusBottom?: boolean;
  dividerBottom?: boolean;
}>(({ borderRadiusTop, borderRadiusBottom, dividerBottom }) => ({
  overflow: 'hidden',
  ...(borderRadiusTop && {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  }),
  ...(borderRadiusBottom && {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }),
  ...(dividerBottom && {
    marginBottom: 2,
  }),
}));

function removePrefix(base64String?:string) {
  // Split the base64 string at the comma to remove the prefix
  const [, base64Data] = base64String.split(',');
  return base64Data;
};

function getHtml(uri?: string) {
  return uri
    ? `<!DOCTYPE html>
    <html lang="en">
      <body style="height: 100vh; display: flex; align-items: flex-start; justify-content: center; background: #F2F2F7; padding: 16px">
        <img alt="" style="box-shadow: 0 0 8px #333c; max-width: 100%;" src="${uri}" />
      </body>
    </html>`
    : '';
}
const TouchableBannerContent = styled(View)(({ theme: { colors } }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: colors.slate050,
  padding: 16,
}));

const TouchableBannerText = styled(Text)({
  flex: 1,
  marginLeft: 8,
});

const TextWithTooltip = styled.View({
  display: 'flex',
  flexDirection: 'row',
});

const TooltipBodyText = styled.Text({
  color: CareshipColors.white,
});


const stylesheetCustom = StyleSheet.create({
  container: { flex: 1, position: 'relative' },
  browser: { flex: 1 },
  overlay: {
    position: 'absolute',
    backgroundColor: '#F2F2F7cc',
    top: 0,
    left: 0,
    width: '100%',
    bottom: 85,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonBar: {
    height: 84,
    padding: 16,
    alignItems: 'center',
    borderTopColor: CareshipColors.slate200,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  errorMessage: { color: CareshipColors.red },
});

const apiBackendUrl = Constants?.expoConfig?.extra?.apiBackendUrl;

const apiURI: string = Platform.OS === 'web' ? Manifest.extra.apiEndpoint : apiBackendUrl;

export default function BookingDetail({
  contract,
  booking: {
    bookingId,
    hasNotEnded,
    isDocumentingAllowed,
    isFulfilled,
    startDate,
    endDate,
    pricing,
    services,
    otherServiceDescription,
    isFirstBooking,
    paymentMethod,
    surveyStatus,
    isSignedViaPaper
  },
  booking,
  onPressFulfillBooking,
  onPressEditBooking,
  onPressCancelBooking,
}: {
  contract: Contract;
  booking: PickBookingProperties<FirstBooking> | PickBookingProperties<NonFirstBooking>;
  onPressFulfillBooking: () => void;
  onPressEditBooking: () => void;
  onPressCancelBooking: () => void;
}) {

  const intl = useIntl();
  const bookingService = useBookingService();
  const progressPercentage = useRef(new Animated.Value(0)).current;
  const [displayUploadPictureDialog, setDisplayUploadPictureDialog] = useState(false);


  const [uri, setUri] = useState<string | undefined>(undefined);
  const [noImage, setNoImage] = useState<boolean | undefined>(false);
  const [fromApi, setFromApi] = useState<boolean | undefined>(false);

  const { status, fileUri, showPickerAndUploadFile } = usePickAndUploadFile(
    booking.bookingId,
    () => {
      setFromApi(false)
    },
    () => {
      setFromApi(false)
    },
  );

  let actionViewIcon = <EyeIcon testID="upload-icon" />;
  let actionIcon = <UploadShareIcon testID="upload-icon" />;


  let labelStyle: StyleProp<TextStyle> = null;
  let actionLabel = <FormattedMessage id="SERVICE_DOCUMENTATION_UPLOAD_SHOW" />;

  if (status === 'failed') {
    actionLabel = <FormattedMessage id="SERVICE_DOCUMENTATION_UPLOAD_FAILED" />;
    labelStyle = {
      color: CareshipColors.red,
      marginRight: 8,
    };
  }


  const handlePressUploadPicture = () => {
    void (async () => {
        const response = await bookingService.getUploadImage({bookingId:booking.bookingId})

        if(isSuccess(response))
        {
          //const responses = await fetch(`${apiURI}/caregiver-app/service-documentation/download/${response.data.token_id}`);
          const imageResponse = await fetch(`${apiURI}/caregiver-app/service-documentation/download/${response.data.token_id}`)
          const data = await imageResponse.text();

          if(data.indexOf("error") < 0)
          {
            setUri(data)
            setNoImage(false)
            setFromApi(true)
            return;
          }
          setNoImage(true)
          setUri(undefined)
          setFromApi(false)
        }
        setNoImage(true)
        setUri(undefined)
        setFromApi(false)
      }
    )();
  };

  const handleAcceptUploadPictureDialog = useCallback(() => {
    setDisplayUploadPictureDialog(false);

    setTimeout(() => {
      void showPickerAndUploadFile();
    }, 300);
  }, [showPickerAndUploadFile]);

  const handleUploadUploadPicture = useCallback(() => {
    setDisplayUploadPictureDialog(true);
  }, []);

  const handleDismissUploadPictureDialog = useCallback(() => {
    setDisplayUploadPictureDialog(false);
  }, []);




  const {
    careReceiver: { address },
    cancelationScheduledFor,
    isActive,
  } = contract;
  const { profile } = useProfile();
  const route = useRoute();
  const navigation = useNavigation<BookingStackNavigationProp<'Diary'>>();

  const bookingInfo = {
    ...booking,
    endDate: new Date(booking.endDate),
    startDate: new Date(booking.startDate)
  }
  const handleBookingDeleted = useCallback(() => navigation.navigate('Diary'), [navigation]);
  const destinationAddress = `${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}`;

  const handleMapPress = useCallback(() => {
    openMap(destinationAddress);
    trackEvent(events.BOOKING_DETAIL_ROUTE_CLICK);
  }, [destinationAddress]);

  const { color, label, mode: mode } = getLabel(booking, profile?.isCareshipEmployee );


  const contractData = useLazyLoadQuery<BookingDetailQuery>(bookingDetailQuery, {
    contractId: contract.contractId,
  },{
    fetchPolicy: 'store-and-network',
  });

  const tooltipBoundaryRef = useRef<View>(null);

  return (
    <>
      <UploadPictureInstructionDialog
        visible={displayUploadPictureDialog}
        onAccept={handleAcceptUploadPictureDialog}
        onDismiss={handleDismissUploadPictureDialog}
      />
      {cancelationScheduledFor && (
        <View style={styles.contractCancelationHint}>
          <View style={styles.contractCancelationHintTitle}>
            <View>
              <ContractCancelationIcon color={CareshipColors.white} />
            </View>
            <View>
              <Text bold size="large" style={styles.contractCancelationHintTitleText}>
                <FormattedMessage
                  id={isActive ? 'COLLABORATION_ENDS_ON' : 'COLLABORATION_ENDED_ON'}
                  values={{
                    endsAt: (
                      <FormattedDate
                        value={cancelationScheduledFor}
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                      />
                    ),
                  }}
                />
              </Text>
            </View>
          </View>
        </View>
      )}
      <Container ref={tooltipBoundaryRef} style={[styles.container, styles.detailsContainer]}>
        {isFirstBooking && !profile?.isCareshipEmployee && (
          <View style={styles.firstAppointmentLabelRow}>
            <View>
              {contractData.contract?.serviceRequest.schedule.isFlexible ? (
                <TextWithTooltip>
                  <Text
                    uppercase
                    size="small"
                    bold
                    style={{ color: color || CareshipColors.slate400, alignSelf: 'center' }}
                  >
                    <FormattedMessage id="FIRST_APPOINTMENT" />
                  </Text>
                  <Tooltip boundary={tooltipBoundaryRef}>
                    <TooltipBodyText>
                      <FormattedMessage id="FLEXIBLE_APPOINTMENT_TOOLTIP_BODY" />
                    </TooltipBodyText>
                  </Tooltip>
                </TextWithTooltip>
              ) : (
                <Text
                  uppercase
                  size="small"
                  bold
                  style={{ color: color || CareshipColors.slate400, alignSelf: 'center' }}
                >
                  <FormattedMessage id="FIRST_APPOINTMENT" />
                </Text>
              )}
            </View>
            <View>
              {label && (
                <CellLabel color={color} style={styles.cellLabel} mode={mode}>
                  {label}
                </CellLabel>
              )}
            </View>
          </View>
        )}
        <View style={styles.appointmentDetailsRow}>
          <View>
            <Text style={styles.day}>
              {isToday(startDate) ? (
                <FormattedMessage id="TODAY" />
              ) : (
                <FormattedDate value={startDate} weekday="long" />
              )}
            </Text>
          </View>
          {label && (<CellLabel color={color} mode={mode}>{label} </CellLabel>)}
        </View>
        <View style={styles.appointmentDetailsRow}>
          <Title size="h1" style={[styles.date, !hasNotEnded && styles.muted]}>
            <FormattedDate value={startDate} month="long" day="2-digit" />
          </Title>
        </View>

        <View style={styles.durationRow}>
          <Text style={[styles.duration, hasNotEnded ? styles.upcomingDuration : styles.muted]}>
            <FormattedDate value={startDate} hour12={false} hour="2-digit" minute="2-digit" />
          </Text>
          <Text
            style={[
              styles.duration,
              styles.durationEnd,
              hasNotEnded ? styles.upcomingDuration : styles.muted,
            ]}
          >
            {' '}
            - <FormattedDate value={endDate} hour12={false} hour="2-digit" minute="2-digit" />
          </Text>
        </View>

        {isFulfilled && (
          <View style={styles.timeValueRow}>
            <View style={styles.timeValueCol}>
              <Tag>
                <FormattedMessage
                  id="HOURS_WITH_UNIT"
                  values={{
                    hours: (
                      <FormattedNumber
                        value={pricing.durationInMinutes / 60}
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                      />
                    ),
                  }}
                />
              </Tag>
              <Text style={styles.timeValueLabel} muted bold uppercase>
                <FormattedMessage id="WORKED" />
              </Text>
            </View>
            {!profile?.isCareshipEmployee && (
              <View style={styles.timeValueCol}>
                <Tag>
                  <FormattedNumber
                    value={pricing.grossPriceInCents / 100}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    currency="EUR"
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                  />
                </Tag>
                <Text style={styles.timeValueLabel} muted bold uppercase>
                  <FormattedMessage id="EARNED" />
                </Text>
              </View>
            )}
          </View>
        )}

      </Container>
      <Hr noMargins style={styles.marginTop} />

      {/*{isFirstBooking && contractData.contract && (
        <FirstBookingSurveyButton firstBookingSurveyFragment={contractData.contract} />
      )}*/}

      {hasNotEnded && (
        <>
          <AddressRouteRow
            onPress={handleMapPress}
            street={address.street}
            houseNumber={address.houseNumber}
            postalCode={address.postalCode}
            city={address.city}
          />
          <Container>
            <MapWithMarker
              cacheEnabled
              zoomEnabled={false}
              zoomTapEnabled={false}
              rotateEnabled={false}
              scrollEnabled={false}
              pitchEnabled={false}
              onPress={handleMapPress}
              style={styles.mapStyle}
              address={destinationAddress}
            />
          </Container>
        </>
      )}

      <Container style={styles.actionContainer}>
        {isDocumentingAllowed && (
          <Alert>
            <Text>
              <FormattedMessage id="DOCUMENT_BOOKING_ALERT_TITLE" />
            </Text>
            <Text style={{ marginTop: 16 }}>
              <FormattedMessage id="DOCUMENT_BOOKING_ALERT" />
            </Text>
          </Alert>
        )}

        <View style={styles.buttonGroup}>
          {isDocumentingAllowed && (
            <Button
              style={styles.actionBookingButton}
              size="sm"
              icon={<Feather color="white" size={24} name="edit" />}
              onPress={() => onPressFulfillBooking()}
            >
              <FormattedMessage id="BUTTON_DOCUMENT_BOOKING_NOW" />
            </Button>
          )}
          {Platform.OS === 'web' && !isFulfilled && (
            <Button
              style={styles.actionBookingButton}
              size="sm"
              icon={<Feather color="white" size={24} name="edit" />}
              onPress={() => onPressEditBooking()}
            >
              <FormattedMessage id="BUTTON_EDIT" />
            </Button>
          )}
        </View>
        <View style={styles.buttonGroup}>
        {isDocumentingAllowed && profile?.isCareshipEmployee && (
          <Button
            onPress={() => onPressCancelBooking()}
            testID="delete-booking-button"
            color="white"
            size="sm"
            style={{...styles.actionBookingButton, ...extraStyles.buttonCancel}}
            labelStyle={{ color: CareshipColors.red }}
            icon={<Feather color={CareshipColors.red} size={24} name="trash-2" />}
          >
            <FormattedMessage id="BOOKING_ACTION_CANCEL" />
          </Button>
        )}
        </View>
      </Container>
      {isFulfilled && (
        <>
          <Container style={styles.servicesRow}>
            <DividingHeader noGutter>
              <FormattedMessage id="SERVICES" />
            </DividingHeader>
          </Container>
          {services.map((service) => (
            <Row key={service.id}>
              <Text size="xlarge">{service.labelDe}</Text>
            </Row>
          ))}
          {Boolean(otherServiceDescription) && (
            <Row vertical>
              <Text size="small" style={{ marginBottom: 8 }}>
                <FormattedMessage id="OTHER_SERVICES" />
              </Text>
              <Text size="xlarge">{otherServiceDescription}</Text>
            </Row>
          )}
        </>
      )}
      {isFulfilled && profile?.isCareshipEmployee && booking.surveyStatus === 'false' && (
        <Container style={styles.servicesRow}>
        <View style={styles.buttonGroup}>
            <Button
              style={styles.actionBookingButton}
              size="sm"
              icon={<Feather color="white" size={24} name="edit" />}
              onPress={() => onPressFulfillBooking()}
            >
              <FormattedMessage id="COMPLETE_SURVEY" />
            </Button>
        </View>
        </Container>
      )}
      {isFulfilled && profile?.isCareshipEmployee && booking.isSignedViaPaper && (
        <Container style={styles.servicesRow}>
          <View>
            <DividingHeader noGutter>
              <FormattedMessage id="PERFORMANCE_DOCUMENTATION_TITLE" />
            </DividingHeader>

            <ListItemAction
              style={{ marginBottom: 32 }}
              actionLabel={<></>}
              actionIcon={<></>}
              actionIconLeft={Platform.select({
                native:
                    <>
                      {status === 'failed' && (
                        <Text style={{ color: CareshipColors.red }}>
                          <FormattedMessage id="SERVICE_DOCUMENTATION_UPLOAD_FAILED" />
                        </Text>
                      )}
                      {status === 'success' && (
                        <IconButton disabled={true} icon={CheckCircleFilledIcon} />
                      )}
                      {noImage && (
                        <Text style={{ color: CareshipColors.red }}>
                          <FormattedMessage id="PERFORMANCE_DOCUMENT_NO_IMAGE_FOUND" />
                        </Text>
                      )}
                      <IconButton onPress={handlePressUploadPicture} icon={EyeIcon} />
                      <IconButton onPress={handleUploadUploadPicture} icon={UploadShareIcon} />
                    </>,
                web:
                  <>
                    {status === 'failed' && (
                      <Text style={{ color: CareshipColors.red }}>
                        <FormattedMessage id="SERVICE_DOCUMENTATION_UPLOAD_FAILED" />
                      </Text>
                    )}
                    {status === 'success' && (
                      <IconButton  disabled={true} icon={CheckCircleFilledIcon} />
                    )}
                    {noImage && (
                      <Text style={{ color: CareshipColors.red }}>
                        <FormattedMessage id="PERFORMANCE_DOCUMENT_NO_IMAGE_FOUND" />
                      </Text>
                    )}
                    <IconButton onPress={handlePressUploadPicture} icon={EyeIcon} />
                    <IconButton onPress={handleUploadUploadPicture} icon={UploadShareIcon} />
                  </>
              })}
            >
              <Text>
                <FormattedMessage id="PERFORMANCE_DOCUMENTATION_DATE_FILE_HELP" /> { ' ' }
                <FormattedDate value={startDate} day={'numeric'} month={'numeric'} year={'numeric'}/>
              </Text>
            </ListItemAction>

          </View>


        </Container>
      )}
      <Container>
        <View>
          {fromApi && uri && (
            <Image source={{ uri: uri }} style={{height: 500}} resizeMode="contain" onError={(error) => console.error('Image load error:', error)} />
          )}

          {fileUri && !fromApi && (
            <Image source={{ uri: fileUri }} style={{height: 500}} resizeMode="contain" onError={(error) => console.error('Image load error:', error)} />
          )}
        </View>
      </Container>

    </>
  );
}

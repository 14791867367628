const messages = <const>{
  en: {
    UNDOCUMENTED_MODAL_TITLE: 'Undocumented appointments',
    UNDOCUMENTED_MODAL_DESCRIPTION: 'Please spare some time to document your appointments, your feedback is very important to us.',
    DOCUMENT_LATER_MODAL: ' Document later',
    DOCUMENT_NOW_MODAL: ' Document now',
    FAILED_TO_FETCH: 'Failed to fetch',
    ABSENCES_TITLE: 'Absences',
    ABSENCE_REASON: 'Reason',
    ABSENCE_EDIT_SCREEN_TITLE: 'Edit absence',
    ABSENCE_SCREEN_TITLE: 'Register new absence',
    ABSENCE_PERIOD: 'Period',
    ABSENCE_SAVE: 'Save',
    ABSENCE_TYPE: `{type, select,
      vacation {Vacation}
      sick {Sickness}
      other {Unspecified}
    }`,
    ABSENCE_DELETE_CONFIRM_TITLE: 'Really delete absence period?',
    ABSENCE_DELETE_CONFIRM_CONTENT:
      '{from} - {to}\nYou cannot undo the deletion of this absence period and may get new proposals during this period once deleted. ',
    ABSENCE_DELETE_CONFIRM_SUBMIT: 'Delete',
    ACCEPTED_ALERT_MESSAGE:
      'We are looking for the best possible match between customer and caregiver and will inform you as soon as possible.',
    ACCEPTED_ALERT_TITLE: 'You have applied',
    ACCEPT_PROPOSAL_CONFIRM_CANCEL: 'No, cancel',
    ACCEPT_PROPOSAL_CONFIRM_DESCRIPTION: 'Your potential customer relies on your promise',
    ACCEPT_PROPOSAL_CONFIRM_TITLE: 'Really confirm?',
    ACCEPT_PROPOSAL_CONFIRM_YES: 'Yes, accept',
    ACCOUNT: 'Account',
    ACTION_CALL: 'Call',
    ACTION_DIRECTIONS: 'Route',
    ACTION_EMAIL: 'Email',
    ACTION_MESSAGE: 'Message',
    ADDITIONAL_CONTACT: 'Contact person',
    ADDITIONAL_INFORMATION: 'Miscellaneous',
    ADDITIONAL_INFORMATION_HAS_CAR: 'I have a car',
    ADDITIONAL_INFORMATION_HAS_DRIVERS_LICENCE: 'I have a drivers license',
    ADDITIONAL_INFORMATION_NO_HOMES_WITH_PETS: 'Only households without pets',
    ADDITIONAL_INFORMATION_IS_SMOKER: 'I’m a smoker',
    ADD_APPOINTMENT_MODAL_TITLE: 'Add appointment',
    AGE: '{age} Years',
    ALL_DAY: 'All day',
    APPLICANT_LOGIN_INFO_TITLE: 'Are you an applicant?',
    APPLICANT_LOGIN_INFO:
      'Document upload for applicants has moved. If you are an applicant please visit {link} to upload your documents.',
    APPLICANT_LOGIN_INFO_LINK: 'the applicant portal',
    APPOINTMENT: 'Appointment',
    APPOINTMENT_DATE_LABEL: 'Date and time',
    APPOINTMENT_DATE_TIME_LABEL: '1. Date and time',
    APPOINTMENT_BUDGET_LABEL: '3. Budget Used',
    APPOINTMENT_BUDGET_LABEL_SPECIAL: '3. Budget Used',
    APPOINTMENT_SERVICES_HINT: 'Pick at least one service',
    APPOINTMENT_CANCELLATION_PERSON_LABEL: '1. Who cannot make the appointment?',
    APPOINTMENT_SERVICES_LABEL: '2. Services',
    APPOINTMENT_SERVICES_LABEL_SPECIAL: '2. Services',
    APPOINTMENT_CANCELATION_REASONS_HINT: 'Pick a reason',
    APPOINTMENT_CANCELATION_REASONS_LABEL: '2. What is the reason?',
    UPLOAD_DOCUMENT_LABEL: '3. Upload picture of the appointment records',
    AVAILABILITY: 'Availability',
    AVAILABILITY_DESCRIPTION: 'When disabled you won’t receive any new proposals',
    AVAILABILITY_TITLE: 'Available for new customers',
    BANK_DETAILS_HELP:
      'These details will be printed on your invoices. Please ensure that they’re kept up to date.',
    BANK_TAX_DETAILS: 'Bank and tax details',
    BIC: 'BIC',
    DOCUMENT_NOW: 'Document now',
    BOOKING_ACTION_DELETE: 'Delete booking',
    BOOKING_ACTION_CANCEL: 'Appointment did not take place',
    BOOKING_DELETE_CONFIRM_TITLE: 'Confirm delete',
    BOOKING_FULFILL_CONFIRM_TITLE: 'Confirm booking fulfill',
    BOOKING_CANCEL_CONFIRM_TITLE: 'Confirm appointment cancellation',
    BOOKING_DELETE_CONFIRM_MESSAGE: 'Delete the booking on {bookingDate} with {name}?',
    BOOKING_FULFILL_CONFIRM_MESSAGE: 'Are you sure, you want to continue without uploading the image?',
    BOOKING_CANCELLATION_CONFIRM_MESSAGE: 'Are you sure, you want to cancel the appointment?',
    BOOKING_DELETE_CONFIRM_SUBMIT: 'Yes, delete',
    BOOKING_FULFILL_CONFIRM_SUBMIT: 'Yes',
    BOOKING_CANCEL_CONFIRM_SUBMIT: 'Yes',
    BOOKING_FEEDBACK_ABORT: 'Abort',
    BOOKING_FEEDBACK_MODAL_TITLE: 'Appointment Feedback',
    BOOKING_FEEDBACK_FURTHER_APPOINTMENT_MAYBE: 'Maybe',
    BOOKING_FEEDBACK_FURTHER_APPOINTMENT_NO: 'No',
    BOOKING_FEEDBACK_FURTHER_APPOINTMENT_YES: 'Yes',
    BOOKING_FEEDBACK_MOOD_LONELY: 'Lonely',
    BOOKING_FEEDBACK_MOOD_SAD: 'Sad',
    BOOKING_FEEDBACK_MOOD_DETERIORATING_HEALTH: 'Deteriorating health',
    BOOKING_FEEDBACK_MOOD_GOOD: 'Good',
    BOOKING_FEEDBACK_QUESTION_ONE_TITLE:
      '3 quick questions regarding the appointment:\n\n Do you think there will be further appointments?',
    BOOKING_FEEDBACK_TITLE_QUESTION_ONE:
      '3 quick questions regarding the appointment:\n\n How would you rate their mood?',
    BOOKING_FEEDBACK_MOOD_VERY_GOOD: "Very good",
    BOOKING_FEEDBACK_MOOD_FAIR: "Fair",
    BOOKING_FEEDBACK_MOOD_SO_LALA: "Not so well",
    BOOKING_FEEDBACK_MOOD_POOR: "Poor",
    BOOKING_FEEDBACK_MOOD_FREE_TEXT : "Brief explanation (Optional)",
    BOOKING_FEEDBACK_ACTIVITIES_FREE_TEXT : "Write your answer here",
    BOOKING_FEEDBACK_QUESTION_TWO_TITLE: 'How would you describe the mood of the customer?',
    BOOKING_FEEDBACK_TITLE_QUESTION_TWO: 'How would you rate their energy level?',
    BOOKING_FEEDBACK_ENERGY_VERY_HIGH: "Very high",
    BOOKING_FEEDBACK_ENERGY_HIGH: "High",
    BOOKING_FEEDBACK_ENERGY_FAIR: "Fair",
    BOOKING_FEEDBACK_ENERGY_SO_LALA: "Not so well",
    BOOKING_FEEDBACK_ENERGY_LOW: "Low",
    BOOKING_FEEDBACK_QUESTION_THREE_TITLE: 'How happy are you as a caregiver with this customer?',
    BOOKING_FEEDBACK_TITLE_QUESTION_THREE: 'Can you briefly describe what you did together or anything else worth mentioning?',
    BOOKING_FEEDBACK_SET_FREQUENCY_TITLE: 'Can we ask you for feedback about this customer again?',
    BOOKING_FEEDBACK_SET_FREQUENCY_ALWAYS: 'Yes, for every booking',
    BOOKING_FEEDBACK_SET_FREQUENCY_EVERY_THIRD: 'Only for every 3rd booking',
    BOOKING_FEEDBACK_SET_FREQUENCY_NEVER: 'No, never again',
    BOOKING_FEEDBACK_THANK_YOU: 'Thank you for providing us with feedback.',
    BOOKING_FEEDBACK_SKIP_QUESTION: 'Skip question',
    BOOKING_FEEDBACK_SUBMIT_STEP_1: 'Continue (1 of 3)',
    BOOKING_FEEDBACK_SUBMIT_STEP_2: 'Continue (2 of 3)',
    BOOKING_FEEDBACK_SUBMIT_STEP_3: 'Submit (3 of 3)',
    BOOKING_FEEDBACK_ONLY_VISIBLE_TO_CARESHIP_HINT:
      'Your answers are only visible to Careship employees',
    BOOKING_FREQUENCY: `{frequency, select,
      EVERY_TWO_WEEKS {Every 2 weeks}
      ON_DEMAND {On demand}
      TWO_THREE_TIMES_PER_WEEK {2-3x per week}
      ONCE_PER_WEEK {Once per week}
      FOUR_TIMES_PER_WEEK_OR_MORE {4 or more times per week}
      ONCE_OR_TWICE_A_MONTH {1-2 times per month}
      ONCE {Once}
      other {{ frequency }}
    }`,
    BOOKING_LIST_EMPTY_HEADER: 'Keine Termine eingetragen',
    BOOKING_LIST_EMPTY_SUBLINE: 'Warum zukünftige Termine bereits jetzt eintragen?',
    BOOKING_LIST_EMPTY_ITEM_1_HEADER: '1. Erhalte Erinnerungen',
    BOOKING_LIST_EMPTY_ITEM_1_TEXT: 'Wir können Dich an bevorstehende Termine erinnern.',
    BOOKING_LIST_EMPTY_ITEM_2_HEADER: '2. Schnelleres Dokumentieren',
    BOOKING_LIST_EMPTY_ITEM_2_TEXT: 'Bereits erstellte Termine können schnell dokumentiert werden.',
    BOOKING_LIST_EMPTY_ITEM_3_HEADER: '3. Kundenerwartungen',
    BOOKING_LIST_EMPTY_ITEM_3_TEXT:
      'Deine Kunden können sehen, wann Dein nächster Besuch geplant ist.',
    BOOKING_LIST_EMPTY_ITEM_4_HEADER: '4. Kundenbudget',
    BOOKING_LIST_EMPTY_ITEM_4_TEXT: 'Deine Kunden können ihr Budget für den nächsten Monat planen.',
    BOOKING_RECURRING_SAVED: 'Booking was saved',
    BOOKING_VALIDATION_OVERLAPS:
      '{itemCount, plural, =0 {No other bookings found at this time} one {One overlapping booking found} other {Found {itemCount} overlapping bookings}}',
    BUTTON_ADD: 'Add',
    BUTTON_ADD_BOOKING: 'Add Appointment',
    BUTTON_CALL: 'Call',
    BUTTON_CANCEL: 'Cancel',
    BUTTON_CONFIRM: 'Confirm',
    BUTTON_CREATE_BOOKING: 'Create',
    BUTTON_DOCUMENT_BOOKING_NOW: 'Document now',
    BUTTON_EDIT: 'Edit',
    BUTTON_SAVE: 'Save',
    BUTTON_EMAIL: 'Email',
    BUTTON_SAVE_CHANGES: 'Save changes',
    BUTTON_CONFIRM_FULFILL: 'Confirm',
    BUTTON_SUBMIT_FULFILL: 'Document',



    TEXT_SIGNATURE_FOR_SIGNATURE_SCREEN: 'Signature',
    TEXT_BOOKING_DATE_TIME_1: 'Your booking took place from ',
    TEXT_BOOKING_DATE_TIME_2: ' to ',
    TEXT_BOOKING_DATE_TIME_3: ' on ',
    TEXT_SIGNATURE_CU_PART_1: 'By signing I, ',
    TEXT_SIGNATURE_CU_PART_2: ', agree that the above information is correct and the booking took place',
    TEXT_SIGNATURE_CG_PART_1: 'By signing I, ',
    TEXT_SIGNATURE_CG_PART_2: ', agree that the information is correct and the booking took place',
    TEXT_DECLERATION_SIGNATURE_CU_PART_1: 'I ',
    TEXT_DECLERATION_SIGNATURE_CU_PART_2: ', declare that I want careship to transfer the information of this appointment and related invoiced information to my insurance.',
    BUTTON_NEXT_SIGNATURE_SCREEN: 'Next',
    BUTTON_BACK_SIGNATURE_SCREEN: 'Back',
    BUTTON_EDIT_BOOKING_SIGNATURE_SCREEN: 'Edit Booking',
    BUTTON_NOT_SIGNING_SIGNATURE_SCREEN: 'Not signing, paying privately',
    BUTTON_UNDO_SIGNATURE_SCREEN: 'Clear / Redo',
    TEXT_WARNING_SIGNATURE_SCREEN: 'By not signing the declaration of assignments, you will have to pay the appointment privately',
    BUTTON_AGREE_PAY_PRIVATELY: 'Yes, I agree to pay privately',
    BUTTON_NOT_AGREE_PAY_PRIVATELY: 'No, I want my insurance to cover',
    BUTTON_INSURANCE_COVER: 'I want my insurance to cover',








    BUTTON_UPLOAD_FULFILL: 'Upload',
    CARE_DEGREE: `{degree, select,
      none {None}
      unknown {Unknown}
      requested {Requested}
      other {{degree}}
    }`,
    CARE_DEGREE_LABEL: 'Care Degree',
    CARE_RECIPIENT_LABEL: 'Care recipient',
    CAREFORCE_MIGRATION_PENDING_DESCRIPTION:
      'We are working on making these settings available here. For now, please use the old My Careship to edit these settings.',
    CAREFORCE_MIGRATION_PENDING_LINK: 'Edit in the old My Careship version',
    COLLABORATION_ENDED_ON: 'Collaboration ended on {endsAt}',
    COLLABORATION_ENDS: 'Collaboration ends: {endsAt}',
    COLLABORATION_ENDS_ON: 'Collaboration ends on {endsAt}',
    COLLABORATION_ENDS_ON_DESCRIPTION: 'You cannot add any more appointments after {endsAt}',
    CONTACT_AND_ADDRESS: 'Contact Data',
    CONTACT_AND_ADDRESS_ADDITIONAL_INFORMATION: 'Additional information',
    CONTACT_AND_ADDRESS_BIRTHDAY: 'Birthday',
    CONTACT_AND_ADDRESS_CITY: 'City',
    CONTACT_AND_ADDRESS_CONTACT_US_NAME_CHANGE: 'Contact us to change your name',
    CONTACT_AND_ADDRESS_CONTACT_US_EMAIL_CHANGE: 'Contact us to change your e-mail',
    CONTACT_AND_ADDRESS_COMPANY: 'Company',
    CONTACT_AND_ADDRESS_EMAIL: 'E-mail',
    CONTACT_AND_ADDRESS_FIRST_NAME: 'First Name',
    CONTACT_AND_ADDRESS_LANDLINE: 'Landline',
    CONTACT_AND_ADDRESS_LAST_NAME: 'Last Name',
    CONTACT_AND_ADDRESS_MOBILE: 'Mobile',
    CONTACT_AND_ADDRESS_MOBILE_PHONE_NUMBER: 'Mobile phone number',
    CONTACT_AND_ADDRESS_PHONE_NUMBER: 'Phone Number',
    CONTACT_AND_ADDRESS_POSTAL_CODE: 'Postal Code',
    CONTACT_AND_ADDRESS_STREET: 'Street',
    CONTACT_DETAILS: 'Contact information',
    CONTACT_CARESHIP: 'Contact Careship',
    CONTRACT_CONFIRMATION_ACTION_TITLE: 'Will a second appointment take place?',
    CONTRACT_CONFIRMATION_ACTION_CONFIRM: 'Yes',
    CONTRACT_CONFIRMATION_ACTION_CONFIRM_DIALOG: `{element, select,
      title {Will a second appointment really take place?}
      message {You can add a second appointment now, or later.}
      cancelButtonLabel {Cancel}
      confirmButtonLabel {Yes}
      other {{ element }}
    }`,
    CONTRACT_CONFIRMATION_ACTION_MAYBE: 'Maybe',
    CONTRACT_CONFIRMATION_ACTION_MAYBE_DIALOG: `{element, select,
      title {Really unsure whether a second appointment will take place?}
      message {Please take care of clarifying your collaboration.}
      cancelButtonLabel {Cancel}
      confirmButtonLabel {Yes, really unsure}
      other {{ element }}
    }`,
    CONTRACT_CONFIRMATION_ACTION_REJECT: 'No',
    CONTRACT_CONFIRMATION_ACTION_REJECT_DIALOG: `{element, select,
      title {Won’t there really be no second appointment?}
      message {If not, we will assume that you will no longer work together.}
      cancelButtonLabel {Cancel}
      confirmButtonLabel {No second appointment}
      other {{ element }}
    }`,
    CONTRACT_CONFIRMATION_SECOND_APPOINTMENT_TITLE: 'Create second appointment',
    COMPANY: 'Company',
    CUSTOMERS: 'Customers',
    CUSTOMER: 'Customer',
    CUSTOMER_DETAILS_SCREEN_TITLE: 'Customer Details',
    CUSTOMER_COST_PER_HOUR: 'Customer pays {cost}',
    CUSTOMER_SATISFACTION_LEVEL_HIGH: 'Great, keep it up!',
    CUSTOMER_SATISFACTION_LEVEL_LOW: 'Please think about what you can improve.',
    CUSTOMER_SATISFACTION_LEVEL_MID: 'Okay, but there’s still room for improvements.',
    CUSTOMER_SATISFACTION_TITLE_1: 'Customer rating',
    CUSTOMER_SATISFACTION_TITLE_2: '(avg.)',
    CUSTOMER_SATISFACTION_TOOLTIP_TITLE: 'Customer ratings taken into account:',
    CUSTOMER_SATISFACTION_TOOLTIP_BODY:
      'Average of all customer ratings received after the first appointment on a scale from 1 to 5.',
    DASHBOARD: 'Dashboard',
    DASHBOARD_FEEDBACK_BUTTON: 'Provide feedback',
    DASHBOARD_FEEDBACK_TEXT: 'What do you think about the above overview of your stats?',
    DASHBOARD_FEEDBACK_TITLE: 'Your opinion is important to us',
    DASHBOARD_SUBHEADER: 'Your work made easier',
    DASHBOARD_WELCOME_BACK: 'Welcome back',
    DATEPICKER_END_DATE_LABEL: 'To',
    DATEPICKER_END_TIME_LABEL: 'End',
    DATEPICKER_START_DATE_LABEL: 'From',
    DATEPICKER_START_TIME_LABEL: 'Start',
    DEFINITION_PROPOSAL_DECLINE_REASON: `{reason, select,
      distance_too_large {Distance too large}
      too_many_hours_requested {Too many hours requested}
      requested_services_do_not_match {Requested services do not match}
      other {Other reason}
    }`,
    DETAILS: 'Details',
    DIARY: 'Diary',
    DOCUMENT_BOOKING_ALERT:
      'Um Deine geleisteten Stunden abzurechnen, dokumentiere Deine Arbeitszeit und die erbrachten Leistungen.',
    DOCUMENT_BOOKING_ALERT_TITLE: 'IMPORTANT:',
    DOCUMENT_TITLE: 'My Careship - {title}',
    DURATION_LABEL: 'Duration',
    EARNED: 'Earned',
    EARNINGS_PER_HOUR: 'Earnings / hour',
    EDIT_PROFILE: 'Edit profile',
    EDIT_PROFILE_HINT: ' ',
    EDIT_PROFILE_EXTERNAL_DESCRIPTION:
      'Edit profile is not yet available in the app. Continuing will take you to the old My Careship to edit your profile there.',
    EDIT_PROFILE_EXTERNAL_CANCEL: 'Cancel',
    EDIT_PROFILE_EXTERNAL_CONFIRM: 'Continue',
    EMAIL: 'Email',
    ERROR_LOADING_DETAILS: 'Error while loading details',
    EXISTING_CUSTOMER: 'Existing Customer at Careship',
    EXPERIENCE_WITH_MEDICAL_CONDITIONS: 'Experience with medical conditions',
    FINANCES_SECTION: 'Finances',
    FINANCIAL_OVERVIEW: 'Financial overview',
    FIRST_APPOINTMENT: 'First appointment',
    FIRST_APPOINTMENT_ACTION_TITLE: 'Has a 1st appointment been planned?',
    FIRST_APPOINTMENT_ACTION_CONFIRM: 'Yes',
    FIRST_APPOINTMENT_ACTION_MAYBE: 'Pending',
    FIRST_APPOINTMENT_ACTION_DECLINE: 'No',
    FIRST_APPOINTMENT_ACTION_DOCUMENT: 'Document',
    FIRST_APPOINTMENT_CANCELATION_ACTION_TITLE: 'The first appointment is confirmed',
    FIRST_APPOINTMENT_CANCELATION_ACTION_BUTTON: 'Cancel first appointment & end contract',
    FIRST_APPOINTMENT_CANCELATION_ACTION_TOOLTIP:
      'If you cancel the first appointment, you can no longer create new appointments for this customer and the contract will be terminated.',
    FIRST_APPOINTMENT_CHECKLIST: 'Checklist first appointment',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_1_TITLE: 'Plan enough time for your journey.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_1_DESCRIPTION:
      'Check your route to the customer the day before and plan your journey - punctuality is important.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_2_TITLE:
      'Prepare yourself for questions that the customer might have.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_2_DESCRIPTION:
      'You are the expert - be sure to offer the customer opportunities to ask questions.\nWith the help of the documents that we provide you here in the app in your account area, you have all information about billing, financing and general Careship processes at hand. If you cannot answer something, offer to ask us.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_2_LINK: 'View helpful documents',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_3_TITLE:
      'Confirm the desired services again and clarify special features.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_4_TITLE: 'Explain the billing process to the customer.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_5_TITLE:
      'Inform the customer of the possibility of financing through the insurance, if relevant.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_6_TITLE: 'Arrange further appointments',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_6_DESCRIPTION:
      'Discuss with the customer when a follow-up appointment should take place. From the day of the first appointment, please specify in the previous step in the app whether there are follow-up appointments or not - you can then add further appointments directly.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_7_TITLE:
      'End the conversation by saying that the customer can contact you at any time if further questions arise.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_7_DESCRIPTION:
      'Ensure once again that the customer has your contact details.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_8_TITLE:
      'When you have finished, document this first appointment so that it appears on the next invoice.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_8_DESCRIPTION:
      'You can do this here in the app as soon as the time of your appointment is over.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_9_TITLE:
      'Send us feedback on your first appointment either via the link that you will receive from us or here in the app.',
    FIRST_APPOINTMENT_CONFIRM_FORM_SUBMIT: 'Confirm appointment',
    FIRST_APPOINTMENT_CONFIRM_MODAL_CONFIRM_DATE:
      'Please check the date and time and change them if necessary.',
    FIRST_APPOINTMENT_CONFIRM_MODAL_TITLE: 'First appointment confirmation',
    FIRST_APPOINTMENT_DECLINE_MODAL_TITLE: 'First appointment cancellation',
    FIRST_APPOINTMENT_DECLINE_FORM_HINT_WARNING:
      'If you cancel the first appointment, you can no longer create appointments for this customer and the customer and the first appointment will no longer be visible.',
    FIRST_APPOINTMENT_DECLINE_FORM_DESCRIPTION_TITLE: 'Additional comment',
    FIRST_APPOINTMENT_DECLINE_FORM_DESCRIPTION_PLACEHOLDER: 'Type your comment here...',
    FIRST_APPOINTMENT_DECLINE_FORM_REASONS: `{reason, select,
      timing_issues {We’re unable to find a suitable time}
      die_chemie_stimmt_nicht {“The chemistry is not right”}
      other_solution_found {Customer opted for a different solution or company}
      no_binding_booking {Customer didn’t want to book at all}
      different_type_of_caregiver_needed {Customer wants a different caregiver (gender, age, etc.)}
      different_cg_needed_gender {Customer wants a male/female caregiver}
      different_cg_needed_age {Customer wants an older/younger caregiver}
      different_cg_needed_insufficient_experience {Customer wants a caregiver with more experience}
      different_cg_needed_language {Customer wants a caregiver who speaks their language}
      different_cg_needed_lacks_covid_vaccination {Customer wants a caregiver who’s fully vaccinated against COVID-19}
      different_cg_needed_other {Customer wants a different caregiver (other reasons)}
      customer_needs_different_services {Customer needs different services than expected}
      customer_chose_different_company {Customer decided on a different company}
      customer_did_not_want_to_book {Customer didn’t actually want to book}
      distance_too_far {Distance too far}
      customer_not_ready_yet {Customer is not ready yet (hospital stay, refuses help, financial reasons, etc.)}
      customer_not_reached {Unable to reach customer}
      other {Other reason}
    }`,
    FIRST_APPOINTMENT_DECLINE_FORM_TITLE: 'Why won’t the first appointment take place?',
    FIRST_APPOINTMENT_CONFIRMED_FOR: 'Confirmed first appointment',
    FIRST_APPOINTMENT_PLANNED_FOR: 'Planned first appointment',
    FIRST_APPOINTMENT_REQUESTED_FOR: 'First appointment requested for',
    FIRST_APPOINTMENT_UNCONFIRMED: 'Unconfirmed',
    FIRST_APPOINTMENT_CONFIRMED: 'Confirmed',
    FIRST_APPOINTMENT_DECLINED: 'First appointment cancelled',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_DESCRIPTION:
      'Please read the checklist for the first appointment - you may add follow-up appointments after the first appointment.',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_PAST_DESCRIPTION:
      'Document this and all other past appointments so that they appear on the upcoming invoice.',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_PAST_TITLE: 'First appointment over',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_TITLE: 'First appointment confirmed',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_TODAY_DESCRIPTION:
      'Please read the checklist for the first appointment and document the appointment after completion so that it appears on the next invoice.',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_TODAY_TITLE: 'TODAY First appointment',
    FIRST_APPOINTMENT_STATUS_BAR_UNCONFIRMED_TITLE: 'First appointment unconfirmed - please call',
    FIRST_APPOINTMENT_TOOLTIP:
      '{prefix} on {yes} if you were able to reach them and you made an initial appointment; {maybe} if you still have to agree with them on a time for the first appointment or have not been able to reach them yet. Or press {no} if you could not reach your customer or you could not agree on an initial appointment. If this status changes, please update your information here!',
    FIRST_APPOINTMENT_TOOLTIP_PREFIX:
      'After receiving the request, you have up to 24 hours to call your customer and arrange an initial appointment. Then click',
    FIRST_APPOINTMENT_TOOLTIP_YES: '(YES)',
    FIRST_APPOINTMENT_TOOLTIP_NO: '(NO)',
    FIRST_APPOINTMENT_TOOLTIP_MAYBE: '(PENDING)',
    FIRST_APPOINTMENT_UPDATE_STATUS_CURRENT_REASON: 'Currently picked reason',
    FIRST_APPOINTMENT_UPDATE_STATUS_NEW_REASON: 'New reason',
    FIRST_APPOINTMENT_UPDATE_STATUS_MODAL_TITLE: 'First appointment still uncertain',
    FIRST_APPOINTMENT_UPDATE_STATUS_MODAL_REASON: 'Why is the first appointment not certain?',
    FIRST_APPOINTMENT_UPDATE_STATUS_MODAL_DESCRIPTION: 'Further comment',
    FIRST_APPOINTMENT_UPDATE_STATUS_FORM_SUBMIT: 'Save',
    FIRST_APPOINTMENT_UPDATE_STATUS_MISSING_DESCRIPTION: 'Description cannot be blank',
    FIRST_APPOINTMENT_UPDATE_STATUS_MISSING_REASON: 'Reason cannot be blank',
    FIRST_APPOINTMENT_UPDATE_STATUS_REASON: `{reason, select,
      reason_not_reachable {Customer not reachable - I will keep trying}
      reason_discuss_with_family {The customer would like to have another consultation with the family - we will speak again}
      reason_timing_issues {We still need clarification for the appointment - we’ll talk again}
      reason_financing_unclear {Financing with the customer still unclear - we’ll talk again}
      reason_customer_unsure {Customer still unclear whether they would like to use Careship - we’ll talk again}
      reason_other {Other reason}
      other {{ reason }}
    }`,
    FIRST_APPOINTMENT_SURVEY_BUTTON: 'How was the first appointment?',
    FIRST_APPOINTMENT_SURVEY_COMPLETED: 'Thank you for the first appointment feedback',
    FIRST_CALL_BUTTON: 'Call',
    FIRST_CALL_CHECKLIST: 'Guide for first call & appointment ',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_1_DESCRIPTION:
      'If necessary, adjust the date and time as agreed with the customer.',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_1_TITLE: 'Yes',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_2_DESCRIPTION:
      'Try to clarify the first appointment again later - we will support you if needed.',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_2_TITLE: 'Maybe',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_3_DESCRIPTION:
      'Please let us know why and we will remove the customer and the first appointment from your overview.',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_3_TITLE: 'No',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIPS_DESCRIPTION:
      'Pick the right option on the Customer details page.',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIPS_TITLE: 'Will the first appointment take place?',
    FIRST_CALL_CHECKLIST_ITEM_1_TITLE: 'Introduce yourself as the everyday hero from Careship.',
    FIRST_CALL_CHECKLIST_ITEM_2_TITLE:
      'Confirm or find a suitable date and time for the first appointment.',
    FIRST_CALL_CHECKLIST_ITEM_3_ITEMS:
      'Desired services;Special needs;Any other relevant and important information',
    FIRST_CALL_CHECKLIST_ITEM_3_TITLE:
      'Clarify the details of the first appointment with the customer.',
    FIRST_CALL_CHECKLIST_ITEM_4_DESCRIPTION:
      'For example, how the billing and payment process works or billing options via the insurance company.',
    FIRST_CALL_CHECKLIST_ITEM_4_TITLE:
      'Ask if the customer has any questions and clarify any remaining doubts.',
    FIRST_CALL_CHECKLIST_ITEM_5_TITLE:
      'Tell the customer that you would like to confirm the billing details once again on the first appointment.',
    FIRST_CALL_CHECKLIST_ITEM_6_DESCRIPTION:
      'Make sure they’ve taken note of your contact details.',
    FIRST_CALL_CHECKLIST_ITEM_6_TITLE:
      'End the conversation by telling the customer that they can contact you at any time if they have any further questions.',
    FORGOTTEN_PASSWORD: 'Forgotten password?',
    FORGOTTEN_PASSWORD_LEGEND: 'Enter your email address',
    FORGOTTEN_PASSWORD_REQUEST_BUTTON: 'Reset password',
    FORGOTTEN_PASSWORD_REQUEST_SUCCESS: 'Please check your email',
    FORGOTTEN_PASSWORD_EMAIL_SENT:
      'We have sent you an email with a link to reset your password - if the email address you provided is the one used to log in to Careship. If you have not received an email, this email address is not registered.',
    FORGOTTEN_PASSWORD_REQUEST_LOGIN_BUTTON: 'Back to Login',
    FREQUENCY_LABEL: 'Frequency',
    GENERAL_EXPERIENCE: 'Experience in general',
    EDIT_APPOINTMENT_MODAL_TITLE: 'Edit appointment',
    CANCEL_APPOINTMENT_MODAL_TITLE: 'Cancel appointment',
    ERROR_UNKNOWN: 'Unknown error, please try again later',
    ERROR_RESPONSE: `{errorCode, select,
      BOOKING_INVALID_ID {Invalid booking ID}
      BOOKING_INVALID_SERVICE {Invalid booking service}
      BOOKING_IS_FULFILLED {Booking is already fulfilled}
      BOOKING_IS_INVOICED {Booking is already invoiced}
      BOOKING_IS_OVERLAPPING {Booking is overlapping with another booking}
      BOOKING_NOT_FOUND {Booking not found}
      CANNOT_DESERIALIZE_REQUEST_DATA {Cannot deserialize request data}
      CONTRACT_INVALID_ID {Invalid contract ID}
      CONTRACT_NOT_FOUND {Contract not found}
      INTERNAL_SERVER_ERROR {Internal server error}
      other {Unexpected error: {errorCode}}
    }`,
    FLEXIBLE_APPOINTMENT:
      'The first booking is {prefix} and can be determined in consultation with the customer',
    FLEXIBLE_APPOINTMENT_PREFIX: 'flexible',
    FLEXIBLE_APPOINTMENT_TOOLTIP_BODY:
      'Once the request has been assigned, you have 24 hours to contact the customer and arrange the first appointment. Its date is flexible, but please also note the desired duration and the frequency of follow-up appointments. As soon as you have agreed on a time, please adjust it accordingly using the edit icon (pencil) here.',
    HEADER_BACK_BUTTON: 'Back',
    HELP_CENTER: 'Help Center',
    HOURS_WITH_UNIT: '{hours} H',
    IBAN: 'IBAN',
    INSURANCE_DIRECT_PAYMENT_TITLE: 'Customer insurance direct payment',
    INSURANCE_DIRECT_PAYMENT_DESCRIPTION:
      'Customer insurance pays your services directly to Careship and we transfer this, minus the commission, to your specified bank account. Filling out and uploading the service documentation is mandatory for this customer.',
    INSURANCE_DIRECT_PAYMENT_LINK: 'more',
    INVALID_GRANT:
      'Incorrect email/password. Note that if you haven’t completed the application process you won’t have access to the new My Careship.',
    INVOICED_BOOKING: 'Invoiced',
    INVOICE_LIST: 'All invoices',
    INVOICE_LIST_EMPTY: 'No invoices available',
    INVOICE_CANCELLED: 'Cancelled',
    INVOICE_TYPE: `{invoiceType, select,
      CUSTOMER {Customer}
      COMMISSION {Commission}
      CUSTOMER_STORNO {Customer Storno}
      COMMISSION_STORNO {Commission Storno}
      other {{invoiceType}}
    }`,
    INVOICE_LIST_WITH_ANONYMIZED:
      'In {selectedYear} you received invoices from customers who wanted their data anonymized and for whom we unfortunately cannot display any invoices here. Please contact us via {customerServiceLink}.',
    INVOICE_LIST_WITH_ANONYMIZED_LINK: 'Email our customer service',
    INVOICE_PDF_VIEW_DOWNLOAD: 'Download/Share',
    INVOICE_REQUEST_ANONYMIZED_BODY:
      'Dear Careship Team,\n\nPlease send me the invoices from the year {year} for those of my customers who have had their customer data anonymized.\n\nThanks in advance.',
    INVOICE_REQUEST_ANONYMIZED_SUBJECT: 'Invoices {year} for customers with anonymized data',
    CAREGIVER_LOGIN: 'Caregiver Login',
    LANGUAGES: `{key, select,
      GERMAN {German}
      ENGLISH {English}
      SPANISH {Spanish}
      FRENCH {French}
      ARABIC {Arabic}
      CHINESE {Chinese}
      CROATIAN {Croatian}
      CZECH {Czech}
      DANISH {Danish}
      DUTCH {Dutch}
      FINNISH {Finnish}
      GREEK {Greek}
      HEBREW {Hebrew}
      HUNGARIAN {Hungarian}
      ITALIAN {Italian}
      JAPANESE {Japanese}
      KOREAN {Korean}
      POLISH {Polish}
      PORTUGUESE {Portuguese}
      NORWEGIAN {Norwegian}
      ROMANIAN {Romanian}
      RUSSIAN {Russian}
      SERBIAN {Serbian}
      SIGN_LANGUAGE {Sign Language}
      SWEDISH {Swedish}
      TURKISH {Turkish}
      VIETNAMESE {Vietnamese}
      other {Other}
    }`,
    LOGIN: 'Sign In',
    LOGIN_TITLE: 'Login',
    LOGOUT: 'Log out',
    RESET_PASSWORD: 'Forgot password?',
    LOG_APPOINTMENT_MODAL_TITLE: 'Document appointment',
    MORE_ABOUT_CARE_RECEIVER: 'More about {name}',
    MY_CUSTOMERS: 'My Customers',
    MY_PROPOSALS: 'My Proposals',
    MONTHLY_EARNINGS_EARNINGS: 'Earnings',
    MONTHLY_EARNINGS_DOCUMENTED_HOURS: 'Documented hrs.',
    MONTHLY_EARNINGS_HOURS: 'Hrs',
    MONTHLY_EARNINGS_MINUTES: 'min',
    MONTHLY_EARNINGS_TITLE: 'Your earnings for {month}',
    MONTHLY_EARNINGS_TITLE_1: 'Earnings',
    MONTHLY_EARNINGS_TITLE_2: '(in {month})',
    MONTHLY_EARNINGS_TOOLTIP_TITLE: 'Earnings taken into account:',
    MONTHLY_EARNINGS_TOOLTIP_BODY:
      'The sum of all documented hours of the current month (excluding the commission).',
    MONTHLY_HOURS_TOOLTIP_TITLE: 'Hours taken into account:',
    MONTHLY_HOURS_TOOLTIP_BODY: 'The sum of all documented hours of the current month.',
    MY_SERVICES: 'My services',
    NAME_FULL: '{lastName}, {firstName}',
    NAME_PARTIAL: `{gender, select,
      male {Mr {name}.}
      female {Ms {name}.}
      other {{name}}
    }`,
    NAME_WITH_SALUTATION: `{gender, select,
      male {Mr {name}}
      female {Ms {name}}
      other {{name}}
    }`,
    NEW_CUSTOMER: 'New Customer at Careship',
    ONLY_VISIBLE_TO_CARESHIP: 'Only visible to Careship',
    OTHER_SERVICES: 'Other',
    OTHER_SERVICES_LABEL: 'Other services',
    OTHER_REASONS_LABEL: 'Other reasons',
    PASSWORD: 'Password',
    PASSWORD_RESET: 'Create new password',
    PASSWORD_RESET_BUTTON: 'Set password and login',
    PASSWORD_MISMATCH: 'Passwords must match',
    PASSWORD_MIN_LENGTH: 'At least 6 characters',
    PASSWORD_WAS_RESET:
      'Your password has been updated successfully. \nYou can now log in with your new password.',
    NEW_PASSWORD: 'New password',
    NEW_PASSWORD_CONFIRMATION: 'Confirm new password',
    PAST: 'Past',
    PAYMENT_METHOD: `{paymentMethod, select,
      invoice {Invoice}
      sepa {SEPA}
      sepa_direct_debit {SEPA}
      direct_reimbursement {Insurance direct payment}
      other {{paymentMethod}}
    }`,
    PAYMENT_METHOD_LABEL: 'Payment Method',
    PROFILE: 'Profile',
    PROFILE_ABOUT_ME: 'About me',
    PROFILE_ABOUT_ME_INTRO:
      'Add something about yourself and your interests. Caregivers with a presentation of themselves receive up to 20% more inquiries.',
    PROFILE_CURRENT_POSITION: 'Profession',
    PROFILE_EDUCATION_AND_QUALIFICATIONS: 'Education and qualifications',
    PROFILE_EDUCATION_AND_QUALIFICATIONS_SCREEN_TITLE: 'Education and qualifications',
    PROFILE_EXPERIENCE_AND_ABILITIES: 'Experience and abilities',
    PROFILE_LANGUAGES: 'Languages',
    PROFILE_SERVICES: 'My services',
    PROPOSAL: 'Proposal',
    PROPOSAL_ACCEPT_BUTTON: 'Accept proposal',
    PROPOSAL_AWAITING_CUSTOMER: 'Awaiting Careship feedback',
    PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE: 'Day and time flexible',
    PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE_LABEL: 'Expected appointment schedule',
    PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE_NO_SCHEDULE: 'none, this is a single booking',
    PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE_PREFERENCES_PREFIX: 'Weekday & time',
    PROPOSAL_NEW: 'New proposal',
    SERVICE_NEW: 'New service',
    PROPOSAL_DECLINE_DESCRIPTION_LABEL: 'Description of other reason',
    PROPOSAL_DECLINE_MODAL_TITLE: 'Reject request because...',
    PROPOSAL_UNAVAILABLE: 'Proposal unavailable',
    PROPOSAL_UNAVAILABLE_TITLE: 'Sorry, this proposal is unavailable.',
    PROPOSAL_UNAVAILABLE_SUBTITLE: 'Good luck next time!',
    PROPOSAL_DISPLAY_DETAILS: 'See details',
    PROPOSAL_RESPONSE_EMPTY: 'You have not received any proposals during the last 4 weeks',
    PROPOSAL_RESPONSE_FIRST_CALL_TIMING: 'Avg. time from won proposal to first call',
    PROPOSAL_RESPONSE_TITLE_1: 'Proposals',
    PROPOSAL_RESPONSE_TITLE_2: '(past 4 weeks)',
    PROPOSAL_RESPONSE_TOOLTIP_TITLE: 'Proposals considered',
    PROPOSAL_RESPONSE_TOOLTIP_BODY: `The answered percentage is calculated based on how many of the proposals you’ve answered.\nResponse time shows how long on average it takes until you have contacted the potential customer of a won request and reported back whether or not an appointment will take place.\nThe small arrows show you whether the number has gone up or down compared to the previous 4 weeks.`,
    PROPOSAL_RESPONSE_RESPONSE_RATE: 'Proposals\nanswered',
    PROPOSAL_RESPONSE_UNIT_HOURS: 'Hrs.',
    PROPOSAL_STATUS: `{status, select,
      OPEN {Answer Request}
      ACCEPTED {Applied}
      other {{status}}
    }`,
    RECURRING_BOOKING_LABEL: 'Recurring appointment',
    RECURRING_BOOKING_LABEL_HINT: 'Repeat for 4 weeks',
    REFERRAL_CODE_CUSTOMER_AMOUNT: '{amount} per new customer',
    REFERRAL_CODE_CAREGIVER_AMOUNT: '{amount} per caregiver',
    REFERRAL_CODE_COPY_BUTTON: 'Copy your code',
    REFERRAL_CODE_COPY_BUTTON_COPIED: 'Copied',
    REFERRAL_CODE_COPY_BUTTON_HINT:
      'By clicking on the button, you copy your code with a small greeting text and a link to Careship. For example, you can paste it into an email and send it to new customers.',
    REFERRAL_CODE_TITLE: 'Recommend Careship',
    REFERRAL_CODE_SHARE_BUTTON: 'Share your code',
    REFERRAL_CODE_SHARE_MESSAGE:
      'Referral Code: {referralCode} \n\nYou have been invited to try Careship. Careship offers caregivers from the neighbourhood for seniors. If you are looking for a caregiver, enter the referral code in the last step of your search in the field “Promotion code”. If you would like to apply as a caregiver, share the referral code to your interviewer in the application process. \n\nwww.careship.de',
    REFERRAL_CODE_STEPS_TITLE: 'How it works',
    REFERRAL_CODE_STEPS_FIRST_TEXT_SHARE_CODE:
      'You can write down your code and share it or click on “Share your code” and choose one of the options (e.g. email, SMS, WhatsApp ...) to easily share your code to potential new customers or caregivers.',
    REFERRAL_CODE_STEPS_FIRST_TEXT_COPY_CODE:
      'You can write down your code and share it, or click on “Copy your code” and use it to copy your code with a small greeting text and a link to Careship. Then you can paste it into an e-mail, WhatsApp, SMS or whatever you would like to use, and send it to potential new customers or new caregivers.',
    REFERRAL_CODE_STEPS_FIRST_TITLE: 'Share your referral code',
    REFERRAL_CODE_STEPS_SECOND_TEXT:
      'New customer:\nWhen searching for a caregiver on Careship, the new customers you have recruited enter your code in the last step of their search in the “Promotion code” input field.\n\nNew caregivers:\nWhen applying online as a caregiver on Careship, the caregivers you recruited will enter your code as one of the last questions in the application process.',
    REFERRAL_CODE_STEPS_SECOND_TITLE: 'New customers and caregivers use your referral code',
    REFERRAL_CODE_STEPS_THIRD_BANK_ACCOUNT: 'bank account',
    REFERRAL_CODE_STEPS_THIRD_TEXT:
      'Set up a {bankAccount} for this if you haven’t already.\n\nPayment for new customers:\nAs soon as the new customer you have recruited has at least 3 billed hours, you will be informed by us and receive our gift of {customerReferralAmount} to your bank account.\n\nPayment for new caregivers:\nAs soon as the new caregiver you recruited has their first customer via Careship, you will be informed by us and receive our gift of {caregiverReferralAmount} to your bank account.',
    REFERRAL_CODE_STEPS_THIRD_TITLE: 'Receive your payment as a gift from Careship',
    REFERRAL_CODE_YOUR_CODE: 'Your referral code',
    PROPOSAL_DECLINE_BUTTON: 'Reject',
    SCROLL_UP: 'Scroll up',
    SEND_FEEDBACK_BTN: 'Send feedback',
    SEND_FEEDBACK_TEXT:
      'Have you found an error in the app? Is there something we could improve? Please send us your feedback, we’ll be happy to hear from you!',
    SEND_FEEDBACK_TITLE: 'Let us know what you think!',
    SERVICE_DOCUMENTATION: 'Service documentation',
    SERVICE_DOCUMENTATION_HOW_TO: 'How to do it',
    SERVICE_DOCUMENTATION_HOW_TO_DESCRIPTION:
      'The monthly service documentation is an additional documentation of your services in paper form, used as proof of the provided services for the customer’s insurance to process costs.',
    SERVICE_DOCUMENTATION_HOW_TO_FILL: `{element, select,
      title {Fill}
      description {Please always fill out the service documentation by hand. This is required by insurance companies.}
      link {Help on how to fill out the service documentation}
      firstItemBold {General customer data & month/year}
      firstItem { in the upper part should be fully filled out together with the customer when you first meet.}
      secondItemBold {After EVERY appointment}
      secondItem {, enter the date, time and provided services. This must be signed by you and your customer.}
      secondItemImportant {IMPORTANT:}
      secondItemImportantMessage { Please make sure that the appointments on the service documentation EXACTLY match the appointments documented in your MeinCareship app, because only then the customer’s insurance will accept this as proof.}
      thirdItemBold {The customer’s insurance data}
      thirdItem { in the lower part must be filled out depending on your customer’s payment method (visible on the customer page):}
      thirdItemFirstItemBold {With “customer insurance direct payment”}
      thirdItemFirstItem { you do NOT need to fill out the lower part, since in this case the money will be paid DIRECTLY to you.}
      thirdItemSecondItemBold {For customers who pay you themselves in advance via “invoice” or “SEPA”}
      thirdItemSecondItem { and who are eligible for reimbursement by their insurance, please fill out the lower part. Ask your customer about the authorization for preventive care under Section 39 SGB XI (requires at least care level 2) and fill this out at the first meeting, even if the customer is not sure yet.}
      fourthItemBold {After the last appointment}
      fourthItem { the service documentation is signed by the customer (= insured person) and also by you as the caregiver (= service provider) at the bottom. Not mandatory for “customer insurance direct payment”.}
      other {}
    }`,
    SERVICE_DOCUMENTATION_HOW_TO_PRINT: `{element, select,
      title {Print}
      description {Print the service documentation template (on the previous page) and take it with you to the customer when you first meet. As a rule, the document remains with your customer.}
    }`,
    SERVICE_DOCUMENTATION_HOW_TO_UPLOAD: `{element, select,
      title {Upload}
      descriptionBold {After each appointment}
      description {, take a photo/scan of the current service documentation with the newly entered appointment and then upload it to your customer record in your MeinCareship app. After each following appointment you take another photo and update the picture of your uploaded service documentation.\nMake sure that the photo/scan is easy to read and that no other background object is visible in the image.}
      other {}
    }`,
    SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW: `{element, select,
      title {What happens now with the service documentation?}
      description {Whenever the customer’s insurance is involved in the payment, the completed service documentation must be sent to the insurance together with the corresponding invoice at the end of the month.}
      dirTitle {Customer insurance direct payment}
      dirDescriptionBold {Careship sends out}
      dirDescription { the service documentation and the corresponding invoice.}
      dirItemsFirst {On the third day of the following month, we will check your uploaded service documentation records and your documented appointments in your MeinCareship app for an EXACT match and completeness.}
      dirItemsSecond {If everything is correct, we will send it to your customer’s insurance. The insurance will pay your services to Careship and we will transfer your earnings, minus the commission, directly to the bank account you specified. The customer’s insurance usually takes around 10 days to check everything and transfer the money. In the event of discrepancies/incompleteness, we will notify you.}
      customerPaymentTitle {Insurance customer prepayment\n(e.g. invoice or SEPA)}
      customerPaymentDescriptionBold {The customer sends out}
      customerPaymentDescription { the service documentation and the associated invoice themselves.}
      customerPaymentItem {Customers who pay for your services themselves in advance and who are entitled to have the costs reimbursed by their insurance as part of preventive care (Section 39) need to send the service documentation and the associated invoice to their insurance themselves.}
      customerPaymentItemAccent { For many customers it is not so easy to get the papers ready and sent by post or email. They look forward to your support, if that is possible for you.}
      other {}
    }`,
    SERVICE_DOCUMENTATION_INSTRUCTION_TITLE: 'Service documentation image quality',
    SERVICE_DOCUMENTATION_INSTRUCTION_TEXT:
      'Please make sure that your uploaded picture is legible. Make sure that only the service documentation is visible with little distracting background.',
    SERVICE_DOCUMENTATION_INSTRUCTION_BUTTON: 'Got it - upload now',
    SERVICE_DOCUMENTATION_UPLOAD_SHOW: 'Show',
    SERVICE_DOCUMENTATION_LABEL: 'Service documentation',
    SERVICE_DOCUMENTATION_LABEL_MISSING: 'missing',
    SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_IOS:
      'MeinCareship does not have access to your camera. To enable access tap Settings and turn on Camera.',
    SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_ANDROID:
      'MeinCareship does not have access to your camera and media files. To enable access tap Settings and allow access to Files & Media as well as Camera in the permissions section.',
    SERVICE_DOCUMENTATION_MISSING_LIBRARY_PERMISSION_MESSAGE_IOS:
      'MeinCareship does not have access to your photos. To enable access tap Settings and turn on Photos.',
    SERVICE_DOCUMENTATION_MISSING_LIBRARY_PERMISSION_MESSAGE_ANDROID:
      'MeinCareship does not have access to your media files. To enable access tap Settings and allow access to Files & Media in the permissions section.',
    SERVICE_DOCUMENTATION_PDF_VIEW_DOWNLOAD: 'Download/Share',
    SERVICE_DOCUMENTATION_TOP_BANNER_DIR:
      'Insurance direct payment - service documentation is mandatory',
    SERVICE_DOCUMENTATION_TEMPLATE: 'Service documentation template',
    SERVICE_DOCUMENTATION_UPLOAD_BOTTOM_SHEET: `{element, select,
      title {Upload service documentation}
      camera {Take photo}
      image {Choose from library}
      document {Upload file}
      cancel {Cancel}
    }`,
    SERVICE_DOCUMENTATION_UPLOAD_TITLE: 'Upload service documentation',
    PERFORMANCE_DOCUMENTATION_TITLE: 'Service documentation',
    PERFORMANCE_DOCUMENTATION_DATE_FILE_HELP: 'File',
    PERFORMANCE_DOCUMENT_NO_IMAGE_FOUND : 'No Image Found',
    SERVICE_DOCUMENTATION_UPLOAD_CAMERA: 'Take photo',
    SERVICE_DOCUMENTATION_UPLOAD_IMAGE: 'Choose from library',
    SERVICE_DOCUMENTATION_UPLOAD_DOCUMENT: 'Upload file',
    SERVICE_DOCUMENTATION_UPLOAD_CANCEL: 'Cancel',
    SERVICE_DOCUMENTATION_UPLOAD_FAILED: 'Upload failed',
    SERVICE_DOCUMENTATION_UPLOAD_REPLACE_CURRENT: 'Upload a new version',
    SERVICE_DOCUMENTATION_DOCUMENTED_BOOKINGS_COUNT: `{count, plural,
      =0 {# documented bookings}
      one {# documented booking}
      other {# documented bookings}
    }`,
    SERVICES: 'Services',
    SERVICES_REQUESTED: 'Requested services',
    SERVICE_TOOLTIP_ACCOMPANIMENT_BY_CAR:
      'If there is no special authorization, the accompaniment is only permitted with the customer’s car.',
    SERVICE_TOOLTIP_CARRYING_OUT_ADMINISTRATIVE_TASKS:
      'Help with correspondence, telephone contact with doctors, health insurance companies and authorities; organization of appointments such as hairdresser, pedicure, doctor appointments etc.',
    SERVICE_TOOLTIP_COMPANIONSHIP:
      'Conversing, reading from books and magazines, playing board and card games, pursuing a hobby',
    SERVICE_TOOLTIP_LIGHT_HOUSEWORK:
      'Picking up mail, taking out garbage, watering flowers, doing small crafts, taking care of pets',
    SERIVCE_TOOLTIP_MOBILITY_HELP:
      'Helping with standing up, supporting the arm, helping with walking & going for walks',
    SERVICE_TOOLTIP_LIGHT_BODY_CARE:
      'Washing and combing hair, helping with lotion, helping in the shower or bath',
    TAX_NUMBER: 'Tax ID',
    TAX_NUMBER_HINT: '“Kleinunternehmen”',
    TODAY: 'Today',
    TOTAL_HOURS_SERVED: 'Total hours served',
    TOTAL_EARNINGS: 'Total earnings',
    TRAVEL_DISTANCE_TITLE: 'Maximum travel distance',
    TRAVEL_DISTANCE_VALUE: '{value} km',
    UNDOCUMENTED_BOOKING: 'Undocumented',
    SURVEY_NEEDED_BOOKING: 'Missing Survey',
    SIGNED_VIA_PAPER: 'Signed on Paper',
    UPCOMING: 'Upcoming',
    UPCOMING_CUSTOMERS: 'Upcoming',
    UPCOMING_BOOKINGS: 'Upcoming bookings',
    VALIDATION_AT_LEAST_ONE_SERVICE: 'Pick at least one service',
    VALIDATION_EMAIL: 'E-Mail address is invalid',
    VALIDATION_REQUIRED: 'Required',
    VALIDATION_FIRST_BOOKING_MIN_DECLINE_REASONS: 'Please select at least one reason',
    VALIDATION_FIRST_BOOKING_REQUIRED_DESCRIPTION: 'Please add a brief reason for declining',
    VALIDATION_PROPOSAL_MIN_DECLINE_REASONS: 'Please select at least one reason',
    VALIDATION_PROPOSAL_OTHER_REASON: 'Please add a brief reason for declining',
    VALIDATION_TAX_NO_REQUIRED: 'Steuernummer required if no Umsatzsteuer-ID is entered',
    VALIDATION_UST_ID_REQUIRED: 'Umsatzsteuer-ID required if no Steuernummer is entered',
    VAT_ID: 'VAT ID',
    VAT_ID_HINT: '“Großunternehmen”',
    WORKED: 'Worked',
    WORKING_HOURS_LINE_1: 'Monday to Wednesday from 08:00 to 12:00 and from 15:00 to 19:00',
    WORKING_HOURS_LINE_2: 'Thursday and Friday from 10:00 to 15:00',
    CARESHIP_COMMISSION: 'Careship commission {commissionRate}',
    YOUR_CUSTOMER_SINCE: 'Your customer since',
    CUSTOMER_KRANKHEIT: 'Sick',
    CAREGIVER_KRANKHEIT: 'Sick',
    CUSTOMER_URLAUB: 'On Vacation',
    CAREGIVER_URLAUB: 'On Vacation',
    CUSTOMER_GESUNDHEITSZUSTAND_VERSCHLECHTERT: 'Health deteriorates',
    CUSTOMER_TERMIN_VERGESSEN : 'Forgot Appointment',
    CAREGIVER_TERMIN_VERGESSEN : 'Forgot Appointment',
    CUSTOMER_TERMIN_ABGESAGT  : 'Appointment Cancelled',
    CAREGIVER_TERMIN_ABGESAGT  : 'Appointment Cancelled',
    CUSTOMER_UNTERSTUETZUNGSBEDUERFTIGE_PERSON_LEHNT_HILFE_AB  : 'Contact Carereceiver rejects the help',
    CAREGIVER_PROBLEME_BEI_DER_ANFAHRT_ZUM_KUNDEN  : 'Transportation problem while driving to the CU',
    CAREGIVER_FAMILIAERER_NOTFALL  : 'Family emergency',
    CUSTOMER_FAMILIAERER_NOTFALL  : 'Family emergency',
    CAREGIVER_AENDERUNG_IN_DER_VORGESEHENEN_ARBEITSPLANUNG  : 'Changes in the scheduled work plan)',
    CAREGIVER_ANDERE: 'Other',
    CUSTOMER_ANDERE: 'Other',
    CAREGIVER_CANCELLED_BOOKING: 'I couldn’t',
    CUSTOMER_CANCELLED_BOOKING: 'The customer couldn’t',
    HEADING_FULFILL_SIGNATURE_STEP_1: 'Booking Date & Time',
    HEADING_FULFILL_SIGNATURE_STEP_2: 'Booking Summary',
    HEADING_FULFILL_SIGNATURE_STEP_3_1: 'Customer Signature',
    HEADING_FULFILL_SIGNATURE_STEP_3_2: 'Booking Summary',
    HEADING_FULFILL_SIGNATURE_STEP_4_1: 'Customer Signature',
    HEADING_FULFILL_SIGNATURE_STEP_4_2: 'Decleration of Assignment',
    HEADING_FULFILL_SIGNATURE_STEP_5: 'Attention',
    HEADING_FULFILL_SIGNATURE_STEP_6_1: 'Caregiver Signature',
    HEADING_FULFILL_SIGNATURE_STEP_6_2: 'Booking Summary',

    BUTTON_CLASSIC_FLOW: 'Sign on Paper',
    BUTTON_DIGITAL_FLOW: 'Sign Digitally',

    FILL_SURVEY_NOW: 'Next',
    FILL_SURVEY_LATER: 'Answer survey later',

    BOOKING_CONFIRMATION_TITLE : 'Great, the appointment has been documented!',
    BOOKING_CONFIRMATION_DIGITAL_LINE_1 : 'The documentation for this appointment is now complete with your digital signatures and will be billed as such!',
    BOOKING_CONFIRMATION_DIGITAL_LINE_2 : 'We and the environment thank you!',
    BOOKING_CONFIRMATION_DIGITAL_LINE_3 : 'Now just answer a few questions about the appointment...',


    BOOKING_CONFIRMATION_PAPER_LINE_1 : 'Attention: Since you have chosen to sign on paper, do not forget the signatures on the paper performance record and send it to us by post at the end of the month!',
    BOOKING_CONFIRMATION_PAPER_LINE_2 : 'The signatures on it are the valid documentation for billing the hours so that you can receive your wage.',
    BOOKING_CONFIRMATION_PAPER_LINE_3 : 'Now just answer a few questions about the appointment...',

    COMPLETE_SURVEY: 'Complete Survey',

    MISSING_SURVEY_AND_BOOKING_ON_PAPER: 'Missing Survey'
  },
  de: {
    UNDOCUMENTED_MODAL_TITLE: 'Noch nicht dokumentierte Termine',
    UNDOCUMENTED_MODAL_DESCRIPTION: 'Bitte nimm Dir kurz Zeit und dokumentiere Deine Termine.',
    DOCUMENT_LATER_MODAL: 'Später dokumentieren',
    DOCUMENT_NOW_MODAL: 'Jetzt dokumentieren',
    ABSENCES_TITLE: 'Abwesenheiten',
    ABSENCE_REASON: 'Grund',
    ABSENCE_SAVE: 'Speichern',
    ABSENCE_PERIOD: 'Zeitraum',
    ABSENCE_SCREEN_TITLE: 'Abwesenheit hinzufügen',
    ABSENCE_EDIT_SCREEN_TITLE: 'Abwesenheit bearbeiten',
    ABSENCE_TYPE: `{type, select,
      vacation {Urlaub}
      sick {Krankheit}
      other {Ohne Angabe}
    }`,
    ABSENCE_DELETE_CONFIRM_TITLE: 'Abwesenheit wirklich löschen?',
    ABSENCE_DELETE_CONFIRM_CONTENT:
      '{from} - {to}\nDas Löschen kann nicht rückgängig gemacht werden und Du erhältst dann evtl. neue Kundenanfragen in diesem Zeitraum.',
    ABSENCE_DELETE_CONFIRM_SUBMIT: 'Löschen',
    ACCEPTED_ALERT_MESSAGE:
      'Wir suchen die bestmögliche Übereinstimmung zwischen Kunde und Alltagshelfer und geben Dir Bescheid.',
    ACCEPTED_ALERT_TITLE: 'Du hast Dich beworben.',
    ACCEPT_PROPOSAL_CONFIRM_TITLE: 'Wirklich bewerben?',
    ACCEPT_PROPOSAL_CONFIRM_DESCRIPTION: 'Dein möglicher Kunde verlässt sich auf Deine Zusage.',
    ACCEPT_PROPOSAL_CONFIRM_YES: 'Ja, bewerben',
    ACCEPT_PROPOSAL_CONFIRM_CANCEL: 'Nein',
    ACCOUNT: 'Konto',
    ACTION_CALL: 'Anrufen',
    ACTION_DIRECTIONS: 'Route',
    ACTION_EMAIL: 'E-Mail',
    ACTION_MESSAGE: 'Nachricht',
    ALL_DAY: 'Ganztägig',
    APPLICANT_LOGIN_INFO_TITLE: 'Du hast Dich bei uns beworben?',
    APPLICANT_LOGIN_INFO:
      'Um Deine Dokumente hochzuladen, gehe bitte {link} und logge Dich dort ein.',
    APPLICANT_LOGIN_INFO_LINK: 'zum Bewerberportal',
    APPOINTMENT: 'Termin',
    ADDITIONAL_CONTACT: 'Ansprechpartner',
    ADDITIONAL_INFORMATION: 'Sonstiges',
    ADDITIONAL_INFORMATION_HAS_CAR: 'Eigener PKW vorhanden',
    ADDITIONAL_INFORMATION_HAS_DRIVERS_LICENCE: 'Führerschein',
    ADDITIONAL_INFORMATION_NO_HOMES_WITH_PETS: 'Nur Haushalt ohne Tiere',
    ADDITIONAL_INFORMATION_IS_SMOKER: 'Ich bin RaucherIn',
    ADD_APPOINTMENT_MODAL_TITLE: 'Termin eintragen',
    AGE: '{age} Jahre',
    APPOINTMENT_DATE_LABEL: 'Datum und Uhrzeit',
    APPOINTMENT_DATE_TIME_LABEL: '1. Datum und Uhrzeit',
    APPOINTMENT_BUDGET_LABEL: '3.Verwendete Haushaltsmittel',
    APPOINTMENT_BUDGET_LABEL_SPECIAL: '3.Genutztes Budget',
    APPOINTMENT_SERVICES_LABEL: '2. Leistungen',
    APPOINTMENT_SERVICES_LABEL_SPECIAL: '2. Leistung',
    APPOINTMENT_SERVICES_HINT: 'Wähle mind. eine Leistung',
    APPOINTMENT_CANCELLATION_PERSON_LABEL: '1. Wer kann den Termin nicht wahrnehmen?',
    APPOINTMENT_CANCELATION_REASONS_HINT: 'Wählen Sie einen Grund',
    APPOINTMENT_CANCELATION_REASONS_LABEL: '2. Was ist der Gründe dafür',
    UPLOAD_DOCUMENT_LABEL: '3. Leistungsnachweis hochladen',
    AVAILABILITY: 'Verfügbarkeit',
    AVAILABILITY_DESCRIPTION: 'Aktiviere oder deaktiviere hier den Erhalt von Kundenanfragen.',
    AVAILABILITY_TITLE: 'Kundenanfragen erhalten',
    BANK_DETAILS_HELP:
      'Deine Bankdaten werden auf Deine Rechnungen gedruckt. Bitte stelle sicher, dass die Angaben immer aktuell sind.',
    BANK_TAX_DETAILS: 'Bank- u. Steuerdaten',
    BIC: 'BIC',
    DOCUMENT_NOW: 'Jetzt dokumentieren',
    BOOKING_ACTION_DELETE: 'Termin löschen',
    BOOKING_ACTION_CANCEL: 'Termin hat nicht stattgefunden',
    BOOKING_DELETE_CONFIRM_TITLE: 'Sicher löschen?',
    BOOKING_FULFILL_CONFIRM_TITLE: 'Bestätigung der Termindokumentation',
    BOOKING_CANCEL_CONFIRM_TITLE: 'Terminstornierung bestätigen',
    BOOKING_DELETE_CONFIRM_MESSAGE:
      'Möchtest du den Termin am {bookingDate} mit {name} wirklich löschen?',
    BOOKING_FULFILL_CONFIRM_MESSAGE: 'Bist du sicher, dass du fortfahren möchten, ohne das Bild hochzuladen?',
    BOOKING_CANCELLATION_CONFIRM_MESSAGE: 'Bist du sicher, dass du den Termin absagen möchten?',
    BOOKING_DELETE_CONFIRM_SUBMIT: 'Ja, löschen',
    BOOKING_FULFILL_CONFIRM_SUBMIT: 'Ja',
    BOOKING_CANCEL_CONFIRM_SUBMIT: 'Ja',
    BOOKING_FEEDBACK_ABORT: 'Abbrechen',
    BOOKING_FEEDBACK_MODAL_TITLE: 'Feedback zum Termin',
    BOOKING_FEEDBACK_FURTHER_APPOINTMENT_MAYBE: 'Kann sein',
    BOOKING_FEEDBACK_FURTHER_APPOINTMENT_NO: 'Nein',
    BOOKING_FEEDBACK_FURTHER_APPOINTMENT_YES: 'Ja',
    BOOKING_FEEDBACK_MOOD_LONELY: 'Einsam',
    BOOKING_FEEDBACK_MOOD_SAD: 'Traurig',
    BOOKING_FEEDBACK_MOOD_DETERIORATING_HEALTH: 'Gesundheitszustand verschlechtert',
    BOOKING_FEEDBACK_MOOD_GOOD: 'Gut',
    BOOKING_FEEDBACK_QUESTION_ONE_TITLE:
      '3 kurze Fragen zum Termin:\n\n Denkst Du, es wird ein weiterer Termin stattfinden?',
    BOOKING_FEEDBACK_TITLE_QUESTION_ONE:
      '3 kurze Fragen zum dokumentierten Termin\n\n Wie schätzt du die Stimmung des Kunden bei diesem Termin ein?',
    BOOKING_FEEDBACK_MOOD_VERY_GOOD: "Sehr gut",
    BOOKING_FEEDBACK_MOOD_FAIR: "Mittelmäßig",
    BOOKING_FEEDBACK_MOOD_SO_LALA: "Schwankend",
    BOOKING_FEEDBACK_MOOD_POOR: "Schlecht",
    BOOKING_FEEDBACK_MOOD_FREE_TEXT : "Kurze Erläuterung (Optional)",
    BOOKING_FEEDBACK_ACTIVITIES_FREE_TEXT : "Geben Sie Ihre Antwort hier ein",
    BOOKING_FEEDBACK_QUESTION_TWO_TITLE:
      'Wie würdest Du die allgemeine Stimmung Deines Kunden beschreiben?',
    BOOKING_FEEDBACK_TITLE_QUESTION_TWO:
      'Wie schätzt du die körperliche Leistungsfähigkeit des Kunden bei diesem Termin ein?',
    BOOKING_FEEDBACK_ENERGY_VERY_HIGH: "Sehr hoch",
    BOOKING_FEEDBACK_ENERGY_HIGH: "Hoch",
    BOOKING_FEEDBACK_ENERGY_FAIR: "Mittelmäßig",
    BOOKING_FEEDBACK_ENERGY_SO_LALA: "Schwankend",
    BOOKING_FEEDBACK_ENERGY_LOW: "Niedrig",
    BOOKING_FEEDBACK_QUESTION_THREE_TITLE: 'Wie geht es Dir als Alltagshelfer mit diesem Kunden?',
    BOOKING_FEEDBACK_TITLE_QUESTION_THREE: 'Gab es einen besonderen Moment oder Unregelmäßigkeiten, die dir aufgefallen sind? Wenn ja, schildere sie gerne kurz.',
    BOOKING_FEEDBACK_SET_FREQUENCY_TITLE: 'Möchtest Du weiterhin für diesen Kunden Feedback geben?',
    BOOKING_FEEDBACK_SET_FREQUENCY_ALWAYS: 'Ja, weiter Feedback geben',
    BOOKING_FEEDBACK_SET_FREQUENCY_EVERY_THIRD: 'Nur nach jedem 3. Termin',
    BOOKING_FEEDBACK_SET_FREQUENCY_NEVER: 'Nein, kein Feedback mehr geben',
    BOOKING_FEEDBACK_THANK_YOU: 'Danke, dass Du Dir Zeit für das Feedback genommen hast.',
    BOOKING_FEEDBACK_SKIP_QUESTION: 'Frage überspringen',
    BOOKING_FEEDBACK_SUBMIT_STEP_1: 'Weiter (1 von 3)',
    BOOKING_FEEDBACK_SUBMIT_STEP_2: 'Weiter (2 von 3)',
    BOOKING_FEEDBACK_SUBMIT_STEP_3: 'Abschicken (3 von 3)',
    BOOKING_FEEDBACK_ONLY_VISIBLE_TO_CARESHIP_HINT:
      'Deine Angaben sind nur für Careship-Mitarbeiter sichtbar.',
    BOOKING_FREQUENCY: `{frequency, select,
      EVERY_TWO_WEEKS {Alle 2 Wochen}
      ON_DEMAND {Nach Bedarf}
      TWO_THREE_TIMES_PER_WEEK {2-3x pro Woche}
      ONCE_PER_WEEK {Einmal pro Woche}
      FOUR_TIMES_PER_WEEK_OR_MORE {4x pro Woche oder häufiger}
      ONCE_OR_TWICE_A_MONTH {1-2x im Monat}
      ONCE {Einmalig}
      other {{ frequency }}
    }`,
    BOOKING_LIST_EMPTY_HEADER: 'Keine Termine eingetragen',
    BOOKING_LIST_EMPTY_SUBLINE: 'Warum zukünftige Termine bereits jetzt eintragen?',
    BOOKING_LIST_EMPTY_ITEM_1_HEADER: '1. Erhalte Erinnerungen',
    BOOKING_LIST_EMPTY_ITEM_1_TEXT: 'Wir können Dich an bevorstehende Termine erinnern.',
    BOOKING_LIST_EMPTY_ITEM_2_HEADER: '2. Schnelleres Dokumentieren',
    BOOKING_LIST_EMPTY_ITEM_2_TEXT: 'Bereits erstellte Termine können schnell dokumentiert werden.',
    BOOKING_LIST_EMPTY_ITEM_3_HEADER: '3. Kundenerwartungen',
    BOOKING_LIST_EMPTY_ITEM_3_TEXT:
      'Deine Kunden können sehen, wann Dein nächster Besuch geplant ist.',
    BOOKING_LIST_EMPTY_ITEM_4_HEADER: '4. Kundenbudget',
    BOOKING_LIST_EMPTY_ITEM_4_TEXT: 'Deine Kunden können ihr Budget für den nächsten Monat planen.',
    BOOKING_RECURRING_SAVED: 'Termin wurde gespeichert',
    BOOKING_VALIDATION_OVERLAPS:
      '{itemCount, plural, =0 {Keine anderen Termine zur gleichen Zeit} one {1 Termin zur gleichen Zeit existiert bereits} other {{itemCount} überlappende Termine gefunden}}',
    BUTTON_ADD: 'Hinzufügen',
    BUTTON_ADD_BOOKING: 'Termin eintragen',
    BUTTON_CALL: 'Anrufen',
    BUTTON_CANCEL: 'Abbrechen',
    BUTTON_CONFIRM: 'Bestätigen',
    BUTTON_CREATE_BOOKING: 'Termin eintragen',
    BUTTON_DOCUMENT_BOOKING_NOW: 'Jetzt dokumentieren',
    BUTTON_EDIT: 'Bearbeiten',
    BUTTON_SAVE: 'Speichern',
    BUTTON_EMAIL: 'E-Mail',
    BUTTON_SAVE_CHANGES: 'Änderungen speichern',
    BUTTON_CONFIRM_FULFILL: 'Bestätigen',
    BUTTON_SUBMIT_FULFILL: 'Dokumentieren',
    BUTTON_UPLOAD_FULFILL: 'Hochladen',
    CARE_DEGREE: `{degree, select,
      none {Nein}
      unknown {Ich weiß nicht}
      requested {Beantragt}
      other {{degree}}
    }`,
    CARE_DEGREE_LABEL: 'Pflegegrad',
    CARE_RECIPIENT_LABEL: 'Kunde',
    CAREFORCE_MIGRATION_PENDING_DESCRIPTION:
      'Wir arbeiten daran, diese Einstellungen auch hier im neuen MeinCareship zur Verfügung zu stellen. Solange gehe bitte zum alten MeinCareship, um diese Einstellungen zu bearbeiten.',
    CAREFORCE_MIGRATION_PENDING_LINK: 'Im alten MeinCareship bearbeiten',
    COLLABORATION_ENDED_ON: 'Zusammenarbeit endete am {endsAt}',
    COLLABORATION_ENDS: 'Zusammenarbeit endet: {endsAt}',
    COLLABORATION_ENDS_ON: 'Zusammenarbeit endet zum {endsAt}',
    COLLABORATION_ENDS_ON_DESCRIPTION: 'Du kannst ab dem {endsAt} keine Termine mehr hinzufügen.',
    CONTACT_AND_ADDRESS: 'Kontaktdaten',
    CONTACT_AND_ADDRESS_ADDITIONAL_INFORMATION: 'Zusätzliche Adressdaten',
    CONTACT_AND_ADDRESS_BIRTHDAY: 'Geburtstag',
    CONTACT_AND_ADDRESS_CITY: 'Stadt',
    CONTACT_AND_ADDRESS_CONTACT_US_NAME_CHANGE: 'Kontaktiere uns, um deinen Namen zu ändern',
    CONTACT_AND_ADDRESS_CONTACT_US_EMAIL_CHANGE: 'Kontaktiere uns, um deine E-Mail zu ändern',
    CONTACT_AND_ADDRESS_COMPANY: 'Firma',
    CONTACT_AND_ADDRESS_EMAIL: 'E-Mail',
    CONTACT_AND_ADDRESS_FIRST_NAME: 'Vorname',
    CONTACT_AND_ADDRESS_LANDLINE: 'Festnetz',
    CONTACT_AND_ADDRESS_LAST_NAME: 'Nachname',
    CONTACT_AND_ADDRESS_MOBILE: 'Mobil',
    CONTACT_AND_ADDRESS_MOBILE_PHONE_NUMBER: 'Telefonnummer Mobil',
    CONTACT_AND_ADDRESS_PHONE_NUMBER: 'Telefonnummer Festnetz',
    CONTACT_AND_ADDRESS_POSTAL_CODE: 'PLZ',
    CONTACT_AND_ADDRESS_STREET: 'Straße',
    CONTACT_DETAILS: 'Kontaktinformationen',
    CONTACT_CARESHIP: 'Kontakt zu Careship',
    CONTRACT_CONFIRMATION_ACTION_TITLE: 'Wird es Folgetermine geben?',
    CONTRACT_CONFIRMATION_ACTION_CONFIRM: 'Ja',
    CONTRACT_CONFIRMATION_ACTION_CONFIRM_DIALOG: `{element, select,
      title {Gibt es wirklich Folgetermin?}
      message {Du kannst hier direkt im Anschluss Folgetermine hinzufügen oder auch später.}
      cancelButtonLabel {Abbrechen}
      confirmButtonLabel {Ja}
    }`,
    CONTRACT_CONFIRMATION_ACTION_MAYBE: 'Vielleicht',
    CONTRACT_CONFIRMATION_ACTION_MAYBE_DIALOG: `{element, select,
      title {Wirklich unklar, ob Folgetermine stattfinden?}
      message {Bitte kümmere Dich weiterhin darum, eure Zusammenarbeit zu klären.}
      cancelButtonLabel {Abbrechen}
      confirmButtonLabel {Ja, unklar}
    }`,
    CONTRACT_CONFIRMATION_ACTION_REJECT: 'Nein',
    CONTRACT_CONFIRMATION_ACTION_REJECT_DIALOG: `{element, select,
      title {Gibt es wirklich keine Folgetermine?}
      message {Wenn nicht, gehen wir davon aus, dass ihr nicht weiter zusammenarbeitet.}
      cancelButtonLabel {Abbrechen}
      confirmButtonLabel {Keine Folgetermine}
    }`,
    CONTRACT_CONFIRMATION_SECOND_APPOINTMENT_TITLE: 'Folgetermin hinzufügen',
    COMPANY: 'Firma',
    CUSTOMERS: 'Kunden',
    CUSTOMER: 'Kunde',
    CUSTOMER_DETAILS_SCREEN_TITLE: 'Kundendetails',
    CUSTOMER_COST_PER_HOUR: 'Kunde zahlt {cost}',
    CUSTOMER_SATISFACTION_LEVEL_HIGH: 'Großartig! Weiter so.',
    CUSTOMER_SATISFACTION_LEVEL_LOW: 'Bitte überlege, was Du verbessern kannst.',
    CUSTOMER_SATISFACTION_LEVEL_MID: 'Okay,… Und da ist noch Luft nach oben.',
    CUSTOMER_SATISFACTION_TITLE_1: 'Kundenbewertungen',
    CUSTOMER_SATISFACTION_TITLE_2: '(alle)',
    CUSTOMER_SATISFACTION_TOOLTIP_TITLE: 'Kundenbewertungen berücksichtigt:',
    CUSTOMER_SATISFACTION_TOOLTIP_BODY:
      'Durchschnitt aller erhaltenen Kundenbewertungen des Ersttermins. Mögliche Bewertungen zwischen 1-5.',
    DASHBOARD: 'Home',
    DASHBOARD_FEEDBACK_BUTTON: 'Feedback zur Statistik geben',
    DASHBOARD_FEEDBACK_TEXT: 'Wie findest Du oben stehende Übersicht über Deine Statistik?',
    DASHBOARD_FEEDBACK_TITLE: 'Deine Meinung ist uns wichtig',
    DASHBOARD_SUBHEADER: 'Schön, dass Du dabei bist',
    DASHBOARD_WELCOME_BACK: 'Willkommen zurück',
    DATEPICKER_END_DATE_LABEL: 'Bis',
    DATEPICKER_END_TIME_LABEL: 'Ende',
    DATEPICKER_START_DATE_LABEL: 'Von',
    DATEPICKER_START_TIME_LABEL: 'Beginn',
    DEFINITION_PROPOSAL_DECLINE_REASON: `{reason, select,
      distance_too_large {Entfernung zu groß}
      too_many_hours_requested {Stundenumfang zu groß}
      requested_services_do_not_match {Leistungen passen nicht}
      other {Andere Gründe}
    }`,
    DIARY: 'Termine',
    DETAILS: 'Details',
    DOCUMENT_BOOKING_ALERT:
      'Um Deine geleisteten Stunden abzurechnen, dokumentiere Deine Arbeitszeit und die erbrachten Leistungen.',
    DOCUMENT_BOOKING_ALERT_TITLE: 'WICHTIG:',
    DOCUMENT_TITLE: 'Mein Careship - {title}',
    DURATION_LABEL: 'Dauer',
    EARNED: 'Verdient',
    EARNINGS_PER_HOUR: 'Verdienst / Std.',
    EDIT_PROFILE: 'Profil',
    EDIT_PROFILE_HINT: 'bearbeiten',
    EDIT_PROFILE_EXTERNAL_DESCRIPTION:
      'Die Profil-Bearbeitung gibt es noch nicht in der App. Wir leiten Dich weiter zum alten MeinCareship, wo du Dein Profil bearbeiten kannst.',
    EDIT_PROFILE_EXTERNAL_CANCEL: 'Abbrechen',
    EDIT_PROFILE_EXTERNAL_CONFIRM: 'Weiter',
    ERROR_UNKNOWN: 'Ein Fehler ist aufgetreten. Bitte versuche es noch einmal.',
    ERROR_RESPONSE: `{errorCode, select,
      BOOKING_INVALID_ID {Termin ist nicht gültig}
      BOOKING_INVALID_SERVICE {Ausgewählte Leistung nicht gültig}
      BOOKING_IS_FULFILLED {Termin bereits dokumentiert}
      BOOKING_IS_INVOICED {Termin bereits abgerechnet}
      BOOKING_IS_OVERLAPPING {Termin überschneidet sich mit einem anderen Termin}
      BOOKING_NOT_FOUND {Termin nicht gefunden}
      CANNOT_DESERIALIZE_REQUEST_DATA {Daten können nicht dargestellt werden}
      CONTRACT_INVALID_ID {Vertrags-ID nicht gültig}
      CONTRACT_NOT_FOUND {Vertrag nicht gefunden}
      INTERNAL_SERVER_ERROR {Interner Server-Fehler}
      other {Ein Fehler ist aufgetreten ({errorCode}). Bitte versuche es noch einmal.}
    }`,
    EDIT_APPOINTMENT_MODAL_TITLE: 'Termin bearbeiten',
    CANCEL_APPOINTMENT_MODAL_TITLE: 'Termin absagen',
    EMAIL: 'E-Mail',
    ERROR_LOADING_DETAILS: 'Details können nicht geladen werden',
    EXISTING_CUSTOMER: 'Bestandskunde bei Careship',
    EXPERIENCE_WITH_MEDICAL_CONDITIONS: 'Erfahrung mit speziellen Krankheitsbildern',
    FINANCES_SECTION: 'Finanzen',
    FINANCIAL_OVERVIEW: 'Finanzen im Überblick',
    FIRST_APPOINTMENT: 'Ersttermin',
    FIRST_APPOINTMENT_ACTION_TITLE: 'Wurde ein Ersttermin vereinbart?',
    FIRST_APPOINTMENT_ACTION_CONFIRM: 'Ja',
    FIRST_APPOINTMENT_ACTION_MAYBE: 'In Klärung',
    FIRST_APPOINTMENT_ACTION_DECLINE: 'Nein',
    FIRST_APPOINTMENT_ACTION_DOCUMENT: 'Dokumentieren',
    FIRST_APPOINTMENT_CANCELATION_ACTION_TITLE: 'Der Ersttermin ist bestätigt',
    FIRST_APPOINTMENT_CANCELATION_ACTION_BUTTON: 'Ersttermin absagen & Vertrag beenden',
    FIRST_APPOINTMENT_CANCELATION_ACTION_TOOLTIP:
      'Wenn Du den Ersttermin absagst, kannst Du keine neuen Termine mehr für diese Kundin/diesen Kunden anlegen und der Vertrag wird beendet.',
    FIRST_APPOINTMENT_CHECKLIST: 'Checkliste Ersttermin',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_1_TITLE: 'Plane genügend Zeit für die Anreise ein. ',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_1_DESCRIPTION:
      'Schau Dir am Vortag noch einmal den Weg zum Kunden an und plane Deine Anreise – Pünktlichkeit ist selbstverständlich.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_2_TITLE:
      'Bereite Dich auf mögliche Fragen von Seiten des Kunden vor.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_2_DESCRIPTION:
      'Du bist der Experte – biete dem Kunden explizit die Möglichkeit an, Fragen zu stellen.\nMithilfe der Dokumente, die wir Dir hier in der App in Deinem Konto-Bereich bereitstellen, hast Du alle Informationen zu Abrechnung, Finanzierung und allgemeinen Careship-Abläufen schnell zur Hand. Falls Du etwas nicht beantworten kannst, biete dem Kunden an, bei uns nachzufragen.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_2_LINK: 'Hilfreiche Dokumente ansehen ',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_3_TITLE:
      'Bestätige nochmal die gewünschten Leistungen und kläre Besonderheiten ab.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_4_TITLE:
      'Erläutere dem Kunden den Ablauf der Rechnungsstellung.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_5_TITLE:
      'Informiere den Kunden über die Möglichkeit zur Finanzierung über die Versicherung, falls relevant.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_6_TITLE: 'Vereinbare weitere Termine',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_6_DESCRIPTION:
      'Besprich mit dem Kunden, wann ein Folgetermin stattfinden soll. Bitte gib ab dem Tag des Ersttermins direkt in der App im vorigen Schritt an, ob es Folgetermine gibt oder nicht – Du kannst weitere Termine anschließend direkt hinzufügen.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_7_TITLE:
      'Schließe das Gespräch mit dem Hinweis ab, dass Dich der Kunde jederzeit kontaktieren kann, falls weitere Fragen aufkommen.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_7_DESCRIPTION:
      'Gleiche nochmal Deine Kontaktdaten mit dem Kunden ab.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_8_TITLE:
      'Dokumentiere nach Abschluss diesen Ersttermin, damit er auf der nächsten Rechnung erscheint.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_8_DESCRIPTION:
      'Dies kannst Du auch hier in der App machen, sobald der Zeitpunkt Deines Termins vorüber ist.',
    FIRST_APPOINTMENT_CHECKLIST_ITEM_9_TITLE:
      'Gib uns bitte eine kurze Rückmeldung zu Deinem Ersttermin über einen Link, den Du von uns per Mail bekommst – oder auch direkt hier in der App bei Deiner Kundenansicht.',
    FIRST_APPOINTMENT_CONFIRM_FORM_SUBMIT: 'Termin findet statt',
    FIRST_APPOINTMENT_CONFIRM_MODAL_CONFIRM_DATE:
      'Bitte überprüfe Datum und Uhrzeit und ändere diese, falls notwendig.',
    FIRST_APPOINTMENT_CONFIRM_MODAL_TITLE: 'Ersttermin Zusage',
    FIRST_APPOINTMENT_DECLINE_MODAL_TITLE: 'Ersttermin Absage',
    FIRST_APPOINTMENT_DECLINE_FORM_HINT_WARNING:
      'Wenn Du den Ersttermin absagst, kannst Du keine Termine mehr für diesen Kunden anlegen und wir entfernen den Kunden und auch den Ersttermin aus Deiner Übersicht.',
    FIRST_APPOINTMENT_DECLINE_FORM_REASONS: `{reason, select,
      timing_issues {Wir finden keinen passenden Termin}
      die_chemie_stimmt_nicht {„Die Chemie stimmt nicht“}
      other_solution_found {Kunde hat sich für eine andere Lösung oder einen anderen Anbieter entschieden}
      no_binding_booking {Kunde wollte gar nicht buchen}
      different_type_of_caregiver_needed {Kunde möchte anderen Alltagshelfer (Geschlecht, Alter, etc.)}
      different_cg_needed_gender {Kunde möchte einen männlichen/weiblichen Alltagshelfer}
      different_cg_needed_age {Kunde möchte einen älteren/jüngeren Alltagshelfer}
      different_cg_needed_insufficient_experience {Kunde möchte einen Alltagshelfer mit mehr Erfahrung}
      different_cg_needed_language {Kunde möchte einen Alltagshelfer, der dessen Sprache spricht}
      different_cg_needed_lacks_covid_vaccination {Kunde möchte einen Helfer mit vollständiger COVID-19-Impfung}
      different_cg_needed_other {Kunde möchte einen anderen Alltagshelfer (andere Gründe)}
      customer_needs_different_services {Kunde benötigt andere Dienstleistungen als erwartet}
      customer_chose_different_company {Kunde hat sich für einen anderen Anbieter entschieden}
      customer_did_not_want_to_book {Kunde wollte gar nicht buchen}
      distance_too_far {Zu weit weg}
      customer_not_ready_yet {Kunde ist noch nicht bereit (Krankenhausaufenthalt, möchte keine Hilfe annehmen, finanzielle Gründe usw.)}
      customer_not_reached {Kunde nicht erreicht}
      other {Anderer Grund}
    }`,
    FIRST_APPOINTMENT_DECLINE_FORM_DESCRIPTION_TITLE: 'Weitere Bemerkung',
    FIRST_APPOINTMENT_DECLINE_FORM_DESCRIPTION_PLACEHOLDER:
      'Tippe hier, um Bemerkungen zu schreiben ...',
    FIRST_APPOINTMENT_DECLINE_FORM_TITLE: 'Warum findet der Ersttermin nicht statt?',
    FIRST_APPOINTMENT_CONFIRMED_FOR: 'Vereinbarter Ersttermin',
    FIRST_APPOINTMENT_PLANNED_FOR: 'Geplanter Ersttermin',
    FIRST_APPOINTMENT_REQUESTED_FOR: 'Ersttermin angefragt für',
    FIRST_APPOINTMENT_UNCONFIRMED: 'Ungeklärt',
    FIRST_APPOINTMENT_CONFIRMED: 'Bestätigt',
    FIRST_APPOINTMENT_DECLINED: 'Ersttermin abgesagt',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_DESCRIPTION:
      'Bitte Checkliste für den Ersttermin lesen – ab dem Zeitpunkt des Ersttermins kannst Du hier Folgetermine hinzufügen.',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_PAST_DESCRIPTION:
      'Dokumentiere diesen und alle weiteren vergangenen Termine, damit sie auf der kommenden Rechnung erscheinen. ',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_PAST_TITLE: 'Ersttermin vorbei',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_TITLE: 'Ersttermin bestätigt',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_TODAY_DESCRIPTION:
      'Bitte Checkliste für den Ersttermin lesen und den Termin nach Abschluss dokumentieren, damit er auf der kommenden Rechnung erscheint. ',
    FIRST_APPOINTMENT_STATUS_BAR_CONFIRMED_TODAY_TITLE: 'HEUTE Ersttermin',
    FIRST_APPOINTMENT_STATUS_BAR_UNCONFIRMED_TITLE: 'Ersttermin ungeklärt – bitte anrufen',
    FIRST_APPOINTMENT_TOOLTIP:
      '{prefix} auf {yes} , wenn Du sie/ihn erreichen konntest und Ihr einen Ersttermin ausgemacht habt; {maybe} , wenn Du Dich noch mit ihr/ihm auf einen Zeitpunkt für den Ersttermin einigen musst oder sie/ihn noch nicht erreichen konntest. Oder drücke auf {no} wenn Du Deine Kundin / Deinen Kunden nicht erreichen konntest oder Ihr Euch nicht auf einen Ersttermin einigen konntet. Sollte sich dieser Status ändern, aktualisiere hier bitte unbedingt Deine Angabe!',
    FIRST_APPOINTMENT_TOOLTIP_PREFIX:
      'Nach Eingang der Anfrage hast Du bis zu 24 Stunden Zeit, Deine Kundin / Deinen Kunden anzurufen und einen Ersttermin zu vereinbaren. Klicke anschließend',
    FIRST_APPOINTMENT_TOOLTIP_YES: '(JA)',
    FIRST_APPOINTMENT_TOOLTIP_NO: '(NEIN)',
    FIRST_APPOINTMENT_TOOLTIP_MAYBE: '(IN KLÄRUNG)',
    FIRST_APPOINTMENT_UPDATE_STATUS_CURRENT_REASON: 'Dein zuletzt gewählter Grund',
    FIRST_APPOINTMENT_UPDATE_STATUS_NEW_REASON: 'Neuer Grund',
    FIRST_APPOINTMENT_UPDATE_STATUS_MODAL_TITLE: 'Ersttermin noch unklar',
    FIRST_APPOINTMENT_UPDATE_STATUS_MODAL_REASON:
      'Warum findet der Ersttermin nur vielleicht statt?',
    FIRST_APPOINTMENT_UPDATE_STATUS_FORM_SUBMIT: 'Speichern',
    FIRST_APPOINTMENT_UPDATE_STATUS_MODAL_DESCRIPTION: 'Weitere Bermerkung',
    FIRST_APPOINTMENT_UPDATE_STATUS_MISSING_DESCRIPTION: 'Beschreibung darf nicht leer sein.',
    FIRST_APPOINTMENT_UPDATE_STATUS_MISSING_REASON: 'Weitere Bermerkung darf nicht leer sein.',
    FIRST_APPOINTMENT_UPDATE_STATUS_REASON: `{reason, select,
      reason_not_reachable {Kunde nicht erreicht – ich versuche es weiter}
      reason_discuss_with_family {Kunde möchte nochmal Rücksprache mit der Familie halten – wir sprechen nochmal}
      reason_timing_issues {Wir haben noch Klärungsbedarf für die Terminabsprache – wir sprechen nochmal}
      reason_financing_unclear {Finanzierung beim Kunden noch unklar – wir sprechen nochmal}
      reason_customer_unsure {Kunde/Kundin noch unentschieden, ob er/sie Careship nutzen möchten – wir sprechen nochmal}
      reason_other {Anderer Grund}
      other {{ reason }}
    }`,
    FIRST_APPOINTMENT_SURVEY_BUTTON: 'Wie war der Ersttermin?',
    FIRST_APPOINTMENT_SURVEY_COMPLETED: 'Danke für Deine Rückmeldung zum Ersttermin.',
    FIRST_CALL_BUTTON: 'Anrufen',
    FIRST_CALL_CHECKLIST: 'Kundenanruf & Ersttermin Le...',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_1_DESCRIPTION:
      'Passe ggf. Datum und Uhrzeit an, wie mit dem Kunden vereinbart.',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_1_TITLE: 'Ja',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_2_DESCRIPTION:
      'Versuche zu einem späteren Zeitpunkt erneut den Ersttermin zu klären – wir unterstützen euch ggf. dabei.',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_2_TITLE: 'Vielleicht',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_3_DESCRIPTION:
      'Lass uns bitte wissen warum und wir entfernen den Kunden und den Ersttermin aus Deiner Übersicht.',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIP_3_TITLE: 'Nein',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIPS_DESCRIPTION:
      'Gib dies auf der Kundenseite in der App an.',
    FIRST_CALL_CHECKLIST_FIRST_BOOKING_TIPS_TITLE: 'Wird der Ersttermin stattfinden?',
    FIRST_CALL_CHECKLIST_ITEM_1_TITLE: 'Stelle Dich als Alltagsheld von Careship vor.',
    FIRST_CALL_CHECKLIST_ITEM_2_TITLE: 'Besprich Datum und Uhrzeit für den Ersttermin.',
    FIRST_CALL_CHECKLIST_ITEM_3_ITEMS:
      'Gewünschte Leistungen;Besonderheiten, die beachtet werden sollen;Weitere wichtige Informationen, die relevant sind',
    FIRST_CALL_CHECKLIST_ITEM_3_TITLE: 'Kläre mit dem Kunden die Details zum Ersttermin.',
    FIRST_CALL_CHECKLIST_ITEM_4_DESCRIPTION:
      'Zum Beispiel zum Ablauf der Rechnungslegung und Bezahlung oder auch zu den Abrechnungsmöglichkeit über die Versicherung.',
    FIRST_CALL_CHECKLIST_ITEM_4_TITLE:
      'Erkundige dich nach Fragen von Seiten des Kunden und kläre diese ggf.',
    FIRST_CALL_CHECKLIST_ITEM_5_TITLE:
      'Biete dem Kunden an, dass Du im Ersttermin gerne nochmal durch alle Details zur Abrechnung führst.',
    FIRST_CALL_CHECKLIST_ITEM_6_DESCRIPTION: 'Teile nochmal Deine Kontaktdaten.',
    FIRST_CALL_CHECKLIST_ITEM_6_TITLE:
      'Schließe das Gespräch mit dem Hinweis ab, dass Dich der Kunde jederzeit kontaktieren kann, falls weitere Fragen aufkommen.',
    FORGOTTEN_PASSWORD: 'Passwort vergessen?',
    FORGOTTEN_PASSWORD_LEGEND:
      'Bitte trage Deine E-Mail-Adresse ein. Wir schicken Dir einen Link zum Zurücksetzen Deines Passwortes.',
    FORGOTTEN_PASSWORD_REQUEST_BUTTON: 'Passwort zurücksetzen',
    FORGOTTEN_PASSWORD_REQUEST_SUCCESS: 'Bitte überprüfe Deine E-Mails',
    FORGOTTEN_PASSWORD_EMAIL_SENT:
      'Wir haben Dir eine E-Mail mit dem Link zum Zurücksetzen Deines Passworts geschickt – allerdings nur, wenn die von Dir angegebene E-Mail-Adresse bei Careship für ein Login verwendet wird. Wenn Du keine E-Mail erhalten hast, existiert diese E-Mail-Adresse bei uns nicht.',
    FORGOTTEN_PASSWORD_REQUEST_LOGIN_BUTTON: 'Zurück zum Login',
    FREQUENCY_LABEL: 'Häufigkeit',
    GENERAL_EXPERIENCE: 'Erfahrungen Allgemein',
    HOURS_WITH_UNIT: '{hours} STD',
    FLEXIBLE_APPOINTMENT:
      'Der Ersttermin ist {prefix} und kann in Absprache mit dem Kunden festgelegt werden',
    FLEXIBLE_APPOINTMENT_PREFIX: 'flexibel',
    FLEXIBLE_APPOINTMENT_TOOLTIP_BODY:
      'Nach Vergabe der Anfrage hast Du 24 Stunden Zeit, um Deine Kundin/Deinen Kunden zu kontaktieren und den Ersttermin zu vereinbaren. Dieser ist flexibel. Bitte beachte dennoch die gewünschte Dauer und bei Folgeterminen auch die Häufigkeit. Sobald Ihr Euch auf einen Zeitpunkt geeinigt habt, passe diesen bitte entsprechend über das Bearbeitungssymbol (Stift) hier an.',
    HEADER_BACK_BUTTON: 'Zurück',
    HELP_CENTER: 'Hilfecenter',
    IBAN: 'IBAN',
    INSURANCE_DIRECT_PAYMENT_TITLE: 'Versicherungskunde Direktzahlung',
    INSURANCE_DIRECT_PAYMENT_DESCRIPTION:
      'Die Kundenversicherung zahlt Deine Leistungen direkt an Careship und wir überweisen das, abzüglich der Provision, auf Dein bei uns angegebenes Bankkonto. Ausfüllen und Hochladen des zusätzlichen Leistungsnachweises ist bei diesem Kunden für Dich Pflicht.',
    INSURANCE_DIRECT_PAYMENT_LINK: 'mehr',
    INVALID_GRANT:
      'E-Mail oder Passwort nicht korrekt. Falls Deine Bewerbung als Alltagshelfer:in noch nicht vollständig abgeschlossen ist, hast Du noch keinen Zugang zu MeinCareship.',
    INVOICED_BOOKING: 'Abgerechnet',
    INVOICE_LIST: 'Alle Rechnungen',
    INVOICE_LIST_EMPTY: 'Keine Rechnungen vorhanden',
    INVOICE_CANCELLED: 'Storniert',
    INVOICE_TYPE: `{invoiceType, select,
      CUSTOMER {Kunde}
      COMMISSION {Provision}
      CUSTOMER_STORNO {Kunde Storno}
      COMMISSION_STORNO {Provision Storno}
      other {{invoiceType}}
    }`,
    INVOICE_LIST_WITH_ANONYMIZED:
      'Du hast im Jahr {selectedYear} Rechnungen von Kunden, die bei uns Ihre Daten anonymisiert haben wollten und für die wir Dir hier leider keine Rechnungen anzeigen können. Bitte wende Dich dazu per {customerServiceLink}.',
    INVOICE_LIST_WITH_ANONYMIZED_LINK: 'E-Mail an unseren Kundenservice',
    INVOICE_PDF_VIEW_DOWNLOAD: 'Herunterladen/Teilen',
    INVOICE_REQUEST_ANONYMIZED_BODY:
      'Liebes Careship-Team,\n\nbitte schickt mir die Rechnungen aus dem Jahr {year} für diejenigen meiner Kunden zu, die bei euch eine Anonymisierung ihrer Kundendaten erwirkt haben.\n\nVielen Dank im Voraus.',
    INVOICE_REQUEST_ANONYMIZED_SUBJECT: 'Rechnungen {year} für anonymisierte Kundendaten',
    CAREGIVER_LOGIN: 'Login für Alltagshilfen',
    LANGUAGES: `{key, select,
      GERMAN {Deutsch}
      ENGLISH {Englisch}
      SPANISH {Spanisch}
      FRENCH {Französisch}
      ARABIC {Arabisch}
      CHINESE {Chinesisch}
      CROATIAN {Kroatisch}
      CZECH {Tschechisch}
      DANISH {Dänisch}
      DUTCH {Niederländisch}
      FINNISH {Finnisch}
      GREEK {Griechisch}
      HEBREW {Hebräisch}
      HUNGARIAN {Ungarisch}
      ITALIAN {Italienisch}
      JAPANESE {Japanisch}
      KOREAN {Koreanisch}
      POLISH {Polnisch}
      PORTUGUESE {Portugiesisch}
      NORWEGIAN {Norwegisch}
      ROMANIAN {Rumänisch}
      RUSSIAN {Russisch}
      SERBIAN {Serbisch}
      SIGN_LANGUAGE {Gebärdensprache}
      SWEDISH {Schwedisch}
      TURKISH {Türkisch}
      VIETNAMESE {Vietnamesisch}
      other {Andere Sprache}
    }`,
    LOGIN: 'Einloggen',
    LOGIN_TITLE: 'Betreuer Login',
    LOGOUT: 'Ausloggen',
    RESET_PASSWORD: 'Passwort vergessen?',
    LOG_APPOINTMENT_MODAL_TITLE: 'Termin dokumentieren',
    MORE_ABOUT_CARE_RECEIVER: 'Mehr zu {name}',
    MY_CUSTOMERS: 'Meine Kunden',
    MY_PROPOSALS: 'Meine Anfragen',
    MONTHLY_EARNINGS_EARNINGS: 'Verdienst',
    MONTHLY_EARNINGS_DOCUMENTED_HOURS: 'Dokumentierte Std.',
    MONTHLY_EARNINGS_HOURS: 'Std',
    MONTHLY_EARNINGS_MINUTES: 'min',
    MONTHLY_EARNINGS_TITLE: 'Dein Verdienst für {month}',
    MONTHLY_EARNINGS_TITLE_1: 'Verdienst',
    MONTHLY_EARNINGS_TITLE_2: '(im {month})',
    MONTHLY_EARNINGS_TOOLTIP_TITLE: 'Verdienst berücksichtigt:',
    MONTHLY_EARNINGS_TOOLTIP_BODY:
      'Die Summe aller dokumentierten Stunden des aktuellen Monats (abzüglich der Provision).',
    MONTHLY_HOURS_TOOLTIP_TITLE: 'Stunden berücksichtigt:',
    MONTHLY_HOURS_TOOLTIP_BODY: 'Die Summe aller dokumentierten Stunden des aktuellen Monats.',
    MY_SERVICES: 'Meine Leistungen',
    NAME_FULL: '{lastName}, {firstName}',
    NAME_PARTIAL: `{gender, select,
      male {Herr {name}.}
      female {Frau {name}.}
      other {{name}}
    }`,
    NAME_WITH_SALUTATION: `{gender, select,
      male {Herr {name}}
      female {Frau {name}}
      other {{name}}
    }`,
    NEW_CUSTOMER: 'Neukunde bei Careship',
    ONLY_VISIBLE_TO_CARESHIP: 'Nur für Careship-Mitarbeiter sichtbar',
    OTHER_SERVICES: 'Sonstiges',
    OTHER_SERVICES_LABEL: 'Sonstige Leistungen',
    OTHER_REASONS_LABEL: 'Sonstige Gründe',
    PASSWORD: 'Passwort',
    PASSWORD_RESET: 'Bitte wähle Dein Passwort',
    PASSWORD_RESET_BUTTON: 'Speichern und einloggen',
    PASSWORD_MISMATCH: 'Passwort muss übereinstimmen',
    PASSWORD_MIN_LENGTH: 'Mindestens 6 Zeichen',
    PASSWORD_WAS_RESET:
      'Du hast Dein Passwort erfolgreich geändert.\nBitte logge Dich jetzt mit Deinem neuen Passwort ein.',
    NEW_PASSWORD: 'Passwort erstellen',
    NEW_PASSWORD_CONFIRMATION: 'Passwort bestätigen',
    PAST: 'Vergangene',
    PAYMENT_METHOD: `{paymentMethod, select,
      invoice {Rechnung}
      sepa {SEPA-Lastschrift}
      sepa_direct_debit {SEPA-Lastschrift}
      direct_reimbursement {Versicherung Direktzahlung}
      other {{paymentMethod}}
    }`,
    PAYMENT_METHOD_LABEL: 'Zahlungsmethode',
    PROFILE: 'Profil',
    PROFILE_ABOUT_ME: 'Über mich',
    PROFILE_ABOUT_ME_INTRO:
      'Sag etwas zu Dir und Deinen Interessen. Alltagshelfer mit einer Beschreibung über sich erhalten bis zu 20 % mehr Anfragen.',
    PROFILE_CURRENT_POSITION: 'Beruf',
    PROFILE_EDUCATION_AND_QUALIFICATIONS: 'Ausbildung und Qualifikationen',
    PROFILE_EDUCATION_AND_QUALIFICATIONS_SCREEN_TITLE: 'Ausbildung und Qualifikationen',
    PROFILE_EXPERIENCE_AND_ABILITIES: 'Erfahrungen und Fähigkeiten',
    PROFILE_LANGUAGES: 'Sprachkenntnisse',
    PROFILE_SERVICES: 'Meine Leistungen',
    PROPOSAL: 'Anfrage',
    PROPOSAL_ACCEPT_BUTTON: 'Bewerben',
    PROPOSAL_AWAITING_CUSTOMER: 'Warten auf Rückmeldung von Careship',
    PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE: 'Wochentage und Uhrzeit flexibel',
    PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE_LABEL: 'Folgetermine',
    PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE_NO_SCHEDULE: 'keine, dies ist ein Einzeltermin',
    PROPOSAL_EXPECTED_APPOINTMENT_SCHEDULE_PREFERENCES_PREFIX: 'Wochentag & Uhrzeit',
    PROPOSAL_NEW: 'Neue Anfrage',
    SERVICE_NEW: 'Neue Leistungen',
    PROPOSAL_DECLINE_DESCRIPTION_LABEL: 'Beschreibung der anderen Gründe',
    PROPOSAL_DECLINE_MODAL_TITLE: 'Anfrage ablehnen, weil ...',
    PROPOSAL_UNAVAILABLE: 'Anfrage abgelaufen',
    PROPOSAL_UNAVAILABLE_TITLE: 'Leider ist diese Anfrage bereits vergeben',
    PROPOSAL_UNAVAILABLE_SUBTITLE: 'Viel Erfolg beim nächsten Mal!',
    PROPOSAL_DISPLAY_DETAILS: 'Details ansehen',
    PROPOSAL_RESPONSE_EMPTY: 'Du hast keine Anfragen erhalten innerhalb der letzten 4 Wochen.',
    PROPOSAL_RESPONSE_FIRST_CALL_TIMING: 'Rückmeldung nach gewonnener Anfrage',
    PROPOSAL_RESPONSE_TITLE_1: 'Anfragen',
    PROPOSAL_RESPONSE_TITLE_2: '(letzte 4 Wochen)',
    PROPOSAL_RESPONSE_TOOLTIP_TITLE: 'Anfragen berücksichtig:',
    PROPOSAL_RESPONSE_TOOLTIP_BODY: `Anfragen beantwortet = % aller beantworteten Anfragen. \nRückmeldung nach gewonnener Anfrage = wie lange dauert es im Durchschnitt, bis Du den möglichen Kunden einer gewonnenen Anfrage kontaktiert und zurückgemeldet hast, ob tatsächlich ein Ersttermin stattfindet.\nDie kleinen Pfeile zeigen Dir, ob die Zahl im Vergleich zu den vorherigen 4 Wochen rauf oder runter gegangen ist.`,
    PROPOSAL_RESPONSE_RESPONSE_RATE: 'Anfragen\nbeantwortet',
    PROPOSAL_RESPONSE_UNIT_HOURS: 'Std.',
    PROPOSAL_STATUS: `{status, select,
      OPEN {Unbeantwortet}
      ACCEPTED {Beworben}
      other {{status}}
    }`,
    RECURRING_BOOKING_LABEL: 'Wiederkehrender Termin',
    RECURRING_BOOKING_LABEL_HINT: 'Für 4 weitere Wochen erstellen',
    REFERRAL_CODE_CUSTOMER_AMOUNT: '{amount} pro Neukunde',
    REFERRAL_CODE_CAREGIVER_AMOUNT: '{amount} pro Alltagshelfer',
    REFERRAL_CODE_COPY_BUTTON: 'Code kopieren',
    REFERRAL_CODE_COPY_BUTTON_COPIED: 'Kopiert',
    REFERRAL_CODE_COPY_BUTTON_HINT:
      'Mit Klick auf den Button kopierst Du Deinen Code, einen kleinen Begrüßungstext und einen Link zu Careship – füge dies z.B. in eine E-Mail ein und schicke sie an Neukunden.',
    REFERRAL_CODE_TITLE: 'Careship weiterempfehlen',
    REFERRAL_CODE_SHARE_BUTTON: 'Code weitergeben',
    REFERRAL_CODE_SHARE_MESSAGE:
      'Empfehlungscode: {referralCode} \n\nSie wurden eingeladen, Careship auszuprobieren. Careship bietet Alltagshilfe aus der Nachbarschaft für Senioren. Wenn Sie einen Alltagshelfer suchen, geben sie den Empfehlungscode im letzten Schritt Ihrer Suche nach einem Alltagshelfer im Feld ,Aktionscode‘ ein. Wenn Sie sich als Alltagshelfer bewerben möchten, geben Sie den Empfehlungscode mündlich im Interview Teil Ihrer Bewerbung an. \n\nwww.careship.de',
    REFERRAL_CODE_STEPS_TITLE: 'So geht’s',
    REFERRAL_CODE_STEPS_FIRST_TEXT_SHARE_CODE:
      'Du kannst Deinen Code aufschreiben und weitergeben oder Du klickst auf ‚Code weitergeben‘ und wählst eine der angebotenen Möglichkeiten (z.B. per E-Mail, SMS, WhatsApp…), um Deinen Code so einfach an mögliche Neukunden oder Alltagshelfer weiterzugeben.',
    REFERRAL_CODE_STEPS_FIRST_TEXT_COPY_CODE:
      'Du kannst Deinen Code aufschreiben und weitergeben, oder Du klickst auf ‚Code kopieren‘ und kopierst damit Deinen Code, einen kleinen Begrüßungstext und einen Link zu Careship. Dies fügst Du dann z.B. in eine E-Mail, WhatsApp, SMS oder was auch immer Du gerne benutzen möchtest ein, und schickst sie an mögliche Neukunden oder neue Alltagshelfer.',
    REFERRAL_CODE_STEPS_FIRST_TITLE: 'Gib Deinen Empfehlungscode weiter',
    REFERRAL_CODE_STEPS_SECOND_TEXT:
      'Neukunde:\nBei der Suche nach einem Alltagshelfer auf Careship, geben die von Dir geworbenen Neukunden Deinen Code im letzten Schritt Ihrer Suche im Eingabefeld ‚Aktionscode‘ ein.\n\nNeue Alltagshelfer:\nBei der Online-Bewerbung als Alltagshelfer auf Careship geben die von Dir geworbenen Alltagshelfer Deinen Code als eine der letzten Fragen bei Ihrer Bewerbung an.',
    REFERRAL_CODE_STEPS_SECOND_TITLE:
      'Neukunden und neue Alltagshelfer nutzen Deinen Empfehlungscode',
    REFERRAL_CODE_STEPS_THIRD_BANK_ACCOUNT: 'Bankkonto',
    REFERRAL_CODE_STEPS_THIRD_TEXT:
      'Lege ein {bankAccount} hierfür fest, falls Du das noch nicht getan hast.\n\nBezahlung bei Neukunden:\nSobald der von Dir geworbene Neukunde mindestens 3 abgerechnete Stunden hat, wirst Du von uns informiert und erhältst unser Dankeschön von {customerReferralAmount} auf das von Dir bei uns angegebene Bankkonto.\n\nBezahlung bei neuen Alltagshelfern:\nSobald der von Dir geworbene neue Alltagshelfer seinen ersten Kunden über Careship hat, wirst Du von uns informiert und erhältst unser Dankeschön in Höhe von {caregiverReferralAmount} auf das von Dir bei uns angegebene Bankkonto.',
    REFERRAL_CODE_STEPS_THIRD_TITLE: 'Erhalte Deine Bezahlung als Dankeschön von Careship',
    REFERRAL_CODE_YOUR_CODE: 'Dein Empfehlungscode',
    PROPOSAL_DECLINE_BUTTON: 'Ablehnen',
    SCROLL_UP: 'nach oben',
    SEND_FEEDBACK_BTN: 'Feedback senden',
    SEND_FEEDBACK_TEXT:
      'Hast du einen Fehler in der App gefunden? Können wir etwas besser machen? Bitte sende uns Dein Feedback. Wir freuen uns, von Dir zu hören!',
    SEND_FEEDBACK_TITLE: 'Sende uns Dein Feedback!',
    SERVICE_DOCUMENTATION: 'Leistungsnachweise',
    SERVICE_DOCUMENTATION_HOW_TO: 'So geht’s',
    SERVICE_DOCUMENTATION_HOW_TO_DESCRIPTION:
      'Der monatliche Leistungsnachweis ist eine zusätzliche Dokumentation Deiner Leistungen in Papierform, als Nachweis Deiner Leistungen für Dich und für die Kundenversicherung zur Kostenabwicklung.',
    SERVICE_DOCUMENTATION_HOW_TO_FILL: `{element, select,
      title {Ausfüllen}
      description {Leistungsnachweis bitte immer handschriftlich ausfüllen – zur Zeit wollen die Versicherungen das noch so.}
      link {Leistungsnachweis Ausfüllhilfe}
      firstItemBold {Allgemeine Kundendaten & Monat/Jahr}
      firstItem { im oberen Teil gemeinsam mit dem Kunden am besten gleich beim Kennenlerntermin vollständig ausfüllen.}
      secondItemBold {Nach JEDEM Termin}
      secondItem { Datum, Zeit und die erbrachte Leistungen eintragen und von Dir und Deinem Kunden unterschreiben.}
      secondItemImportant {Wichtig:}
      secondItemImportantMessage { Bitte stelle sicher, dass die Termine auf dem Leistungsnachweis GENAU mit Deinen dokumentierten Terminen in Deinem MeinCareship übereinstimmen, denn nur dann akzeptiert die Kundenversicherung dies als Nachweis.}
      thirdItemBold {Versicherungsdaten des Kunden}
      thirdItem { im unteren Teil sind unterschiedlich auszufüllen, je nach Zahlungsmethode Deines Kunden (sichtbar auf der Kundenseite):}
      thirdItemFirstItemBold {Bei ‚Versicherungskunde Direktzahlung‘}
      thirdItemFirstItem { brauchst Du den unteren Teil für die Pflegeversicherung NICHT auszufüllen, da das Geld in diesem Fall DIREKT an Dich gezahlt wird.}
      thirdItemSecondItemBold {Bei Kunden, die Dich vorab selber bezahlen, über ‚Rechnung‘ oder auch ‚SEPA‘}
      thirdItemSecondItem {, und die berechtigt sind die Kosten von ihrer Versicherung zurück zu bekommen, bitte den unteren Teil für die Pflegeversicherung ausfüllen. Frage Deinen Kunden nach der Berechtigung für die Verhinderungspflege §39 (Vorraussetzung mind. Pflegegrad 2) und füllt das am besten gleich beim Kennenlerntermin aus, auch wenn der Kunde das noch nicht sicher weiß.}
      fourthItemBold {Nach dem letzten Termin}
      fourthItem { den Leistungsnachweis ganz unten unterschreiben vom Kunden (=Versicherter) und auch von Dir als Alltagshelfer (=Leistungserbringer). Bei ‚Versicherungskunde Direktzahlung‘ nicht zwingend erforderlich.}
      other {{ element }}
    }`,
    SERVICE_DOCUMENTATION_HOW_TO_PRINT: `{element, select,
      title {Ausdrucken}
      description {Leistungsnachweis-Vorlage (auf der vorherigen Seite) ausdrucken, und gleich beim Kennenlerntermin mit zum Kunden nehmen. In der Regel verbleibt der Zettel bei Deinem Kunden.}
      other {{ element }}
    }`,
    SERVICE_DOCUMENTATION_HOW_TO_UPLOAD: `{element, select,
      title {Hochladen}
      descriptionBold {Mache nach jedem Termin}
      description { ein Foto/Scan des aktuellen Leistungsnachweises mit dem neu eingetragenen Termin und lade es dann am besten sofort bei Deinem Kunden in Deinem MeinCareship hoch. Nach jedem weiteren Termin machst Du wieder ein Foto und aktualisierst so das Bild Deines hochgeladenen Leistungsnachweises.\nAchte darauf, dass das Foto/der Scan gut lesbar ist und möglichst wenig anderer Hintergrund im Bild sichtbar ist.}
      other {}
    }`,
    SERVICE_DOCUMENTATION_HOW_TO_WHAT_HAPPENS_NOW: `{element, select,
      title {Was passiert jetzt mit dem Leistungsnachweis?}
      description {Immer wenn die Kundenversicherung in die Zahlung involviert ist, muss der vollständig ausgefüllte Leistungsnachweis zusammen mit der dazugehörigen Rechnung am Ende des Monats an die Kundenversicherung geschickt werden.}
      dirTitle {Versicherungskunde Direktzahlung}
      dirDescriptionBold {Careship verschickt}
      dirDescription { den Leistungsnachweis und die dazugehörige Rechnung.}
      dirItemsFirst {Wir prüfen am dritten Tag des Folgemonats Deine hochgeladenen Leistungsnachweise und Deine dokumentierten Termine in Deinem MeinCareship auf GENAUE Übereinstimmung und Vollständigkeit.}
      dirItemsSecond {Wenn alles stimmt, schicken wir es an die Kundenversicherung. Diese zahlt Deine Leistungen an Careship und wir überweisen Dir Deinen Verdienst, bereits abzüglich der Provision, direkt auf das von Dir bei uns angegebene Bankkonto. Die Kundenversicherung braucht in der Regel ca. 10 Tage, um alles zu prüfen und das Geld zu überweisen. Bei Unstimmigkeiten/Unvollständigkeit geben wir Dir Bescheid.}
      customerPaymentTitle {Versicherungskunde Vorkasse\n(z.B. Rechnung oder SEPA)}
      customerPaymentDescriptionBold {Kunde verschickt}
      customerPaymentDescription { den Leistungsnachweis und die dazugehörige Rechnung selber.}
      customerPaymentItem {Kunden, die Deine Leistungen vorab selber bezahlen und die berechtigt sind, die Kosten von ihrer Versicherung im Rahmen der Verhinderungspflege (§39) erstattet zu bekommen, schicken den Leistungsnachweis und die dazugehörige Rechnung selbständig an ihre Pflegeversicherung.}
      customerPaymentItemAccent { Für viele Kunden ist das nicht so leicht, die Papiere zusammen zu haben und den Weg zum Briefkasten zu schaffen – oder alternativ alles per E-Mail fertig zu machen. Sie freuen sich über Deine Unterstützung dabei, falls das für Dich möglich ist.}
      other {{ element }}
    }`,
    SERVICE_DOCUMENTATION_INSTRUCTION_TITLE: 'Leistungsnachweis Bildqualität',
    SERVICE_DOCUMENTATION_INSTRUCTION_TEXT:
      'Bitte stelle sicher, dass Dein hochgeladenes Bild gut lesbar ist. Achte darauf, dass möglichst nur der Leistungsnachweis und kaum anderer Hintergrund sichtbar ist.',
    SERVICE_DOCUMENTATION_INSTRUCTION_BUTTON: 'Verstanden – jetzt hochladen',
    SERVICE_DOCUMENTATION_UPLOAD_SHOW: 'Anzeigen',
    SERVICE_DOCUMENTATION_LABEL: 'Leistungsnachweise',
    SERVICE_DOCUMENTATION_LABEL_MISSING: 'fehlt',
    SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_IOS:
      'MeinCareship hat keinen Zugriff auf Deine Kamera. Um den Zugriff zu erlauben, gehe auf Einstellungen und aktiviere den Zugriff auf die Kamera.',
    SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_ANDROID:
      'MeinCareship hat keinen Zugriff auf Deine Kamera- und Mediendateien. Um den Zugriff zu erlauben, gehe auf Einstellungen und gib im Bereich Berechtigungen den Zugriff auf Dateien und Medien sowie auf die Kamera frei.',
    SERVICE_DOCUMENTATION_MISSING_LIBRARY_PERMISSION_MESSAGE_IOS:
      'MeinCareship hat keinen Zugriff auf Deine Fotos. Um den Zugriff zu erlauben, gehe auf Einstellungen und aktiviere den Zugriff auf Fotos.',
    SERVICE_DOCUMENTATION_MISSING_LIBRARY_PERMISSION_MESSAGE_ANDROID:
      'MeinCareship hat keinen Zugriff auf Deine Kamera- und Mediendateien. Um den Zugriff zu erlauben, gehe auf Einstellungen und gib im Bereich Berechtigungen den Zugriff auf Dateien und Medien frei.',
    SERVICE_DOCUMENTATION_PDF_VIEW_DOWNLOAD: 'Herunterladen/Teilen',
    SERVICE_DOCUMENTATION_TOP_BANNER_DIR:
      'Versicherungskunde Direktzahlung – Leistungsnachweis-Pflicht',
    SERVICE_DOCUMENTATION_TEMPLATE: 'Leistungsnachweis-Vorlage',
    SERVICE_DOCUMENTATION_UPLOAD_BOTTOM_SHEET: `{element, select,
      title {Leistungsnachweis hinzufügen}
      camera {Kamera}
      image {Foto auswählen}
      document {Hochladen}
      cancel {Abbrechen}
    }`,
    SERVICE_DOCUMENTATION_UPLOAD_TITLE: 'Leistungsnachweis hinzufügen',
    SERVICE_DOCUMENTATION_UPLOAD_CAMERA: 'Kamera',
    PERFORMANCE_DOCUMENTATION_TITLE: 'Leistungsnachweis',
    PERFORMANCE_DOCUMENTATION_DATE_FILE_HELP: 'Datei',
    PERFORMANCE_DOCUMENT_NO_IMAGE_FOUND : 'Kein Bild gefunden',
    SERVICE_DOCUMENTATION_UPLOAD_IMAGE: 'Foto auswählen',
    SERVICE_DOCUMENTATION_UPLOAD_DOCUMENT: 'Hochladen',
    SERVICE_DOCUMENTATION_UPLOAD_CANCEL: 'Abbrechen',
    SERVICE_DOCUMENTATION_UPLOAD_FAILED: 'Fehlgeschlagen',
    SERVICE_DOCUMENTATION_UPLOAD_REPLACE_CURRENT: 'Dokument ändern',
    SERVICE_DOCUMENTATION_DOCUMENTED_BOOKINGS_COUNT: `{count, plural,
      =0 {# Termine dokumentiert}
      one {# Termin dokumentiert}
      other {# Termine dokumentiert}
    }`,
    SERVICES: 'Leistungen',
    SERVICES_REQUESTED: 'Angefragte Leistungen',
    SERVICE_TOOLTIP_ACCOMPANIMENT_BY_CAR:
      'Sofern kein Personenbeförderungsschein vorliegt, ist die Begleitung nur mit dem Auto der Kundin / des Kunden gestattet.',
    SERVICE_TOOLTIP_CARRYING_OUT_ADMINISTRATIVE_TASKS:
      'Hilfe beim Schriftwechsel, Telefonkontakt zu Ärzten, Krankenkassen & Ämtern; Organisation von Terminen wie bspw. Friseur-, Fußpflege-, Arztterminen etc.',
    SERVICE_TOOLTIP_COMPANIONSHIP:
      'Gespräche führen, aus Büchern & Zeitschriften vorlesen, Brett- & Kartenspiele spielen, Begleiten eines Hobbys',
    SERVICE_TOOLTIP_LIGHT_HOUSEWORK:
      'Post reintragen, Müll rausbringen, Blumen gießen, kleine Handwerkstätigkeiten ausführen, Haustiere versorgen',
    SERIVCE_TOOLTIP_MOBILITY_HELP:
      'Beim Aufstehen helfen, den Arm stützen, beim Gehen & Spazieren helfen',
    SERVICE_TOOLTIP_LIGHT_BODY_CARE:
      'Haare waschen & kämmen, beim Eincremen unterstützen, in die Dusche oder Badewanne helfen',
    TAX_NUMBER: 'Steuernummer',
    TAX_NUMBER_HINT: 'Gewerbe: Kleinunternehmen',
    TODAY: 'Heute',
    TOTAL_HOURS_SERVED: 'Geleistete Stunden',
    TOTAL_EARNINGS: 'Verdienst bisher',
    TRAVEL_DISTANCE_TITLE: 'Maximale Entfernung zum Kunden',
    TRAVEL_DISTANCE_VALUE: '{value} km',
    UNDOCUMENTED_BOOKING: 'Nicht dokumentiert',
    SURVEY_NEEDED_BOOKING: 'Fragen nicht beantwortet',
    SIGNED_VIA_PAPER: 'Auf Papier unterschrieben',
    UPCOMING: 'Zukünftige',
    UPCOMING_CUSTOMERS: 'Zukünftige',
    UPCOMING_BOOKINGS: 'Zukünftige Termine',
    VALIDATION_AT_LEAST_ONE_SERVICE: 'Wählen Sie mind. eine Leistung',
    VALIDATION_EMAIL: 'Ungültige E-Mail-Adresse',
    VALIDATION_REQUIRED: 'Erforderlich',
    VALIDATION_FIRST_BOOKING_MIN_DECLINE_REASONS: 'Bitte wähle mind. einen Grund',
    VALIDATION_FIRST_BOOKING_REQUIRED_DESCRIPTION: 'Bitte beschreibe kurz den Grund',
    VALIDATION_PROPOSAL_MIN_DECLINE_REASONS: 'Bitte wähle mind. einen Grund',
    VALIDATION_PROPOSAL_OTHER_REASON: 'Bitte beschreibe kurz den Grund',
    VALIDATION_TAX_NO_REQUIRED:
      'Steuernummer erforderlich, wenn keine Umsatzsteuer-ID im nächsten Feld angegeben wird',
    VALIDATION_UST_ID_REQUIRED:
      'Umsatzsteuer-ID erforderlich, wenn keine Steuernummer im vorigen Feld angegeben wird',
    VAT_ID: 'Umsatzsteuer-ID',
    VAT_ID_HINT: 'Gewerbe: Großunternehmen',
    WORKED: 'Gearbeitet',
    WORKING_HOURS_LINE_1:
      'Montag bis Mittwoch von 08:00 bis 12:00 Uhr sowie von 15:00 bis 19:00 Uhr',
    WORKING_HOURS_LINE_2: 'Donnerstag und Freitag von 10:00 bis 15:00 Uhr',
    CARESHIP_COMMISSION: 'Careship-Provision {commissionRate}',
    YOUR_CUSTOMER_SINCE: 'Dein Kunde seit',
    CUSTOMER_KRANKHEIT: 'Krankheit',
    CAREGIVER_KRANKHEIT: 'Krankheit',
    CUSTOMER_URLAUB: 'Urlaub',
    CAREGIVER_URLAUB: 'Urlaub',
    CUSTOMER_GESUNDHEITSZUSTAND_VERSCHLECHTERT: 'Gesundheitszustand verschlechtert',
    CUSTOMER_TERMIN_VERGESSEN : 'Termin vergessen',
    CAREGIVER_TERMIN_VERGESSEN : 'Termin vergessen',
    CUSTOMER_TERMIN_ABGESAGT  : 'Termin abgesagt',
    CAREGIVER_TERMIN_ABGESAGT  : 'Termin abgesagt',
    CUSTOMER_UNTERSTUETZUNGSBEDUERFTIGE_PERSON_LEHNT_HILFE_AB  : 'Unterstützungsbedürftige Person lehnt Hilfe ab',
    CAREGIVER_PROBLEME_BEI_DER_ANFAHRT_ZUM_KUNDEN  : 'Probleme bei der Anfahrt zum Kunden',
    CAREGIVER_FAMILIAERER_NOTFALL  : 'Familiärer Notfall',
    CUSTOMER_FAMILIAERER_NOTFALL  : 'Familiärer Notfall',
    CAREGIVER_AENDERUNG_IN_DER_VORGESEHENEN_ARBEITSPLANUNG  : 'Änderung in der vorgesehenen Arbeitsplanung',
    CAREGIVER_ANDERE: 'Andere',
    CUSTOMER_ANDERE: 'Andere',
    CAREGIVER_CANCELLED_BOOKING: 'Ich konnte nicht',
    CUSTOMER_CANCELLED_BOOKING: 'Der Kunde konnte nicht ',

    TEXT_SIGNATURE_FOR_SIGNATURE_SCREEN: 'Unterschrift',
    TEXT_BOOKING_DATE_TIME_1: 'Ihre Buchung fand statt von ',
    TEXT_BOOKING_DATE_TIME_2: ' bis ',
    TEXT_BOOKING_DATE_TIME_3: ' am ',
    TEXT_SIGNATURE_CU_PART_1: 'Mit meiner Unterschrift bestätige ich, ',
    TEXT_SIGNATURE_CU_PART_2: ', dass die obigen Angaben korrekt sind und die Buchung zur angegebenen Zeit stattgefunden hat',
    TEXT_SIGNATURE_CG_PART_1: 'Mit meiner Unterschrift bestätige ich, ',
    TEXT_SIGNATURE_CG_PART_2: ', dass die Angaben korrekt sind und die Buchung zur angegebenen Zeit stattgefunden hat',
    TEXT_DECLERATION_SIGNATURE_CU_PART_1: 'Hiermit trete ich, ',
    TEXT_DECLERATION_SIGNATURE_CU_PART_2: ', meine Ansprüche auf die Erstattung an die Careship Ambulant GmbH ab. Der Dienstleister darf die Leistungen aus der Pflegeversicherung direkt mit der Pflegekasse abrechnen.',
    BUTTON_NEXT_SIGNATURE_SCREEN: 'Weiter',
    BUTTON_BACK_SIGNATURE_SCREEN: 'Zurück',
    BUTTON_EDIT_BOOKING_SIGNATURE_SCREEN: 'Buchung bearbeiten',
    BUTTON_NOT_SIGNING_SIGNATURE_SCREEN: 'Nein, privat bezahlen',
    BUTTON_UNDO_SIGNATURE_SCREEN: 'Löschen und wiederholen',
    TEXT_WARNING_SIGNATURE_SCREEN: 'Wenn Sie die Abtretungserklärung nicht unterschreiben, müssen Sie den Termin privat bezahlen und erhalten hierfür am Ende des Monats eine entsprechende Rechnung.\n',
    BUTTON_AGREE_PAY_PRIVATELY: 'Ja, ich bin damit einverstanden, privat zu zahlen',
    BUTTON_NOT_AGREE_PAY_PRIVATELY: 'Nein, ich möchte, dass meine Versicherung die Kosten übernimmt',
    BUTTON_INSURANCE_COVER: 'Ich möchte, dass meine Versicherung die Kosten übernimmt',

    HEADING_FULFILL_SIGNATURE_STEP_1: 'Datum & Uhrzeit bestätigen',
    HEADING_FULFILL_SIGNATURE_STEP_2: 'Termindetails bestätigen',
    HEADING_FULFILL_SIGNATURE_STEP_3_1: 'Kundenunterschrift',
    HEADING_FULFILL_SIGNATURE_STEP_3_2: 'Terminbestätigung',
    HEADING_FULFILL_SIGNATURE_STEP_4_1: 'Kundenunterschrift',
    HEADING_FULFILL_SIGNATURE_STEP_4_2: 'Bestätigung der Abtretung',
    HEADING_FULFILL_SIGNATURE_STEP_5: 'Achtung',
    HEADING_FULFILL_SIGNATURE_STEP_6_1: 'Alltagshilfeunterschrift',
    HEADING_FULFILL_SIGNATURE_STEP_6_2: 'Terminbestätigung',

    BUTTON_CLASSIC_FLOW: 'Auf Papier unterschreiben',
    BUTTON_DIGITAL_FLOW: 'Digital unterschreiben',

    FILL_SURVEY_NOW: 'Weiter',
    FILL_SURVEY_LATER: 'Fragen später ausfüllen',


    BOOKING_CONFIRMATION_TITLE : 'Super, der Termin ist dokumentiert!',
    BOOKING_CONFIRMATION_DIGITAL_LINE_1 : 'Die Dokumentation für diesen Termin ist mit Euren digitalen Unterschriften jetzt komplett abgeschlossen und wird so abgerechnet!',
    BOOKING_CONFIRMATION_DIGITAL_LINE_2 : 'Wir und die Umwelt danken Dir!',
    BOOKING_CONFIRMATION_DIGITAL_LINE_3 : 'Jetzt nur noch ein paar Fragen zum Termin beantworten...',


    BOOKING_CONFIRMATION_PAPER_LINE_1 : 'Achtung: Da Du ausgewählt hast, auf Papier zu unterschreiben, vergiss die Unterschriften auf dem Papier-Leistungsnachweis nicht und schicke uns diesen unbedingt Ende des Monats per Post zu!',
    BOOKING_CONFIRMATION_PAPER_LINE_2 : 'Die Unterschriften darauf, sind die gültige Dokumentation zur Abrechnung der Stunden, sodass Du Lohn bekommen kannst.',
    BOOKING_CONFIRMATION_PAPER_LINE_3 : 'Jetzt nur noch ein paar Fragen zum Termin beantworten...',

    COMPLETE_SURVEY: 'Fragen jetzt beantworten',
    MISSING_SURVEY_AND_BOOKING_ON_PAPER: 'Fragen nicht beantwortet'
  },
};

export default messages;
export type MessageKey = keyof (typeof messages)['de'];

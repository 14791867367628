import React, { ReactElement, useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import {StyleSheet, View, Platform, Button} from 'react-native';
import { useQueryLoader } from 'react-relay/hooks';
import { startOfDay, subWeeks } from 'date-fns';
import Dashboard, { dashboardQuery } from '../Component/Dashboard';
import { CSTheme } from '../../Component';
import { DashboardQuery } from '../Component/__generated__/DashboardQuery.graphql';
import { withLayout } from '../../HOC';
import Sentry from "../../../Infrastructure/ErrorTracking/Sentry";

const styles = StyleSheet.create({
  topBackground: {
    position: 'absolute',
    backgroundColor: CSTheme.colors.primary,
    height: '50%',
    width: '100%',
  },
});

export default withLayout(function DashboardScreen(): ReactElement {
  const [dashboardQueryReference, loadDashboardQuery] =
    useQueryLoader<DashboardQuery>(dashboardQuery);

  const [focus, setFocus] = useState(false);

  useFocusEffect(() => {
    setFocus(true);
    return () => setFocus(false);
  });

  useEffect(() => {
    if (focus) {
      loadDashboardQuery(
        {
          startDate: startOfDay(subWeeks(new Date(), 4)).toISOString(),
          splitDate: startOfDay(subWeeks(new Date(), 0)).toISOString(),
        },
        { fetchPolicy: 'store-and-network' },
      );
    }
  }, [loadDashboardQuery, focus]);


  return (
    <>
      {Platform.OS !== 'web' && <View style={styles.topBackground} />}
      {dashboardQueryReference && (
        <Dashboard monthlyEarningsQueryReference={dashboardQueryReference} />
      )}
    </>
  );
});

/**
 * @generated SignedSource<<e8c36a9e3141c69ae9151e7a16aed6eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FirstBookingStatus = "confirmed" | "created" | "declined" | "%future added value";
export type Status = "created" | "fulfilled" | "invoiced" | "%future added value";
export type fetchAllBookingsQuery$variables = {};
export type fetchAllBookingsQuery$data = {
  readonly allBookings: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly status: Status;
        readonly surveyStatus: string;
        readonly contract: {
          readonly id: string;
          readonly hasFulfilledBooking: boolean;
          readonly cancelationScheduledFor: string | null;
          readonly firstBookingStatus: FirstBookingStatus;
          readonly firstBookingConfirmationStatus: {
            readonly reason: string;
          } | null;
        };
      };
    } | null> | null;
  } | null;
};
export type fetchAllBookingsQuery = {
  variables: fetchAllBookingsQuery$variables;
  response: fetchAllBookingsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BookingConnection",
    "kind": "LinkedField",
    "name": "allBookings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BookingEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Booking",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "surveyStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "contract",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasFulfilledBooking",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cancelationScheduledFor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstBookingStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "firstBookingConfirmationStatus",
                    "kind": "LinkedField",
                    "name": "firstBookingConfirmationStatus",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reason",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchAllBookingsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "fetchAllBookingsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8289f12a4a235a59ebac9ef0eb3f826d",
    "id": null,
    "metadata": {},
    "name": "fetchAllBookingsQuery",
    "operationKind": "query",
    "text": "query fetchAllBookingsQuery {\n  allBookings {\n    edges {\n      node {\n        id\n        startDate\n        endDate\n        status\n        surveyStatus\n        contract {\n          id\n          hasFulfilledBooking\n          cancelationScheduledFor\n          firstBookingStatus\n          firstBookingConfirmationStatus {\n            reason\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "14d673b46c91c745c6a63ee5195104a1";

export default node;

import { FormattedMessage, useIntl } from 'react-intl';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Animated, Platform, StyleProp, TextStyle, View } from 'react-native';
import { setMonth } from 'date-fns';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
import styled from '@emotion/native';
import { IconButton } from 'react-native-paper';
import { EyeIcon, UploadShareIcon } from '../../Component/Icon';
import { ListItemAction, ProgressBar, Text } from '../../Component';
import { CareshipColors } from '../../Component/CSTheme';
import { ServiceDocumentationRow_serviceDocumentationQuery$key } from './__generated__/ServiceDocumentationRow_serviceDocumentationQuery.graphql';
import { usePickAndUploadFile } from '../Hook';
import ServiceDocumentationInstructionDialog from './ServiceDocumentationInstructionDialog';

const FulfilledBookingsCountText = styled(Text)({
  marginTop: 4,
});

interface ServiceDocumentationRowProps {
  sectionData: ReadonlyArray<ServiceDocumentationRow_serviceDocumentationQuery$key>;
  index: number;
  serviceDocumentation: ServiceDocumentationRow_serviceDocumentationQuery$key;
  onPressServiceDocumentationPeriod: (serviceDocumentationPeriod: string, title: string) => void;
  fulfilledBookingsCount: number;
}

function ServiceDocumentationRow({
  index,
  serviceDocumentation,
  onPressServiceDocumentationPeriod,
  sectionData,
  fulfilledBookingsCount,
}: ServiceDocumentationRowProps) {
  const [{ id, lastUploadedFile, serviceDocumentationId, month: monthStr }, refetch] =
    useRefetchableFragment(
      graphql`
        fragment ServiceDocumentationRow_serviceDocumentationQuery on ServiceDocumentation
        @refetchable(queryName: "serviceDocumentationRowRefetchQuery") {
          id
          month
          serviceDocumentationId
          lastUploadedFile {
            filename
            mimeType
            pathInStorage
          }
        }
      `,
      serviceDocumentation,
    );

  const intl = useIntl();
  const progressPercentage = useRef(new Animated.Value(0)).current;
  const month = intl.formatDate(setMonth(new Date(), parseInt(monthStr, 10) - 1), {
    month: 'long',
  });
  const [displayServiceDocumentationDialog, setDisplayServiceDocumentationDialog] = useState(false);

  const { status, showPickerAndUploadFile, progress } = usePickAndUploadFile(
    `/caregiver-app/service-documentation/${serviceDocumentationId}/files`,
    () => {
      progressPercentage.setValue(0);
      return refetch({}, { fetchPolicy: 'store-and-network' });
    },
  );

  useEffect(
    () =>
      Animated.spring(progressPercentage, { toValue: progress, useNativeDriver: false }).start(),
    [progressPercentage, progress],
  );

  let actionIcon = lastUploadedFile ? null : <UploadShareIcon testID="upload-icon" />;

  if (status === 'progress') {
    actionIcon = <ProgressBar progress={progressPercentage} />;
  }

  let labelStyle: StyleProp<TextStyle> = null;
  let actionLabel = lastUploadedFile ? (
    <FormattedMessage id="SERVICE_DOCUMENTATION_UPLOAD_SHOW" />
  ) : null;

  if (status === 'failed') {
    actionLabel = <FormattedMessage id="SERVICE_DOCUMENTATION_UPLOAD_FAILED" />;
    labelStyle = {
      color: CareshipColors.red,
      marginRight: 8,
    };
  }

  const handlePressServiceDocumentation = useCallback(() => {
    if (Platform.OS === 'web') {
      onPressServiceDocumentationPeriod(serviceDocumentationId, month);
      return;
    }

    onPressServiceDocumentationPeriod(id, month);
  }, [id, month, onPressServiceDocumentationPeriod, serviceDocumentationId]);

  const handleAcceptServiceDocumentationDialog = useCallback(() => {
    setDisplayServiceDocumentationDialog(false);

    setTimeout(() => {
      void showPickerAndUploadFile();
    }, 300);
  }, [showPickerAndUploadFile]);

  const handleUploadServiceDocumentation = useCallback(() => {
    setDisplayServiceDocumentationDialog(true);
  }, []);

  const handleDismissServiceDocumentationDialog = useCallback(() => {
    setDisplayServiceDocumentationDialog(false);
  }, []);

  return (
    <>
      {(!lastUploadedFile || Platform.OS === 'web') && (
        <ServiceDocumentationInstructionDialog
          visible={displayServiceDocumentationDialog}
          onAccept={handleAcceptServiceDocumentationDialog}
          onDismiss={handleDismissServiceDocumentationDialog}
        />
      )}
      <ListItemAction
        style={sectionData.length - 1 === index ? { marginBottom: 32 } : undefined}
        onPress={Platform.select({
          native: () =>
            lastUploadedFile
              ? handlePressServiceDocumentation()
              : handleUploadServiceDocumentation(),
        })}
        labelStyle={labelStyle}
        actionLabel={Platform.select({
          native: actionLabel,
        })}
        actionIcon={Platform.select({
          native: actionIcon,
        })}
        actionIconLeft={Platform.select({
          web:
            status === 'progress' ? (
              <View style={{ marginRight: 8 }}>
                <ProgressBar progress={progressPercentage} />
              </View>
            ) : (
              <>
                {status === 'failed' && (
                  <Text style={{ color: CareshipColors.red }}>
                    <FormattedMessage id="SERVICE_DOCUMENTATION_UPLOAD_FAILED" />
                  </Text>
                )}
                {lastUploadedFile && (
                  <IconButton onPress={handlePressServiceDocumentation} icon={EyeIcon} />
                )}
                <IconButton onPress={handleUploadServiceDocumentation} icon={UploadShareIcon} />
              </>
            ),
        })}
      >
        {month}
        <FulfilledBookingsCountText muted>
          <FormattedMessage
            id="SERVICE_DOCUMENTATION_DOCUMENTED_BOOKINGS_COUNT"
            values={{ count: fulfilledBookingsCount }}
          />
        </FulfilledBookingsCountText>
      </ListItemAction>
    </>
  );
}

export default memo(ServiceDocumentationRow);

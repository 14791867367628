import React, { useCallback } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { isToday } from 'date-fns';
import { Text } from 'react-native-paper';
import { FirstBooking, NonFirstBooking } from '../../../../Context/Booking/Store/Model/Booking';
import Contract from '../../../../Context/Contract/Store/Model/Contract';
import styles from './BookingListItem.styles';
import { SectionListItem } from '../../../Component';
import ErrorBoundary from '../../../Component/ErrorBoundary';
import BookingItemErrorFallback from './BookingItemErrorFallback';
import ContractCancelationIcon from '../../../Component/Icon/ContractCancelationIcon';
import getLabel from '../getLabel';
import useProfile from "../../../../Context/Profile/Hook/useProfile";

interface BookingListItemProps {
  booking: NonFirstBooking | FirstBooking;
  contract: Contract | null;
  onPress: (bookingId: string) => void;
  style?: StyleProp<ViewStyle>;
  showMonth?: boolean;
}

function BookingItem({ style, booking, onPress, showMonth, contract }: BookingListItemProps) {
  const startsToday = isToday(booking.startDate);
  const {profile} = useProfile();
  const { label, color: labelColor, mode: mode } = getLabel(booking, profile?.isCareshipEmployee, contract?.firstBookingStatus);
  const intl = useIntl();
  const handleBookingPress = useCallback(() => {
    onPress(booking.bookingId);
  }, [booking.bookingId, onPress]);

  return (
    <SectionListItem
      style={style}
      title={
        <View style={{ flexDirection: 'row' }}>
          <Text style={styles.day}>
            {startsToday ? (
              <FormattedMessage
                id="TODAY"
                defaultMessage="Today"
                description="Indicates that the date is today"
              />
            ) : (
              <FormattedDate value={booking.startDate} weekday="short" />
            )}{' '}
          </Text>
          <Text style={styles.time}>
            <FormattedTime value={booking.startDate} hour12={false} />
            {' - '}
            <FormattedTime value={booking.endDate} hour12={false} />
          </Text>
        </View>
      }
      labelColor={labelColor}
      columnTitleColor={labelColor}
      columnTitle={
        // NOTE: not guaranteed to be first in time, as this would be preferable add the
        // required data for this check when starting to get this data from graphql.
        booking.isFirstBooking && !profile?.isCareshipEmployee ? intl.formatMessage({ id: 'FIRST_APPOINTMENT' }) : undefined
      }
      label={label}
      labelMode={mode}
      highlighted={startsToday}
      onPress={handleBookingPress}
      testID={booking.bookingId}
    >
      <>
        <Text style={styles.date}>
          <FormattedDate value={booking.startDate} day="2-digit" />
          {showMonth && ' '}
          {showMonth && <FormattedDate value={booking.startDate} month="short" />}
        </Text>
        <View style={styles.nameContainer}>
          <Text ellipsizeMode="tail" numberOfLines={1} style={styles.name}>
            {/* NOTE: contract is nullable to allow investigating why we see errors with missing
              contracts (CC-480) */}
            <FormattedMessage
              id="NAME_FULL"
              values={{
                firstName: contract!.careReceiver.name.firstName,
                lastName: contract!.careReceiver.name.lastName,
              }}
            />
          </Text>
          {contract!.cancelationScheduledFor && <ContractCancelationIcon size={16} />}
        </View>
      </>
    </SectionListItem>
  );
}

export default function BookingListItem(props: BookingListItemProps) {
  return (
    <ErrorBoundary errorComponent={<BookingItemErrorFallback />}>
      <BookingItem {...props} />
    </ErrorBoundary>
  );
}

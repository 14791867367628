import { StyleProp, TextStyle } from 'react-native';
import Constants from 'expo-constants';
import React from 'react';
import Text from './Text';
interface RevisionProps {
  style?: StyleProp<TextStyle>;
}

export default function Revision({ style }: RevisionProps) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  const commitHash: string =  Constants.expoConfig?.extra?.commitHash || 'n/a';
  const appVersion: string =  Constants.expoConfig?.version || 'n/a';
  const { nativeBuildVersion } = Constants;

  return (
    <>
      <Text style={style} size="small" center muted>
        App version: {appVersion}
        {"\n"}
        Code version: {nativeBuildVersion}-{commitHash}-{'production'}
      </Text>
  </>

  );
}

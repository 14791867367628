//import * as Amplitude from '@amplitude/analytics-react-native';
import Manifest from '../Constants/Manifest';
import logger from '../Service/Logger';

export { default as events } from './Events';

const enabled = Manifest.extra.tracking as boolean;

function isStatisticsConsentGiven(): boolean {
  const consentCookie =
    document.cookie.split('; ').find((row) => row.startsWith('CookieConsent=')) ?? '';

  return /statistics:true/.test(consentCookie);
}

window.addEventListener(
  'CookiebotOnAccept',
  () => {
    if (!isStatisticsConsentGiven()) {
      logger.debug('tracking: Amplitude not initialized, missing consent');
      return;
    }

    if (!enabled) {
      return;
    }

    //void Amplitude.init(Manifest.extra.amplitudeApiKey as string);
  },
  false,
);

export function trackEvent(event: string, properties?: { [key: string]: any }): void {
  logger.debug(`trackEvent: ${event}`);

  if (!enabled) {
    return;
  }

  if (properties) {
    //void Amplitude.logEvent(event, properties);
  } else {
    //void Amplitude.logEvent(event);
  }
}

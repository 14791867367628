import  AsyncStorage  from '@react-native-async-storage/async-storage';

class PlatformLocalStorage {
  static getItem(key: string, values: object) {
    try{
      const value = AsyncStorage.getItem(key).then(results=>{values.hideModalUntil = results;});
    }
    catch(e)
    {
      values.hideModalUntil = '';
    }

  }

  static setItem(key: string, value: any) {
    AsyncStorage.setItem(key, JSON.stringify(value));
  }

}

export default PlatformLocalStorage;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Feather } from '@expo/vector-icons';
import { graphql, useFragment } from 'react-relay/hooks';
import { CSTheme, Text, Title } from '..';
import { CareshipColors } from '../CSTheme';
import CardWithTooltip from './CardWithTooltip';
import Gauge from '../Gauge';
import { ProposalResponse_stats$key } from './__generated__/ProposalResponse_stats.graphql';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginVertical: 16,
  },
  col: {
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: 16,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: '50%',
  },
  leftCol: {
    borderRightColor: CareshipColors.white,
    borderRightWidth: 2,
  },
  responseTimeText: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  responseTimeTextComparison: {
    alignSelf: 'center',
  },
  emptyText: {
    padding: 16,
  },
  gauge: {
    marginBottom: 16,
  },
  gaugeLabel: {
    paddingHorizontal: 8,
  },
  icon: {
    alignSelf: 'center',
    marginTop: 6,
  },
});

export function getFeatherArrow(
  previousValue: number,
  nextValue: number,
  tolerance: number,
): 'arrow-right' | 'arrow-up' | 'arrow-down' {
  if (Math.abs(previousValue - nextValue) < tolerance) {
    return 'arrow-right';
  }

  return previousValue < nextValue ? 'arrow-up' : 'arrow-down';
}

function clamp(min: number, max: number, value: number): number {
  return Math.min(Math.max(value, min), max);
}

export function bucketResponseTime(timeInMinutes: number): number {
  if (timeInMinutes <= 24 * 60) {
    return [24, 12, 3].reduce(
      (carry, cutoff) => (timeInMinutes <= cutoff * 60 ? cutoff : carry),
      0,
    );
  }

  return [24, 36, 48, 60].reduce(
    (carry, cutoff) => (timeInMinutes > cutoff * 60 ? cutoff : carry),
    0,
  );
}

export function responseRateToColor(rate: number): string {
  if (rate >= 0.66) {
    return CareshipColors.secondary;
  }

  if (rate < 0.33) {
    return CareshipColors.red;
  }

  return CareshipColors.primary;
}

export function responseTimeToColor(minutes: number): string {
  if (minutes <= 24 * 60) {
    return CareshipColors.secondary;
  }

  if (minutes > 48 * 60) {
    return CareshipColors.red;
  }

  return CareshipColors.primary;
}

export default function ProposalResponse({ stats }: { stats: ProposalResponse_stats$key }) {
  const {
    averageMinutesToFirstCall,
    previousAverageMinutesToFirstCall,
    proposalResponseRate,
    previousProposalResponseRate,
  } = useFragment(
    graphql`
      fragment ProposalResponse_stats on CaregiverStats
      @argumentDefinitions(splitDate: { type: "DateTime" }, startDate: { type: "DateTime" }) {
        averageMinutesToFirstCall(startDate: $splitDate)
        previousAverageMinutesToFirstCall: averageMinutesToFirstCall(
          startDate: $startDate
          endDate: $splitDate
        )
        proposalResponseRate(startDate: $splitDate)
        previousProposalResponseRate: proposalResponseRate(
          startDate: $startDate
          endDate: $splitDate
        )
      }
    `,
    stats,
  );

  return (
    <CardWithTooltip
      tooltipContent={
        <>
          <Title style={{ color: '#fff', marginBottom: 4 }} size="h6">
            <FormattedMessage id="PROPOSAL_RESPONSE_TOOLTIP_TITLE" />
          </Title>
          <Text style={{ color: '#fff' }}>
            <FormattedMessage id="PROPOSAL_RESPONSE_TOOLTIP_BODY" />
          </Text>
        </>
      }
      title={
        <Text center style={{ width: '100%' }}>
          <Text center bold uppercase>
            <FormattedMessage id="PROPOSAL_RESPONSE_TITLE_1" />{' '}
          </Text>
          <Text center>
            <FormattedMessage id="PROPOSAL_RESPONSE_TITLE_2" />
          </Text>
        </Text>
      }
    >
      {proposalResponseRate === null || averageMinutesToFirstCall === null ? (
        <Text size="large" center style={styles.emptyText}>
          <FormattedMessage id="PROPOSAL_RESPONSE_EMPTY" />
        </Text>
      ) : (
        <View style={styles.row}>
          <View style={[styles.col, styles.leftCol]}>
            <Gauge
              style={styles.gauge}
              radius={40}
              value={proposalResponseRate}
              color={responseRateToColor(proposalResponseRate)}
            >
              <Text style={styles.gaugeLabel}>
                <Text size="xlarge" bold>
                  <FormattedNumber value={proposalResponseRate * 100} maximumFractionDigits={0}
                                   minimumFractionDigits={0} />
                </Text>
                <Text size="large">%</Text>
              </Text>
            </Gauge>
            <Text size="small" center uppercase>
              <FormattedMessage id="PROPOSAL_RESPONSE_RESPONSE_RATE" />
            </Text>
            {previousProposalResponseRate && (
              <Feather
                testID="responseRateArrow"
                style={styles.icon}
                color={CSTheme.colors.text}
                size={12}
                name={getFeatherArrow(previousProposalResponseRate, proposalResponseRate, 0.01)}
              />
            )}
          </View>
          <View style={styles.col}>
            <Gauge
              style={styles.gauge}
              radius={40}
              value={clamp(0.05, 0.95, 1 - averageMinutesToFirstCall / (60 * 72))}
              color={responseTimeToColor(averageMinutesToFirstCall)}
            >
              <View style={styles.responseTimeText}>
                <Text size="large" style={styles.responseTimeTextComparison}>
                  {`${averageMinutesToFirstCall <= 24 * 60 ? '<' : '>'}\u2009`}
                </Text>
                <Text size="xlarge" bold>
                  {`${bucketResponseTime(averageMinutesToFirstCall)}\u2008`}
                </Text>
                <Text size="medium">
                  <FormattedMessage id="PROPOSAL_RESPONSE_UNIT_HOURS" />
                </Text>
              </View>
            </Gauge>
            <Text style={styles.gaugeLabel} size="small" center uppercase>
              <FormattedMessage id="PROPOSAL_RESPONSE_FIRST_CALL_TIMING" />
            </Text>
            {previousAverageMinutesToFirstCall && (
              <Feather
                testID="timeToFirstCallArrow"
                style={styles.icon}
                color={CSTheme.colors.text}
                size={12}
                name={getFeatherArrow(
                  previousAverageMinutesToFirstCall,
                  averageMinutesToFirstCall,
                  10,
                )}
              />
            )}
          </View>
        </View>
      )}
    </CardWithTooltip>
  );
}

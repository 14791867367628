/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import AuthorizedClient, { isError } from '../../../Infrastructure/Service/AuthorizedClient';
import { TokenResponseData } from '../../../Infrastructure/Model/TokenResponseData';
import DefinitionStore, {
  BookingCancellationReasons,
  ConfirmationStatusUpdateReasonListResponse,
  ServiceListResponse,
} from '../Store/DefinitionStore';
import Manifest from '../../../Infrastructure/Constants/Manifest';
import { logger } from '../../../Infrastructure/Service';
import OAuthTokenStore from '../../../Infrastructure/Auth/OAuthTokenStore';
import AccessDeniedError from '../../../Infrastructure/Service/Error/AccessDeniedError';
import AuthenticationError from '../../../Infrastructure/Service/Error/AuthenticationError';
import { OauthServiceInterface } from '../../../Infrastructure/Contract/OauthServiceInterface';
import CancellationReasons from "../../Booking/Store/Model/CancellationReasons";

const { apiEndpoint } = Manifest.extra as { apiEndpoint: string };

const apiBackendUrl = Constants?.expoConfig?.extra?.apiBackendUrl;

const apiURI: string = Platform.OS === 'web' ? apiEndpoint : apiBackendUrl ;

export default class DefinitionService implements OauthServiceInterface {
  constructor(
    private definitionStore: DefinitionStore,
    private tokenStore: OAuthTokenStore,
    private oauthClient: (endpoint: string, params: string[]) => Promise<Response>,
  ) {
    this.apiClient = new AuthorizedClient(this, logger);
  }

  private apiClient: AuthorizedClient;

  async asyncGetToken() {
    let accessToken = await this.tokenStore.getAccessToken();

    if (accessToken && !(await this.tokenStore.isTokenExpired())) {
      return accessToken;
    }

    try {
      await this.authorize();
    } catch (e) {
      await this.tokenStore.clearTokenData();
      throw new AccessDeniedError(String(e));
    }

    accessToken = await this.tokenStore.getAccessToken();

    return accessToken;
  }

  refreshToken(): Promise<void> {
    return this.authorize();
  }

  async authorize(): Promise<void> {
    if (!(await this.tokenStore.isTokenExpired())) {
      return;
    }

    const result = await this.oauthClient(`${apiURI}/auth/token`, [
      'grant_type=client_credentials',
      'scope=definition_read',
    ]);

    if (result.status !== 200) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      throw new AuthenticationError((await result.json()).error as string);
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const jsonResponse: TokenResponseData = await result.json();

    await this.tokenStore.handleTokenData(jsonResponse);
    logger.debug('access token persisted');
  }

  async fetchFirstBookingDeclineReasonList(): Promise<void> {
    const endpoint = '/definition/first-booking/decline-reasons';
    const result = await this.apiClient.request<string[]>(endpoint, {method: 'GET'});

    if (isError(result)) {
      throw new Error(
        `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(
          result.errorCode,
        )}`,
      );
    }

    this.definitionStore.handleFirstBookingDeclineReasonListResponse(result.data);
  }

  async fetchBookingCancellationReasonList(): Promise<void> {
    const endpoint = '/definition/booking/cancellation-reasons';
    const result = await this.apiClient.request<any>(endpoint, {method: 'GET'});
    if (isError(result)) {
      throw new Error(
        `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(
          result.errorCode,
        )}`,
      );
    }

    this.definitionStore.handleBookingCancellationReasonListResponse(result.data.caregiver_cancelled_booking, result.data.customer_cancelled_booking );
  }

  async fetchProposalDeclineReasonList(): Promise<void> {
    const endpoint = '/definition/service-request/proposal-decline-reasons';
    const result = await this.apiClient.request<string[]>(endpoint, {method: 'GET'});

    if (isError(result)) {
      throw new Error(
        `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(
          result.errorCode,
        )}`,
      );
    }

    this.definitionStore.handleProposalDeclineReasonListResponse(result.data);
  }

  async fetchServiceList(): Promise<void> {
    const endpoint = '/definition/service-request/booking-services';
    const result = await this.apiClient.request<ServiceListResponse>(endpoint, {method: 'GET'});

    if (isError(result)) {
      throw new Error(
        `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(
          result.errorCode,
        )}`,
      );
    }

    this.definitionStore.handleServiceListResponse(result.data);
  }

  async fetchConfirmationStatusUpdateReasons(): Promise<void> {
    const endpoint = '/definition/first-booking/confirmation-status-update-reasons';
    const result = await this.apiClient.request<ConfirmationStatusUpdateReasonListResponse>(
      endpoint,
    );

    if (isError(result)) {
      throw new Error(
        `Failed fetching ${endpoint}. Response: ${result.status} ${JSON.stringify(
          result.errorCode,
        )}`,
      );
    }

    this.definitionStore.handleConfirmationStatusUpdateReasons(result.data);
  }
}

import { formatISO } from 'date-fns';
import { useCallback, useState } from 'react';
import { either } from 'fp-ts';
import useBookingService from './useBookingService';
import { ErrorResponse, isError } from '../../../Infrastructure/Service';
import useLogger from '../../../Infrastructure/Hook/useLogger';

interface FulfillBookingData {
  bookingId: string;
  startDate: Date;
  endDate: Date;
  mainServiceIds: Set<string>;
  otherServiceDescription?: string;
  paymentMethodFinal: string;
}

export default function useFulfillBooking() {
  const bookingService = useBookingService();
  const logger = useLogger();
  const [error, setError] = useState<ErrorResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submitBooking = useCallback(
    async ({
      bookingId,
      mainServiceIds,
      otherServiceDescription,
      startDate,
      endDate,
      paymentMethodFinal
    }: FulfillBookingData): Promise<either.Either<ErrorResponse, void>> => {
      setIsLoading(true);

      const preparedData = {
        bookingId,
        startDate: formatISO(startDate),
        endDate: formatISO(endDate),
        mainServiceIds: Array.from(mainServiceIds),
        otherServiceDescription,
        paymentMethod: paymentMethodFinal
      };

      const result = await bookingService.fulfillBooking(preparedData);

      setIsLoading(false);

      // Cannot deserialize request, booking is fulfilled or invoiced, or overlaps with another booking
      if (isError(result)) {
        setError(result);

        logger.info(`Failed to fulfill booking ${bookingId}`, {
          data: preparedData,
          ...result,
        });

        return either.left(result);
      }

      logger.info(
        `Fulfilled booking (${bookingId}) ${startDate.toISOString()} - ${endDate.toISOString()}`,
        preparedData,
      );

      return either.right(undefined);
    },
    [bookingService, logger],
  );

  return {
    submitBooking,
    error,
    isLoading,
  };
}

import * as Sentry from '@sentry/react-native';
import * as Browser from '@sentry/react';
import * as Native from '@sentry/react';
import Constants from 'expo-constants';
import * as Device from "expo-device";

Sentry.init({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  dsn:  Constants.expoConfig?.extra?.sentryDSN,
  debug: true,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  release:  Constants.expoConfig?.extra?.commitHash,
  enabled:true,

});

Sentry.setExtras({
  deviceYearClass: Device.deviceYearClass,
  linkingUri: Constants.linkingUri,
});

Sentry.setTag("deviceId", Constants.sessionId);
Sentry.setTag("appOwnership", Constants.appOwnership || "N/A");
if (Constants.appOwnership === "expo" && Constants.expoVersion) {
  Sentry.setTag("expoAppVersion", Constants.expoVersion);
}
Sentry.setTag("expoChannel", 'development');

// Sentry.Browser is empty in native and Native is empty in browser environments
export default { ...Browser, ...Native };

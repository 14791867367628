import { useRef } from 'react';
import { useStores } from '../../../Infrastructure/Store';
import DefinitionService from '../Service/DefinitionService';
import { oauthClientWrapper } from '../../../Infrastructure/Service/OauthService';
import { definitionsTokenStore } from '../../../Infrastructure/Auth/OAuthTokenStore';

export default function useDefinitionService() {
  const stores = useStores();

  const { current: definitionService } = useRef(
    new DefinitionService(stores.definitionStore, definitionsTokenStore, oauthClientWrapper),
  );
  return definitionService;
}

// Hydrate the stores from storage
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect } from 'react';
import Constants from 'expo-constants';
import stores, { StoreName } from '../Store/stores';
import useLogger from './useLogger';
import asyncResetStores from '../Store/asyncResetStores';
import sanitize from '../Logger/sanitize';
import { userTokenStore } from '../Auth/OAuthTokenStore';

export default function useStoreHydration() {
  const logger = useLogger();

  useEffect(() => {
    void (async () => {
      const revisionId = await AsyncStorage.getItem('revisionId');
      const sameRevision =  true;

      // Set before marking stores as initialized to ensure navigator isn't mounted too early
      stores.sessionStore.setIsLoggedIn(await userTokenStore.isLoggedIn());

      await Promise.all(
        Object.keys(stores).map(async (k) => {
          const data = await AsyncStorage.getItem(k);

          try {
            // if we're not on the same revision, skip store hydration.
            // in dev revision is undefined and hence a an error will be
            // logged if the data in the store is incompatible
            if (sameRevision && data !== null && data !== 'null') {
              logger.debug(`hydrating store ${k}`);
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              stores[k as StoreName].hydrate(JSON.parse(data));
            }

            stores[k as StoreName].setInitialized();
          } catch (e) {
            logger.addBreadcrumb(
              {
                data: {
                  revisionId,
                  sameRevision,
                  store: sanitize(data),
                },
              },
              { __dangerouslySkipSanitize: true },
            );

            logger.error(`failed to re-hydrate ${k}`, { error: String(e), data });

            if ((k as StoreName) === 'sessionStore') {
              await asyncResetStores();
              logger.warn('stores reset');
            }
          }
        }),
      );
    })();
  }, [logger]);
}

import { graphql } from 'react-relay';

export default graphql`
  query fetchAllBookingsQuery {
    allBookings {
      edges {
        node {
          id
          startDate
          endDate
          status
          surveyStatus
          contract {
            id
            hasFulfilledBooking
            cancelationScheduledFor
            firstBookingStatus
            firstBookingConfirmationStatus {
              reason
            }
          }
        }
      }
    }
  }
`;

import {useCallback, useState} from 'react';
import useConfirmAndSelectFile from './useConfirmAndSelectFile';
import {useBookingService} from "../../../Context/Booking/Hook";
import {isError, isSuccess} from "../../../Infrastructure/Service";

export default function usePickAndUploadFile(bookingId: string, onUploadCompleted: () => void, onFileSelected: ()=> void) {
  const confirmAndSelectFile = useConfirmAndSelectFile();
  const bookingService = useBookingService();

  const [status, setStatus] = useState('');
  const [fileUri, setFileUri] = useState('');
  const showPickerAndUploadFile = useCallback(async () => {
    setStatus('')
    const file = await confirmAndSelectFile();

    if (file) {
      let fileResults = typeof(file.file) != 'undefined'? file.file.results: file.results;
      setFileUri(fileResults.uri);
      onFileSelected();

      const base64TagExpression = new RegExp("^data.+base64,")
      const base64Match = fileResults.base64.match(base64TagExpression);
      const hasImageDataTag = base64Match && (base64Match?.index ?? -1) > -1

      let imageProof = fileResults.base64;
      if (!hasImageDataTag) {
        imageProof= `data:image/png;base64,${fileResults.base64}`
      }

      const results = await bookingService.uploadImage({
        imageProof: imageProof,
        bookingId: bookingId
      });

      if (isError(results)) {
        setStatus('failed')
        onUploadCompleted();
      } else if (isSuccess(results)) {
        setStatus('success')
        onUploadCompleted();
      }

    }
  }, [confirmAndSelectFile, onUploadCompleted, onFileSelected]);

  return { status, fileUri, showPickerAndUploadFile};
}

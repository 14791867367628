import React, { ReactElement } from 'react';
import { isPast } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { BookingStatus, FirstBookingStatus } from '../../../Context/Booking/Store/Model/Booking';
import { CareshipColors } from '../../Component/CSTheme';
export default function getLabel(
  booking:
    | {
    id: string,
    endDate: Date;
    status: BookingStatus | '%future added value';
    isFirstBooking: boolean;
    surveyStatus: string;
    paperBookingProof: string;
    cgConfirmationProof: string;
  }
    | null
    | undefined,
  careshipEmployee: boolean,
  firstBookingStatus: FirstBookingStatus = null,

): {
  label: ReactElement | null;
  color?: string;
  mode: 'dot' | 'text' | 'specialDot';
} {
  if (!booking) {
    return {label: null};
  }
  const isPastBooking = isPast(booking.endDate);
  const isConfirmedFirstOrNotFirst = ([null, 'confirmed'] as FirstBookingStatus[]).includes(
    firstBookingStatus,
  );

  // Invoiced booking
  if (booking.status === 'invoiced') {
    return careshipEmployee ? {label: null}:{ mode: 'dot', label: <FormattedMessage id="INVOICED_BOOKING" />, color: CareshipColors.secondary };
  }

  // Booking that needs family feature response
  if (booking.status == 'fulfilled' && booking.surveyStatus == 'false' && !(booking.cgConfirmationProof == '')) {
    return !careshipEmployee ? {label: null}:
      {mode: 'dot',  label: <FormattedMessage id="SURVEY_NEEDED_BOOKING" />, color: CareshipColors.red };
  }

  if (booking.status == 'fulfilled' && booking.surveyStatus == 'false' && booking.cgConfirmationProof == '') {
    return !careshipEmployee ? {label: null}:
      {mode: 'specialDot',  label: <FormattedMessage id="MISSING_SURVEY_AND_BOOKING_ON_PAPER" />, color: CareshipColors.red };
  }

  if (((booking.paperBookingProof == '' && booking.cgConfirmationProof == '') ||  (booking.paperBookingProof != '' && booking.cgConfirmationProof == '')) && booking.status == 'fulfilled') {
    return !careshipEmployee ? {label: null}:
      { label: <FormattedMessage id="SIGNED_VIA_PAPER" />, color: CareshipColors.blue, mode: 'dot' };
  }

  // Undocumented booking
  if (booking.status === 'created' && isPastBooking) {
    return { mode: 'dot', label: <FormattedMessage id="UNDOCUMENTED_BOOKING" /> };
  }

  if (booking.isFirstBooking) {
    // Unconfirmed first appointment
    if (firstBookingStatus === 'created') {
      return careshipEmployee ? {label: null}:
        {
        mode: 'dot',
        label: <FormattedMessage id="FIRST_APPOINTMENT_UNCONFIRMED" />,
      };
    }

    // Confirmed first appointment
    if (firstBookingStatus === 'confirmed') {

      return careshipEmployee ? {label: null}:
        {
        mode: 'dot',
        label: <FormattedMessage id="FIRST_APPOINTMENT_CONFIRMED" />,
        color: CareshipColors.secondary,
      };
    }

    // Declined first appointment
    if (firstBookingStatus === 'declined') {
      return careshipEmployee ? {label: null}:
        {
        mode: 'dot',
        label: <FormattedMessage id="FIRST_APPOINTMENT_DECLINED" />,
        color: CareshipColors.red,
      };
    }
  }

  // Documented booking
  if (booking.status !== 'created') {
    return { label: null };
  }
  // Default case
  return { label: null };
}

import useProfile from "../../../Context/Profile/Hook/useProfile";
import {View} from "react-native";

import React from 'react';
import { Platform } from 'react-native';
import SplashScreen from '../../Session/Screen/SplashScreen';
import TabBar from './TabNavigation';
import { useStoreHydration } from '../../../Infrastructure/Store';
import useSession from '../../../Infrastructure/Hook/useSession';
import LoginScreen from '../../Session/Screen/LoginScreen';
import AddBookingScreen from '../../Booking/Screen/AddBookingScreen';
import { intl } from '../../../Infrastructure/Intl';
import { NavigatorSafeArea } from '../../Component';
import { Navigator, Screen } from '../Navigator/AppNavigator';
import EditBookingScreen from '../../Booking/Screen/EditBookingScreen';
import CancelBookingScreen from '../../Booking/Screen/CancelBookingScreen';
import FulfillBookingScreen from '../../Booking/Screen/FulfillBookingScreen';
import { CareshipColors } from '../../Component/CSTheme';
import screenOptions from '../../screenOptions';
import ProposalDeclineScreen from '../../Customer/Screen/ProposalDeclineScreen';
import FirstBookingConfirmScreen from '../../Customer/Screen/FirstBookingConfirmScreen';
import FirstBookingDeclineScreen from '../../Customer/Screen/FirstBookingDeclineScreen';
import FirstBookingUpdateConfirmationStatusScreen from '../../Customer/Screen/FirstBookingUpdateConfirmationStatusScreen';
import CreateAbsenceScreen from '../../Account/Screen/CreateAbsenceScreen';
import EditAbsenceScreen from '../../Account/Screen/EditAbsenceScreen';
import RequestPasswordResetScreen from '../../Session/Screen/RequestPasswordResetScreen';
import PasswordResetScreen from '../../Session/Screen/PasswordResetScreen';

export default function AppNavigation() {
  useStoreHydration();
  const [{ isInitialized, isLoggedIn }] = useSession();

  // if (!isInitialized) {
  //   return <SplashScreen />;
  // }

  return (
    <NavigatorSafeArea forceInsetTop={isLoggedIn}>
      <Navigator
        screenOptions={{
          headerShown: false,
        }}
        initialRouteName="Auth"
      >
        {!isLoggedIn && (
          <>
            <Screen
              options={{
                contentStyle: { backgroundColor: CareshipColors.primary },
                gestureEnabled: false,
                stackAnimation: 'fade',
                title: intl.formatMessage({ id: 'LOGIN_TITLE' }),
              }}
              name="Auth"
              component={LoginScreen}
            />
            <Screen
              options={{
                contentStyle: { backgroundColor: CareshipColors.primary },
                gestureEnabled: false,
                stackAnimation: 'fade',
                title: intl.formatMessage({ id: 'PASSWORD_RESET' }),
              }}
              name="PasswordReset"
              component={PasswordResetScreen}
            />
            <Screen
              options={{
                contentStyle: { backgroundColor: CareshipColors.primary },
                gestureEnabled: false,
                stackAnimation: 'fade',
                title: intl.formatMessage({ id: 'FORGOTTEN_PASSWORD' }),
              }}
              name="RequestPasswordReset"
              component={RequestPasswordResetScreen}
            />
          </>
        )}
        {isLoggedIn && (
          <>
            <Screen
              name="App"
              options={{
                stackAnimation: 'fade',
              }}
              component={TabBar}
            />
            <Screen
              options={{
                ...screenOptions,
                stackPresentation: 'modal',
                headerShown: Platform.OS !== 'ios',
                title: intl.formatMessage({ id: 'ADD_APPOINTMENT_MODAL_TITLE' }),
              }}
              name="AddAppointment"
              component={AddBookingScreen}
            />
            <Screen
              options={{
                ...screenOptions,
                stackPresentation: 'modal',
                headerShown: Platform.OS !== 'ios',
                title: intl.formatMessage({ id: 'EDIT_APPOINTMENT_MODAL_TITLE' }),
              }}
              name="EditAppointment"
              component={EditBookingScreen}
            />
            <Screen
              options={{
                ...screenOptions,
                stackPresentation: 'modal',
                headerShown: Platform.OS !== 'ios',
                title: intl.formatMessage({ id: 'CANCEL_APPOINTMENT_MODAL_TITLE' }),
              }}
              name="CancelAppointment"
              component={CancelBookingScreen}
            />
            <Screen
              options={{
                ...screenOptions,
                stackPresentation: 'modal',
                headerShown: Platform.OS !== 'ios',
                title: intl.formatMessage({ id: 'FIRST_APPOINTMENT_CONFIRM_MODAL_TITLE' }),
              }}
              name="FirstBookingConfirm"
              component={FirstBookingConfirmScreen}
            />
            <Screen
              options={{
                ...screenOptions,
                stackPresentation: 'modal',
                headerShown: Platform.OS !== 'ios',
                title: intl.formatMessage({ id: 'FIRST_APPOINTMENT_CONFIRM_MODAL_TITLE' }),
              }}
              name="FirstBookingUpdateConfirmationStatus"
              component={FirstBookingUpdateConfirmationStatusScreen}
            />
            <Screen
              options={{
                ...screenOptions,
                stackPresentation: 'modal',
                headerShown: Platform.OS !== 'ios',
                title: intl.formatMessage({ id: 'FIRST_APPOINTMENT_DECLINE_MODAL_TITLE' }),
              }}
              name="FirstBookingDecline"
              component={FirstBookingDeclineScreen}
            />
            <Screen
              options={{
                ...screenOptions,
                // stackPresentation: 'modal', // Removed as modal
                headerShown: Platform.OS !== 'ios',
                title: intl.formatMessage({ id: 'LOG_APPOINTMENT_MODAL_TITLE' }),
              }}
              name="FulfillBooking"
              component={FulfillBookingScreen}
            />
            <Screen
              options={{
                ...screenOptions,
                stackPresentation: 'modal',
                headerShown: Platform.OS !== 'ios',
                title: intl.formatMessage({ id: 'PROPOSAL_DECLINE_MODAL_TITLE' }),
              }}
              name="DeclineProposal"
              component={ProposalDeclineScreen}
            />
            <Screen
              options={{
                ...screenOptions,
                stackPresentation: 'modal',
                headerShown: Platform.OS !== 'ios',
                title: intl.formatMessage({ id: 'ABSENCE_SCREEN_TITLE' }),
              }}
              name="CreateAbsence"
              component={CreateAbsenceScreen}
            />
            <Screen
              options={{
                ...screenOptions,
                stackPresentation: 'modal',
                headerShown: Platform.OS !== 'ios',
                title: intl.formatMessage({ id: 'ABSENCE_EDIT_SCREEN_TITLE' }),
              }}
              name="EditAbsence"
              component={EditAbsenceScreen}
            />
          </>
        )}
      </Navigator>
    </NavigatorSafeArea>
  );
}

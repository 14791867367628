import React, { ReactNode } from 'react';
import { Subheading } from 'react-native-paper';
import { StyleSheet, View } from 'react-native';
import CSTheme, { CareshipColors } from '../CSTheme';

type FormGroupTitlePropsSpecial = {
  children: ReactNode;
};

const stylesSpecial = StyleSheet.create({
  title: {
    textTransform: 'uppercase',
    ...CSTheme.fonts.medium,
    color: CareshipColors.slate300,
    fontSize: 14
  },
  titleRow: {
    borderBottomWidth: 1,
    borderBottomColor: CareshipColors.slate200,
    paddingBottom: 0,
  },
});

export default function FormGroupTitleSpecial({ children }: FormGroupTitlePropsSpecial) {
  return (
    <View style={stylesSpecial.titleRow}>
      <Subheading style={stylesSpecial.title}>{children}</Subheading>
    </View>
  );
}

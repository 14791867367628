/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-native-a11y/has-valid-accessibility-ignores-invert-colors */
import React, {useState, useRef, useEffect} from 'react';
import {
  View,
  Image,
  Platform,
  StyleSheet,
  useWindowDimensions,
  TouchableOpacity,
  Alert,
  ScrollView
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { Camera, CameraType } from 'expo-camera/legacy';
import {Card, TextInput, ActivityIndicator, Modal, Portal, Icon} from 'react-native-paper';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Feather, AntDesign } from '@expo/vector-icons';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { either } from 'fp-ts';


import {
  Button,
  CheckboxItem,
  Container,
  DateTimeInterval,
  FormGroupTitle,
  FormGroupTitleSpecial,
  Hint,
  InputErrorMessage, PressableWithConfirmation,
  Tag,
  Text, Title,
} from '../../../Component';
import styles from './FulfillBookingForm.styles';
import { events, trackEvent } from '../../../../Infrastructure/Tracking/Tracking';
import { useFulfillBooking, useServiceDefinitions } from '../../../../Context/Booking/Hook';
import { NonNullableProperties } from '../../../../Infrastructure/types/NonNullableProperties';
import { toggleSet } from '../../../../Infrastructure/Set';
import useBookingService from '../../../../Context/Booking/Hook/useBookingService';
import { isError } from '../../../../Infrastructure/Service';
import {alertForPermissions} from "../../../Customer/Hook/utils";
import useProfile from "../../../../Context/Profile/Hook/useProfile";
import {PermissionStatus} from "expo-modules-core/src/PermissionsInterface";

import SignatureScreen from '../SignatureScreen';
import {CareshipColors} from "../../../Component/CSTheme";
import {CheckCircleFilledIcon} from "../../../Component/Icon";


interface FormData {
  bookingId: string | null;
  startDate: Date;
  endDate: Date;
  mainServiceIds: Set<string>;
  otherServiceDescription: string;
  otherServicesEnabled: boolean;
  paymentMethod: string;
}

interface FulfillBookingFormProps {
  onSubmit: () => void;
  specialSubmit: () => void;
  booking: {
    readonly bookingId: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly paymentMethod: string;
    readonly surveyStatus: string;
  };
}

function validate(data: FormData): data is NonNullableProperties<FormData> {
  // Text field must not be empty if enabled
  if (data.otherServicesEnabled && data.otherServiceDescription.trim() === '') {
    return false;
  }

  // Must have at least one service
  if (!data.otherServicesEnabled && data.mainServiceIds.size === 0) {
    return false;
  }

  return data.bookingId !== null;
}

export default function FulfillBookingForm({ booking, onSubmit, specialSubmit }: FulfillBookingFormProps) {
  const { submitBooking, error, isLoading } = useFulfillBooking();
  const [bookingInterval, setBookingInterval] = useState<{ start: Date; end: Date }>({
    start: new Date(booking.startDate),
    end: new Date(booking.endDate),
  });
  const [image, setImage] = useState<string|null>(null);

  const [focused, setFocused] = useState<'appointmentTime' | null>(null);
  const [mainServiceIds, setMainServiceIds] = useState<Set<string>>(new Set());
  const [otherServicesEnabled, setOtherServicesEnabled] = useState(false);
  const [otherServiceDescription, setOtherServiceDescription] = useState('');
  const intl = useIntl();
  const profile = useProfile();
  const services = useServiceDefinitions();

  const bookingService = useBookingService();
  const [allowedForward, setAllowedForward] = useState(false);
  const [payingViaPrivateCasesVisibility, setPayingViaPrivateCasesVisibility] = React.useState(false);
  const [signaturePadCuPartOneVisibility, setSignaturePadCuPartOneVisibility] = React.useState(false);
  const [signaturePadCuPartTwoVisibility, setSignaturePadCuPartTwoVisibility] = React.useState(false);
  const [signaturePadCgVisibility, setSignaturePadCgVisibility] = React.useState(false);
  const [bookingTimeConfirmationVisibility, setBookingTimeConfirmationVisibility] = React.useState(false);
  const [bookingTimeAndPaymentConfirmationVisibility, setBookingTimeAndPaymentConfirmationVisibility] = React.useState(false);
  const [signViaPaper, setSignViaPaper] = React.useState(false);
  const [displayBookingConfirmation, setDisplayBookingConfirmation] = React.useState(false);
  const { width } = useWindowDimensions();

  const [paymentMethodFinal, setPaymentMethodFinal] = React.useState(booking.paymentMethod==='Privatzahlung'? 'Kein Budget nutzen - Privatzahlung': booking.paymentMethod);
  const surveyStatus = booking.surveyStatus;
  const paymentMethod = booking.paymentMethod;

  const { lastName, firstName } = booking.contract.careReceiver.name;

  const {start, end} = bookingInterval;

  const signaturePadCUPartOneRef = useRef(null);
  const signaturePadCUPartTwoRef = useRef(null);
  const signaturePadCGRef = useRef(null);

  const containerStyle = { boxShadow: 'none' };


  const [permission, requestPermission] = Camera.useCameraPermissions();
  const [type, setType] = useState(CameraType.back);
  const [visible, setVisible] = React.useState(false);
  const [uploadStatus, setUploadStatus] = React.useState('');
  const [imgLoading, setImgLoading] = React.useState(false);
  const [colorStatus, setColorStatus] = React.useState('');
  const cameraRef = useRef<Camera>();

  const showModal = async () => {
    if(permission?.status === PermissionStatus.GRANTED) {
      setVisible(true);
      handleBookingTimeAndPaymentConfirmationModal();
    } else {
      alertForPermissions(
        intl,
        'SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_IOS',
        'SERVICE_DOCUMENTATION_MISSING_CAMERA_PERMISSION_MESSAGE_ANDROID',
      );
    }
  }

  const hideModal = () => setVisible(false);
  const cameraContainerStyle = { backgroundColor: 'black' };

  const confirmationContainerStyle = {  backgroundColor: 'white', width: '100%', height: '100%', position: 'fixed'};


  const handleSignatureReceivedCUPartOne = (signatureData) => {
    setAllowedForward(true);
    handleUploadSignatureImage(signatureData, 1);
  };

  const handleSignatureReceivedCUPartTwo = (signatureData) => {
    setAllowedForward(true);
    handleUploadSignatureImage(signatureData, 2);
  };

  const handleSignatureReceivedCaregiver = (signatureData) => {
    setAllowedForward(true);
    handleUploadSignatureImage(signatureData, 3);
  };
  const handlePayPrivately = () => {
    setPaymentMethodFinal('Kein Budget nutzen - Privatzahlung')
    handleDisplaySignaturePadCgModal();
  };

  const handlePayingViaPrivateCasesModal = () => {
    setPayingViaPrivateCasesVisibility(!payingViaPrivateCasesVisibility );
    setTimeout(() => {
      setBookingTimeAndPaymentConfirmationVisibility(false);
      setBookingTimeConfirmationVisibility(false);
      setSignaturePadCgVisibility(false);
      setSignaturePadCuPartOneVisibility(false);
      setSignaturePadCuPartTwoVisibility(false);
    }, 500);
  }

  const handleBookingTimeAndPaymentConfirmationModal = () => {
    setBookingTimeAndPaymentConfirmationVisibility(!bookingTimeAndPaymentConfirmationVisibility );
    setTimeout(() => {
      setPayingViaPrivateCasesVisibility(false);
      setBookingTimeConfirmationVisibility(false);
      setSignaturePadCgVisibility(false);
      setSignaturePadCuPartOneVisibility(false);
      setSignaturePadCuPartTwoVisibility(false);
    }, 500);
  }

  const handleBookingTimeConfirmationModal = () => {
    setMainServiceIds(new Set(["5b379b85-8124-4f57-a9f7-d3c3b1b6d5f4"]));
    setBookingTimeConfirmationVisibility(!bookingTimeConfirmationVisibility );
    setTimeout(() => {
      setBookingTimeAndPaymentConfirmationVisibility(false);
      setPayingViaPrivateCasesVisibility(false);
      setSignaturePadCgVisibility(false);
      setSignaturePadCuPartOneVisibility(false);
      setSignaturePadCuPartTwoVisibility(false);
    }, 500);
  }

  const handleDisplaySignaturePadCuPartTwo = () => {
    if (signaturePadCUPartOneRef.current) {
      signaturePadCUPartOneRef.current.getSignature();
    }
  }
  const handleDisplaySignaturePadCg = () => {
    if (signaturePadCUPartTwoRef.current) {
      signaturePadCUPartTwoRef.current.getSignature();
    }
  }

  const handleDisplaySignaturePadCgDirect = () => {
    if (signaturePadCUPartOneRef.current) {
      signaturePadCUPartOneRef.current.getSignature();
    }
  }

  const handleFinalizeBookingSubmission = () => {
    if (signaturePadCGRef.current) {
      signaturePadCGRef.current.getSignature();
    }
  }
  const handleDisplaySignaturePadCgModal = () => {
    setAllowedForward(false)
    setSignaturePadCgVisibility(!signaturePadCgVisibility );
    setTimeout(() => {
      setBookingTimeAndPaymentConfirmationVisibility(false);
      setBookingTimeConfirmationVisibility(false);
      setPayingViaPrivateCasesVisibility(false);
      setSignaturePadCuPartOneVisibility(false);
      setSignaturePadCuPartTwoVisibility(false);
    }, 500);
  }

  const handleDisplaySignaturePadCuPartOneModal = () => {
    setAllowedForward(false)
    setSignaturePadCuPartOneVisibility(!signaturePadCuPartOneVisibility );
    setTimeout(() => {
      setBookingTimeAndPaymentConfirmationVisibility(false);
      setBookingTimeConfirmationVisibility(false);
      setSignaturePadCgVisibility(false);
      setPayingViaPrivateCasesVisibility(false);
      setSignaturePadCuPartTwoVisibility(false);
    }, 500);
  }
  const handleDisplaySignaturePadCuPartTwoModal = () => {
    setAllowedForward(false)
    setSignaturePadCuPartTwoVisibility(!signaturePadCuPartTwoVisibility );
    setTimeout(() => {
      setBookingTimeAndPaymentConfirmationVisibility(false);
      setBookingTimeConfirmationVisibility(false);
      setSignaturePadCgVisibility(false);
      setSignaturePadCuPartOneVisibility(false);
      setPayingViaPrivateCasesVisibility(false);
    }, 0);
  }


  const handlePayByInsurance = () => {
    setPaymentMethodFinal(paymentMethod);
  }


  const handlePictureUploadAndDocument = () => {
    setSignViaPaper(true);
  }

  const formData = {
    bookingId: booking.bookingId,
    startDate: bookingInterval.start,
    endDate: bookingInterval.end,
    mainServiceIds,
    otherServicesEnabled,
    otherServiceDescription,
    paymentMethodFinal
  };

  const handleServiceSelection = (id: string) => {
    setMainServiceIds(toggleSet(id));
  };

  const handleSubmit = () => {
    void (async () => {
      if (validate(formData)) {
        trackEvent(events.BOOKING_DOCUMENT_FINALIZE_CLICK);
        formData.paymentMethodFinal = formData.paymentMethodFinal === 'Kein Budget nutzen - Privatzahlung' ? 'Privatzahlung' : formData.paymentMethodFinal;
        const results = await submitBooking(formData);
        if (either.isRight(results)) {
          if(profile.profile?.isCareshipEmployee)
          {
            setBookingTimeAndPaymentConfirmationVisibility(false);
            setTimeout(() => {
              setDisplayBookingConfirmation(true);
            });
          }
          else
          {
            onSubmit();
          }
          return;
        }
        else {
          (Platform.OS === 'web')?
            alert('Entweder überschneidet sich die Termin mit einem anderen Termin, oder der Termin wurde bereits dokumentiert')
            : Alert.alert('Error', 'Entweder überschneidet sich die Termin mit einem anderen Termin, oder der Termin wurde bereits dokumentiert');
        }
      }
    })();
  };

  const imageUpload = async (base64Img: string, userType: number) => {
    let imageProof = base64Img
    const base64TagExpression = new RegExp("^data.+base64,")
    const base64Match = base64Img.match(base64TagExpression);
    const hasImageDataTag = base64Match && (base64Match?.index ?? -1) > -1

    if (!hasImageDataTag) {
      imageProof= `data:image/png;base64,${base64Img}`
    }

    const response = await bookingService.uploadSignatureImage({
      bookingId: booking?.bookingId,
      imageProof,
      userType
    });

    if (isError(response)) {
      setAllowedForward(false);
      Alert.alert('Error', 'An error occured while saving signature, try submitting again');
    }
    else
    {
      switch(userType)
      {
        case 1:
          setAllowedForward(false);
          paymentMethodFinal.indexOf('Privatzahlung') < 0 ? handleDisplaySignaturePadCuPartTwoModal():handleDisplaySignaturePadCgModal();
          break;
        case 2:
          setAllowedForward(false);
          handleDisplaySignaturePadCgModal();
          break;
        case 3:
          handleDisplaySignaturePadCgModal()
          break;
      }
    }
  }

  const handleUploadSignatureImage = async (signatureImage, type) => {
    await imageUpload(signatureImage, type);
    if (type === 3)
    {
      handleSubmit();
    }
  };

  const imageUploadPaperDocumentation = async (base64Img: string) => {
    let imageProof = base64Img
    const base64TagExpression = new RegExp("^data.+base64,")
    const base64Match = base64Img.match(base64TagExpression);
    const hasImageDataTag = base64Match && (base64Match?.index ?? -1) > -1

    if (!hasImageDataTag) {
      imageProof= `data:image/png;base64,${base64Img}`
    }
    setUploadStatus('');

    const response = await bookingService.uploadImage({
      bookingId: booking?.bookingId,
      imageProof
    });

    console.log(response)
    if (isError(response)) {
      setUploadStatus('Bild hochladen fehlgeschlagen');
      setColorStatus('red');
    } else {
      setUploadStatus('Bild hochgeladen');
      setColorStatus('green');
    }
    setImgLoading(false);
  }

  const handlePickImage = async () => {
    handleBookingTimeAndPaymentConfirmationModal();
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });
    if (!result.canceled) {
      setImgLoading(true);

      setImage(result.assets[0]?.uri ?? null);
      await imageUploadPaperDocumentation(result.assets[0]?.uri);
    }
  };

  const handleRemoveImg = () => {
    setImage(null);
    setSignViaPaper(false)
  };

  function toggleCameraType() {
    setType((current) => (current === CameraType.back ? CameraType.front : CameraType.back));
  }

  const handleTakePicture = async () => {

    const options = {
      quality: 0.75,
      base64: true,
      exif: false,
    };

    const newPhoto = await cameraRef?.current?.takePictureAsync(options);

    setImgLoading(true);
    hideModal();

    if (newPhoto && newPhoto.base64) {
      setImage(newPhoto.uri);
      await imageUploadPaperDocumentation(newPhoto.base64);
    }
  };

  useEffect(()=>{
    if(profile.profile?.isCareshipEmployee) {
      requestPermission()
    }
  },[permission?.status])


  return (
    <>
      <Portal>
        {/* Modal for getting camera images */}
        <Modal visible={visible} dismissable={false} contentContainerStyle={cameraContainerStyle}>
          <View style={style.container}>
            <Camera style={style.camera} type={type} ref={cameraRef}>
              <View style={style.buttonContainer}>
                <View style={style.cameraAction}>
                  <Button
                    icon={
                      <AntDesign style={{ marginLeft: 20 }} color="white" size={32} name="close" />
                    }
                    mode="text"
                    onPress={hideModal}
                    contentStyle={{ height: 60, width: 60 }}
                  />
                  <Button
                    mode="contained"
                    onPress={handleTakePicture}
                    contentStyle={{ paddingVertical: 0, height: 62, width: 62 }}
                  />
                  <Button
                    icon={
                      <AntDesign style={{ marginLeft: 20 }} color="white" size={32} name="sync" />
                    }
                    mode="text"
                    onPress={toggleCameraType}
                    contentStyle={{ height: 60, width: 60 }}
                  />
                </View>
              </View>
            </Camera>
          </View>
        </Modal>
        {/* End of Modal for getting camera images */}


        {/* Start of Booking confirmation Modal */}

        <Modal visible={displayBookingConfirmation} dismissable={false} contentContainerStyle={confirmationContainerStyle} style={style.confirmationModalStyles}>
          <ScrollView contentContainerStyle={{ flexShrink: 0, flexGrow: 1 }}>
            <View style={style.containerHolder}>
              {!signViaPaper && (
                <Container style={style.containerSpecial}>
                  <Title size="h4" center style={style.title}>
                    <FormattedMessage id="BOOKING_CONFIRMATION_TITLE" />
                  </Title>
                  <CheckCircleFilledIcon style={style.icon} color={CareshipColors.secondary} size={43} />
                  <Text size="h4" center style={style.regularText}>
                    <FormattedMessage id="BOOKING_CONFIRMATION_DIGITAL_LINE_1" />
                    {'\n'}
                    {'\n'}
                    <FormattedMessage id="BOOKING_CONFIRMATION_DIGITAL_LINE_2" /> <Feather name='heart' size={20}/>
                  </Text>
                </Container>
              )}

              {signViaPaper && (
                <Container style={style.containerSpecial}>
                  <Title size="h4" center style={style.title}>
                    <FormattedMessage id="BOOKING_CONFIRMATION_TITLE" />
                  </Title>
                  <CheckCircleFilledIcon style={style.icon} color={CareshipColors.secondary} size={43} />
                  <Text size="h4" center style={style.regularText}>
                    <FormattedMessage id="BOOKING_CONFIRMATION_PAPER_LINE_1" />
                    {'\n'}
                    {'\n'}
                    <FormattedMessage id="BOOKING_CONFIRMATION_PAPER_LINE_2" />
                  </Text>
                </Container>
              )}
              <Container>
                <Text size="h4" center style={style.regularText}>
                  <FormattedMessage id="BOOKING_CONFIRMATION_DIGITAL_LINE_3" />
                </Text>
                <Button
                  style={modalStyles.normalButtonSpecialCase}
                  onPress={onSubmit}
                  size="sm"
                >
                  <FormattedMessage id="FILL_SURVEY_NOW" />
                </Button>

                <Button
                  style={modalStyles.specialButtonsNormal}
                  onPress={specialSubmit}
                  size="sm"
                  labelStyle={modalStyles.labelStylesSpecial}
                >
                  <FormattedMessage id="FILL_SURVEY_LATER" />
                </Button>
              </Container>

            </View>
          </ScrollView>
        </Modal>

        {/* End of booking confirmation modal*/}



        {/*    STEP 1 -> Confirming Date and Time of booking    */}

        <Modal visible={bookingTimeConfirmationVisibility} dismissable={false} contentContainerStyle={containerStyle}>
          <View style={{ ...modalStyles.modal, width: Platform.OS === 'web' ? null : width - 20 }}>

            <View style={modalStyles.header}>
              <Text style={modalStyles.headerText}><FormattedMessage id='HEADING_FULFILL_SIGNATURE_STEP_1'></FormattedMessage></Text>
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  right: -10,
                  zIndex: 1, // Ensure the button is above the content
                }}
                onPress={() => handleBookingTimeConfirmationModal()}
              >
                <Feather name="x" size={24} color={CareshipColors.primary} />
              </TouchableOpacity>
            </View>
            <View style={modalStyles.modalContent}>
              <Text style={{...modalStyles.modalSubTitle, fontSize: 16, marginTop: 15, lineHeight: 25, fontWeight: 'normal'}} >
                <FormattedMessage id='TEXT_BOOKING_DATE_TIME_1'></FormattedMessage>
                <Text bold style={{fontSize: 18}}>{`${start.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}</Text>
                <FormattedMessage id='TEXT_BOOKING_DATE_TIME_2'></FormattedMessage>
                <Text bold style={{fontSize: 18}}>{`${end.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`}</Text>
                <FormattedMessage id='TEXT_BOOKING_DATE_TIME_3'></FormattedMessage>
                <Text bold style={{fontSize: 18}}>{`${end.toLocaleDateString()}`} </Text>
              </Text>
            </View>

            <View style={modalStyles.footer}>
              <Button
                style={modalStyles.goBackButton}
                onPress={handleBookingTimeConfirmationModal}
                color="white"
                labelStyle={{ color: CareshipColors.red }}
                size="sm"
                icon={<Feather color={CareshipColors.red } size={16} name="edit" />}
              >
                <FormattedMessage id="BUTTON_EDIT_BOOKING_SIGNATURE_SCREEN" />
              </Button>
              <Button
                style={modalStyles.goForwardButton}
                contentStyle={modalStyles.goForwardButtonText}
                onPress={handleBookingTimeAndPaymentConfirmationModal}
                size="sm"
                icon={<Feather color="white" size={16} name="skip-forward" />}
                iconRight={true}
              >
                <FormattedMessage id="BUTTON_NEXT_SIGNATURE_SCREEN" />
              </Button>
            </View>

          </View>
        </Modal>

        {/*    END STEP 1     */}

        {/*    STEP 2 -> Confirming of Booking Summary    */}

        <Modal visible={bookingTimeAndPaymentConfirmationVisibility} dismissable={false} contentContainerStyle={containerStyle}>
          <View style={{ ...modalStyles.modal, width: Platform.OS === 'web' ? null : width - 20 }}>

            <View style={modalStyles.header}>
              <Text style={modalStyles.headerText}><FormattedMessage id='HEADING_FULFILL_SIGNATURE_STEP_2'></FormattedMessage></Text>
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  right: -10,
                  zIndex: 1, // Ensure the button is above the content
                }}
                onPress={() => handleBookingTimeAndPaymentConfirmationModal()}
              >
                <Feather name="x" size={24} color={CareshipColors.primary} />
              </TouchableOpacity>
            </View>
            <View style={modalStyles.modalContent}>
              <View style={[modalStyles.group]}>
                <Container>
                  <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_DATE_TIME_LABEL" />
                </Container>
                <Container style={ modalStyles.dateTimeInfo}>
                  <TextInput
                    style={modalStyles.textInput}
                    label={'Date '}
                    value={start.toLocaleDateString()}
                    editable={false} // Disable input when isDisabled is true
                    disabled={true}
                  />
                  <TextInput
                    style={modalStyles.textInput}
                    label={'Start '}
                    value={start.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    editable={false} // Disable input when isDisabled is true
                    disabled={true}
                  />
                  <TextInput
                    style={modalStyles.textInput}
                    label={'End '}
                    value={end.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    editable={false} // Disable input when isDisabled is true
                    disabled={true}
                  />
                </Container>
              </View>

              <View style={[modalStyles.group]}>
                <Container>
                  <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_SERVICES_LABEL_SPECIAL" />
                </Container>
                <Container>
                  <TextInput
                    style={modalStyles.textInput}
                    value={'Alltagshilfe'}
                    editable={false} // Disable input when isDisabled is true
                    disabled={true}
                  />
                </Container>
              </View>

              <View style={[modalStyles.group]}>
                <Container>
                  <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_BUDGET_LABEL_SPECIAL" />
                </Container>
                <Container>
                  <TextInput
                    style={modalStyles.textInput}
                    value={paymentMethodFinal}
                    editable={false} // Disable input when isDisabled is true
                    disabled={true}
                  />
                </Container>
              </View>

              <Container>
                {!signViaPaper && (
                <Button
                  style={modalStyles.specialButtonsNormal}
                  onPress={handlePictureUploadAndDocument}
                  size="sm"
                  labelStyle={modalStyles.labelStylesSpecial}
                >
                  <FormattedMessage id="BUTTON_CLASSIC_FLOW" />
                </Button>
                )}

                {signViaPaper && (
                  <Container>
                    <Button
                      style={modalStyles.normalButtonSpecialCaseInverted}
                      onPress={Platform.OS === 'web' ? handlePickImage : showModal}
                      icon={<AntDesign name="camerao" size={24} color={CareshipColors.primary }/>}
                      size="sm"
                      labelStyle={modalStyles.normalButtonSpecialCaseInvertedLabel}
                    >
                      <FormattedMessage id="BUTTON_UPLOAD_FULFILL" />
                    </Button>
                    <Button
                      style={modalStyles.specialButtonsNormal}
                      onPress={()=>setSignViaPaper(false)}
                      size="sm"
                      labelStyle={modalStyles.labelStylesSpecial}
                    >
                      <FormattedMessage id="BUTTON_DIGITAL_FLOW" />
                    </Button>
                  </Container>
                )}
              </Container>

            </View>

            <View style={modalStyles.footer}>
              <Button
                style={modalStyles.goBackButton}
                onPress={handleBookingTimeConfirmationModal}
                color="white"
                labelStyle={{ color: CareshipColors.red }}
                size="sm"
                icon={<Feather color={CareshipColors.red } size={16} name="skip-back" />}
              >
                <FormattedMessage id="BUTTON_BACK_SIGNATURE_SCREEN" />
              </Button>
              {!signViaPaper && (
              <Button
                style={modalStyles.goForwardButton}
                contentStyle={modalStyles.goForwardButtonText}
                onPress={handleDisplaySignaturePadCuPartOneModal}
                size="sm"
                icon={<Feather color="white" size={16} name="skip-forward" />}
                iconRight={true}
              >
                <FormattedMessage id="BUTTON_NEXT_SIGNATURE_SCREEN" />
              </Button>
              )}

              {signViaPaper && (
                <Button
                  style={modalStyles.goForwardButton}
                  contentStyle={modalStyles.goForwardButtonText}
                  onPress={handleSubmit}
                  size="sm"
                  icon={<Feather color="white" size={24} name="check" />}
                  iconRight={true}
                >
                  <FormattedMessage id="BUTTON_SUBMIT_FULFILL" />
                </Button>
              )}
            </View>
          </View>
        </Modal>

        {/*    END STEP 2     */}

        {/*    STEP 3 -> CU signing part 1, booking info is correct   */}

        <Modal visible={signaturePadCuPartOneVisibility} dismissable={false}>
          <View style={{ ...modalStyles.modal, width: Platform.OS === 'web' ? null : width - 20 }}>

            <View style={modalStyles.headerSpecial}>
              <Text style={modalStyles.headerText}>
                <FormattedMessage id='HEADING_FULFILL_SIGNATURE_STEP_3_1'></FormattedMessage>
                {'\n'}
                <FormattedMessage id='HEADING_FULFILL_SIGNATURE_STEP_3_2'></FormattedMessage>
              </Text>
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  right: -10,
                  zIndex: 1, // Ensure the button is above the content
                }}
                onPress={() => handleDisplaySignaturePadCuPartOneModal()}
              >
                <Feather name="x" size={24} color={CareshipColors.primary} />
              </TouchableOpacity>
            </View>
            <View style={modalStyles.modalContent}>
              <ScrollView contentContainerStyle={{ flexShrink: 0, flexGrow: 1 }}>
                <View style={[modalStyles.group]}>
                    <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_DATE_TIME_LABEL" />
                  <Container style={ modalStyles.dateTimeInfo}>
                    <TextInput
                      style={modalStyles.textInput}
                      label={'Date '}
                      value={start.toLocaleDateString()}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                    <TextInput
                      style={modalStyles.textInput}
                      label={'Start '}
                      value={start.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                    <TextInput
                      style={modalStyles.textInput}
                      label={'End '}
                      value={end.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                  </Container>
                </View>

                <View style={[modalStyles.group]}>

                    <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_SERVICES_LABEL_SPECIAL" />

                    <TextInput
                      style={modalStyles.textInput}
                      value={'Alltagshilfe'}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                </View>

                <View style={[modalStyles.group]}>
                    <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_BUDGET_LABEL_SPECIAL" />
                    <TextInput
                      style={modalStyles.textInput}
                      value={paymentMethodFinal}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                </View>
              </ScrollView>
                <View style={[modalStyles.group]}>
                    <Text>
                      <FormattedMessage id="TEXT_SIGNATURE_CU_PART_1" /><Text bold>{`${firstName}`} {`${lastName}`}</Text><FormattedMessage id="TEXT_SIGNATURE_CU_PART_2" /> {'\n'}
                    </Text>
                  <Container style={{height: 180, marginBottom: 5}}>
                    <Text style={{textAlign:'center'}} muted>
                      <FormattedMessage id="TEXT_SIGNATURE_FOR_SIGNATURE_SCREEN" />{'\n'}
                    </Text>
                    <SignatureScreen
                      ref={signaturePadCUPartOneRef}
                      onSignatureReceived={handleSignatureReceivedCUPartOne}
                    />
                  </Container>
                </View>

                {/* Start ---- Allow coming back to original payment method if the booking has been marked as pay privately in the flow */}
              {(paymentMethod.indexOf('Privatzahlung') < 0 ) && paymentMethodFinal.indexOf( 'Privatzahlung') > -1 && (
                <Button
                  style={modalStyles.specialButtonsNormal}
                  onPress={handlePayByInsurance}
                  size="sm"
                  labelStyle={modalStyles.labelStylesSpecial}
                >
                  <FormattedMessage id="BUTTON_INSURANCE_COVER" />
                </Button>
              )}
              {/* End ---- Allow coming back to original payment method if the booking has been marked as pay privately in the flow */}

            </View>
            <View style={modalStyles.footer}>
              <Button
                style={modalStyles.goBackButton}
                onPress={handleBookingTimeAndPaymentConfirmationModal}
                color="white"
                labelStyle={{ color: CareshipColors.red }}
                size="sm"
                icon={<Feather color={CareshipColors.red } size={16} name="skip-back" />}
              >
                <FormattedMessage id="BUTTON_BACK_SIGNATURE_SCREEN" />
              </Button>

              {/* Start --- Go to step 4, for decleration of assignment if the booking is not private */}
              {paymentMethodFinal.indexOf( 'Privatzahlung') < 0 && (
                <Button
                  disabled = {allowedForward}
                  style={modalStyles.goForwardButton}
                  contentStyle={modalStyles.goForwardButtonText}
                  onPress={handleDisplaySignaturePadCuPartTwo}
                  size="sm"
                  icon={<Feather color="white" size={16} name="skip-forward" />}
                  iconRight={true}
                >
                  <FormattedMessage id="BUTTON_NEXT_SIGNATURE_SCREEN" />
                </Button>
              )}
              {/* End --- Go to step 4, for decleration of assignment if the booking is not private */}

              {/* Start --- Go to step 6, for caregiver signature if the booking is not marked as private */}
              {paymentMethodFinal.indexOf( 'Privatzahlung') > -1 && (
                <Button
                  disabled = {allowedForward}
                  style={modalStyles.goForwardButton}
                  contentStyle={modalStyles.goForwardButtonText}
                  onPress={handleDisplaySignaturePadCgDirect}
                  size="sm"
                  icon={<Feather color="white" size={16} name="skip-forward" />}
                  iconRight={true}
                >
                  <FormattedMessage id="BUTTON_NEXT_SIGNATURE_SCREEN" />
                </Button>
              )}
              {/* End --- Go to step 6, for caregiver signature if the booking is not marked as private */}
            </View>
          </View>
        </Modal>

        {/*    END STEP 3     */}

        {/*    STEP 4 -> CU signing part 2, paying via method   */}

        <Modal visible={signaturePadCuPartTwoVisibility} dismissable={false} contentContainerStyle={containerStyle}>
          <View style={{ ...modalStyles.modal, width: Platform.OS === 'web' ? null : width - 20 }}>

            <View style={modalStyles.headerSpecial}>
              <Text style={modalStyles.headerText}>
                <FormattedMessage id='HEADING_FULFILL_SIGNATURE_STEP_4_1'></FormattedMessage>
                {'\n'}
                <FormattedMessage id='HEADING_FULFILL_SIGNATURE_STEP_4_2'></FormattedMessage>
              </Text>
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  right: -10,
                  zIndex: 1, // Ensure the button is above the content
                }}
                onPress={() => handleDisplaySignaturePadCuPartTwoModal()}
              >
                <Feather name="x" size={24} color={CareshipColors.primary} />
              </TouchableOpacity>
            </View>
            <View style={modalStyles.modalContent}>
              <ScrollView contentContainerStyle={{ flexShrink: 0, flexGrow: 1 }}>
                <View style={[modalStyles.group]}>
                  <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_DATE_TIME_LABEL" />
                  <Container style={ modalStyles.dateTimeInfo}>
                    <TextInput
                      style={modalStyles.textInput}
                      label={'Date '}
                      value={start.toLocaleDateString()}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                    <TextInput
                      style={modalStyles.textInput}
                      label={'Start '}
                      value={start.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                    <TextInput
                      style={modalStyles.textInput}
                      label={'End '}
                      value={end.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                  </Container>
                </View>

                <View style={[modalStyles.group]}>

                  <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_SERVICES_LABEL_SPECIAL" />

                  <TextInput
                    style={modalStyles.textInput}
                    value={'Alltagshilfe'}
                    editable={false} // Disable input when isDisabled is true
                    disabled={true}
                  />
                </View>

                <View style={[modalStyles.group]}>
                  <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_BUDGET_LABEL_SPECIAL" />
                  <TextInput
                    style={modalStyles.textInput}
                    value={paymentMethodFinal}
                    editable={false} // Disable input when isDisabled is true
                    disabled={true}
                  />
                </View>
              </ScrollView>
                <View style={[modalStyles.group]}>
                  <Text>
                    <FormattedMessage id="TEXT_DECLERATION_SIGNATURE_CU_PART_1" /><Text bold>{`${firstName}`} {`${lastName}`}</Text><FormattedMessage id="TEXT_DECLERATION_SIGNATURE_CU_PART_2" /> {'\n'}
                  </Text>
                  <Container style={{height: 180, marginBottom: 5}}>
                    <Text  style={{textAlign:'center'}} muted>
                      <FormattedMessage id="TEXT_SIGNATURE_FOR_SIGNATURE_SCREEN" /> {'\n'}
                    </Text>
                    <SignatureScreen
                      ref={signaturePadCUPartTwoRef}
                      onSignatureReceived={handleSignatureReceivedCUPartTwo}
                    />
                  </Container>
                  {paymentMethodFinal.indexOf('Privatzahlung') < 0 && (<Container>
                  <Button
                    style={modalStyles.specialButtonsNormal}
                    onPress={handlePayingViaPrivateCasesModal}
                    size="sm"
                    labelStyle={modalStyles.labelStylesSpecial}
                  >
                    <FormattedMessage id="BUTTON_NOT_SIGNING_SIGNATURE_SCREEN" />
                  </Button>
                  </Container> )}

                </View>

            </View>
            <View style={modalStyles.footer}>
              <Button
                style={modalStyles.goBackButton}
                onPress={handleDisplaySignaturePadCuPartOneModal}
                color="white"
                labelStyle={{ color: CareshipColors.red }}
                size="sm"
                icon={<Feather color={CareshipColors.red } size={16} name="skip-back" />}
              >
                <FormattedMessage id="BUTTON_BACK_SIGNATURE_SCREEN" />
              </Button>
              <Button
                disabled = {allowedForward}
                style={modalStyles.goForwardButton}
                contentStyle={modalStyles.goForwardButtonText}
                onPress={handleDisplaySignaturePadCg}
                size="sm"
                icon={<Feather color="white" size={16} name="skip-forward" />}
                iconRight={true}
              >
                <FormattedMessage id="BUTTON_NEXT_SIGNATURE_SCREEN" />
              </Button>
            </View>
          </View>
        </Modal>

        {/*    END STEP 4     */}

        {/*    STEP 5 -> CU signing part 2, paying via method private  */}

        <Modal visible={payingViaPrivateCasesVisibility} dismissable={false} contentContainerStyle={containerStyle}>
          <View style={{ ...modalStyles.modal, width: Platform.OS === 'web' ? null : width - 20 }}>

            <View style={modalStyles.header}>
              <Text style={modalStyles.headerText}><FormattedMessage id='HEADING_FULFILL_SIGNATURE_STEP_5'></FormattedMessage></Text>
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  right: -10,
                  zIndex: 1, // Ensure the button is above the content
                }}
                onPress={() => handlePayingViaPrivateCasesModal()}
              >
                <Feather name="x" size={24} color={CareshipColors.primary} />
              </TouchableOpacity>
            </View>
            <View style={modalStyles.modalContent}>

              <View style={[modalStyles.group]}>
                <Text style={{...modalStyles.modalSubTitle, fontSize: 16, marginTop: 15, lineHeight: 25, fontWeight: 'normal'}}>
                  <FormattedMessage id="TEXT_WARNING_SIGNATURE_SCREEN" />{'\n'}
                </Text>
                <Container>
                  <Button
                    style={modalStyles.normalButtonSpecialCaseInverted}
                    onPress={handlePayPrivately}
                    size="sm"
                    labelStyle={ modalStyles.normalButtonSpecialCaseInvertedLabel}
                  >
                    <FormattedMessage id="BUTTON_AGREE_PAY_PRIVATELY" />
                  </Button>
                  <Button
                    style={modalStyles.normalButton}
                    onPress={handleDisplaySignaturePadCuPartTwoModal}
                    size="sm"
                  >
                    <FormattedMessage id="BUTTON_NOT_AGREE_PAY_PRIVATELY" />
                  </Button>
                </Container>

              </View>

            </View>
          </View>
        </Modal>

        {/*    END STEP 5     */}

        {/*    STEP 6 -> CG signing  */}

        <Modal visible={signaturePadCgVisibility} dismissable={false} contentContainerStyle={containerStyle}>
          <View style={{ ...modalStyles.modal, width: Platform.OS === 'web' ? null : width - 20 }}>

            <View style={modalStyles.headerSpecial}>
              <Text style={modalStyles.headerText}>
                <FormattedMessage id='HEADING_FULFILL_SIGNATURE_STEP_6_1'></FormattedMessage>
                {'\n'}
                <FormattedMessage id='HEADING_FULFILL_SIGNATURE_STEP_6_2'></FormattedMessage>
              </Text>
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  right: -10,
                  zIndex: 1, // Ensure the button is above the content
                }}
                onPress={() => handleDisplaySignaturePadCgModal()}
              >
                <Feather name="x" size={24} color={CareshipColors.primary} />
              </TouchableOpacity>
            </View>
            <View style={modalStyles.modalContent}>
              <ScrollView contentContainerStyle={{ flexShrink: 0, flexGrow: 1 }}>
                <View style={[modalStyles.group]}>
                  <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_DATE_TIME_LABEL" />
                  <Container style={ modalStyles.dateTimeInfo}>
                    <TextInput
                      style={modalStyles.textInput}
                      label={'Date '}
                      value={start.toLocaleDateString()}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                    <TextInput
                      style={modalStyles.textInput}
                      label={'Start '}
                      value={start.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                    <TextInput
                      style={modalStyles.textInput}
                      label={'End '}
                      value={end.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                      editable={false} // Disable input when isDisabled is true
                      disabled={true}
                    />
                  </Container>
                </View>
                <View style={[modalStyles.group]}>

                  <FormattedMessage tagName={FormGroupTitleSpecial} id="APPOINTMENT_SERVICES_LABEL_SPECIAL" />

                  <TextInput
                    style={modalStyles.textInput}
                    value={'Alltagshilfe'}
                    editable={false} // Disable input when isDisabled is true
                    disabled={true}
                  />
                </View>
              </ScrollView>
                <View style={[modalStyles.group]}>

                  <Text>{'\n'}
                   <FormattedMessage id='TEXT_SIGNATURE_CG_PART_1'/>
                    <Text bold>{`${profile.profile?.name.firstName}`} {`${profile.profile?.name.lastName}`}</Text>
                    <FormattedMessage id='TEXT_SIGNATURE_CG_PART_2'/>{'\n'}
                  </Text>
                  <Container style={{height: 180, marginBottom: 5}}>
                    <Text  style={{textAlign:'center'}} muted>
                      <FormattedMessage id="TEXT_SIGNATURE_FOR_SIGNATURE_SCREEN" /> {'\n'}
                    </Text>
                    <SignatureScreen
                      ref={signaturePadCGRef}
                      onSignatureReceived={handleSignatureReceivedCaregiver}
                    />
                  </Container>
                </View>


            </View>
            <View style={modalStyles.footer}>
              <Button
                style={modalStyles.goBackButton}
                onPress={handleDisplaySignaturePadCuPartOneModal}
                color="white"
                labelStyle={{ color: CareshipColors.red }}
                size="sm"
                icon={<Feather color={CareshipColors.red } size={16} name="skip-back" />}
              >
                <FormattedMessage id="BUTTON_BACK_SIGNATURE_SCREEN" />
              </Button>
              <Button
                disabled = {allowedForward}
                style={modalStyles.goForwardButton}
                contentStyle={modalStyles.goForwardButtonText}
                onPress={handleFinalizeBookingSubmission}
                size="sm"
                icon={<Feather color="white" size={16} name="check" padding={0}/>}
                iconRight={true}
              >
                <FormattedMessage id="BUTTON_NEXT_SIGNATURE_SCREEN" />
              </Button>
            </View>
          </View>
        </Modal>

        {/*    END STEP 6     */}

      </Portal>
      <View style={[styles.group]}>
        <Container>
          <FormattedMessage tagName={FormGroupTitle} id="APPOINTMENT_DATE_LABEL" />
        </Container>
        <DateTimeInterval
          focused={focused === 'appointmentTime'}
          onIntervalChange={setBookingInterval}
          interval={bookingInterval}
          onFocus={() => setFocused('appointmentTime')}
        />
      </View>
      <View style={styles.bookingHoursRow}>
        <Container style={styles.bookingHoursCol}>
          <FormattedMessage
            id="HOURS_WITH_UNIT"
            values={{
              hours: (
                <FormattedNumber
                  value={differenceInMinutes(bookingInterval.end, bookingInterval.start) / 60}
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                />
              ),
            }}
          >
            {(chunks) => (
              <Tag
                color={
                  differenceInMinutes(bookingInterval.end, bookingInterval.start) >= 60 * 8
                    ? 'error'
                    : undefined
                }
              >
                {chunks}
              </Tag>
            )}
          </FormattedMessage>
          <Text style={styles.bookingHoursLabel} muted bold uppercase>
            <FormattedMessage id="WORKED" />
          </Text>
        </Container>
      </View>
      {!profile.profile?.isCareshipEmployee && (
        <>
      <View style={[styles.group]}>
        <Container>
          <FormattedMessage tagName={FormGroupTitle} id="APPOINTMENT_SERVICES_LABEL" />
          <Hint hint={<FormattedMessage id="APPOINTMENT_SERVICES_HINT" />} />
        </Container>
        {services.map((service) => (
          <CheckboxItem
            key={service.id}
            onPress={() => handleServiceSelection(service.id)}
            status={mainServiceIds.has(service.id) ? 'checked' : 'unchecked'}
            label={intl.locale.startsWith('en') ? service.labelEn : service.labelDe}
            testID={service.id}
          />
        ))}
        <CheckboxItem
          onPress={() => setOtherServicesEnabled((v) => !v)}
          status={otherServicesEnabled ? 'checked' : 'unchecked'}
          label={<FormattedMessage id="OTHER_SERVICES" />}
        />
        {otherServicesEnabled && (
          <View
            style={{
              borderTopColor: 'rgba(255,179,0,0.15)',
              borderTopWidth: 1,
            }}
          >
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint={intl.formatMessage({ id: 'OTHER_SERVICES_LABEL' })}
              textAlign="left"
              multiline
              style={{
                marginTop: -1,
                paddingHorizontal: 16,
                paddingBottom: 8,
                backgroundColor: 'rgba(255,179,0,0.1)',
              }}
              underlineColor="rgba(255,179,0,0.15)"
              label={intl.formatMessage({ id: 'OTHER_SERVICES_LABEL' })}
              theme={{ roundness: 0 }}
              maxLength={100}
              onChangeText={(text) => setOtherServiceDescription(text)}
              value={otherServiceDescription}
            />
          </View>
        )}
      </View>



      <Container>

        {error && (
          <InputErrorMessage>
            <FormattedMessage id="ERROR_RESPONSE" values={{ errorCode: error.errorCode }} />
          </InputErrorMessage>
        )}


        {!profile.profile?.isCareshipEmployee && (
            <Button
              style={styles.submitButton}
              loading={isLoading}
              disabled={!validate(formData) || isLoading }
              onPress={handleSubmit}
              icon={<Feather color="white" size={32} name="check" />}
            >
              <FormattedMessage id="BUTTON_SUBMIT_FULFILL" />
            </Button>
        )}


      </Container>
        </>
        )}
      {profile.profile?.isCareshipEmployee && (
        <Container>
          {!image && (
          <Button
            style={styles.submitButton}
            loading={isLoading}
            onPress={handleBookingTimeConfirmationModal}
            icon={<Feather color="white" size={32} name="thumbs-up" />}
          >
            <FormattedMessage id="BUTTON_CONFIRM_FULFILL" />
          </Button>
          )}

          {error && (
            <InputErrorMessage>
              <FormattedMessage id="ERROR_RESPONSE" values={{ errorCode: error.errorCode }} />
            </InputErrorMessage>
          )}

          {image && signViaPaper && (
            <View>
              <Card>
                <Card.Actions>
                  <Button
                    icon={<AntDesign color="black" size={32} name="close" />}
                    mode="text"
                    onPress={handleRemoveImg}
                  />
                  {imgLoading && <ActivityIndicator animating color="#FFB300" />}
                  {uploadStatus && (
                    <Button
                      icon={<Feather color={colorStatus} size={32} name="check" />}
                      mode="text"
                      color={colorStatus}
                    >
                      {uploadStatus}
                    </Button>
                  )}
                </Card.Actions>
                <Image source={{ uri: image }} style={{ height: 500 }} resizeMode="contain" />
              </Card>

              <Button
                style={styles.submitButton}
                loading={isLoading}
                onPress={handleSubmit}
                icon={<Feather color="white" size={32} name="check" />}
              >
                <FormattedMessage id="BUTTON_SUBMIT_FULFILL" />
              </Button>
            </View>

          )}
        </Container>
      )}

    </>
  );
}

const style = StyleSheet.create({
  container: {
    height: '95%',
  },
  containerHolder: {
    height: '100%',
    paddingTop: 60,
    paddingBottom: 60
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
  camera: {
    flex: 1,
  },
  cameraAction: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 5,
    height: '15%',
  },
  containerSpecial:{
    marginVertical: 'auto',
    marginTop: 0
  },
  confirmationModalStyles:{
    position: "absolute",
    width: '100%',
    height: '98%',
    left: 0,
    top: 0,
    backgroundColor: 'white'
  },
  title: { color: CareshipColors.secondary, fontSize: 30, lineHeight: 40},
  regularText: { color: CareshipColors.secondary, fontSize: 20, lineHeight: 30},
  icon: { marginTop: 16, margin: 'auto', marginBottom: 16},
});

const modalStyles = StyleSheet.create({
  modal: {
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 10,
    alignSelf: 'center',
  },

  modalTitle: {
    fontSize: 20,
    fontWeight: '700',
    marginBottom: 40,
    marginTop: 20,

  },
  modalSubTitle: {
    fontSize: 15,
  },
  modalContent: {
    width: '100%',
    fontSize: 12,
    maxHeight:500,
  },
  modalButtons: {
    flexDirection: 'row',
    marginTop: 20,
  },
  footer: {
    width: '100%',
    height:35,
    borderColor: CareshipColors.slate300,
    borderWidth: 0,
    textAlign: 'center',
    borderTopWidth: 1,
    marginTop: 20,
    paddingTop: 0,
    alignItems: 'center',
  },
  button: {
    padding: 10,
    backgroundColor: '#007BFF',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  header: {
    width: '100%',
    height:40,
    borderColor: CareshipColors.slate300,
    borderWidth: 0,
    textAlign: 'center',
    borderBottomWidth: 1,
    marginBottom: 10,
    marginTop:-10,

  },
  headerSpecial: {
    width: '100%',
    height:50,
    borderColor: CareshipColors.slate300,
    borderWidth: 0,
    textAlign: 'center',
    borderBottomWidth: 1,
    marginBottom: 10,
    marginTop:-10,

  },
  headerText:{
    padding:5,
    fontSize:16,
    textAlign: 'center',
    fontWeight: "800",
    color: CareshipColors.slate400,
  },
  goBackButton: {
    width:'40%',
    position:'absolute',
    left:10,
    top:5,
    borderStyle: "solid",
    borderColor: CareshipColors.red,
    borderWidth: 1,
    padding: 0
  },
  normalButton: {
    borderStyle: "solid",
    borderColor: CareshipColors.primary,
    borderWidth: 1,
  },
  normalButtonSpecialCaseInverted: {
    borderStyle: "solid",
    borderColor: CareshipColors.primary,
    borderWidth: 1,
    backgroundColor: 'white',
    marginBottom:5
  },

  normalButtonSpecialCase: {
    borderStyle: "solid",
    borderColor: CareshipColors.primary,
    borderWidth: 1,
    marginTop: 10,
    width: 300,
    margin: 'auto',
  },
  normalButtonSpecialCaseInvertedLabel: {
      color: CareshipColors.primary
  },
  goForwardButton: {
    width:'40%',
    position:'absolute',
    right:10,
    top:5,
    borderStyle: "solid",
    borderColor: CareshipColors.primary,
    borderWidth: 1,
    padding: 0
  },
  goForwardButtonText:
  {

  },
  dateTimeInfo:
  {
    flexDirection: "row"
  },
  group: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 12
  },
  textInput:{
    padding:0,
    marginTop: -5,
    fontSize: 12
  },
  specialButtonsNormal:{
    borderWidth:0,
    padding:0,
    textAlign: "left",
    height:30,
    backgroundColor: 'white',
  },
  labelStylesSpecial: {
    lineHeight:14,
    textDecorationLine:"underline",
    margin:0,
    color: CareshipColors.primary
  }
});

import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ActivityIndicator, Animated, StyleSheet, View } from 'react-native';
import WebView from 'react-native-webview';
import { FormattedMessage } from 'react-intl';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
import { useNavigation } from '@react-navigation/native';
import { IconButton } from 'react-native-paper';
import format from 'date-fns/format';
import { logger } from '../../../Infrastructure/Service';
import { Button, ProgressBar, Text } from '../../Component';
import CSTheme, { CareshipColors } from '../../Component/CSTheme';
import { ServiceDocumentationPeriodScreen_serviceDocumentationQuery$key } from './__generated__/ServiceDocumentationPeriodScreen_serviceDocumentationQuery.graphql';
import { usePickAndUploadFile, useServiceDocumentationDownloadUrl } from '../Hook';
import { CustomerStackNavigationProp } from '../Navigator/CustomerStackNavigationProp';
import { useDownloadFile } from '../../../Infrastructure/Hook';
import { DownloadIcon } from '../../Component/Icon';
import PdfReader from '../../Component/PdfReader';

const styles = StyleSheet.create({
  container: { flex: 1, position: 'relative' },
  browser: { flex: 1 },
  overlay: {
    position: 'absolute',
    backgroundColor: '#F2F2F7cc',
    top: 0,
    left: 0,
    width: '100%',
    bottom: 85,
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonBar: {
    height: 84,
    padding: 16,
    alignItems: 'center',
    borderTopColor: CareshipColors.slate200,
    borderTopWidth: StyleSheet.hairlineWidth,
  },
  errorMessage: { color: CareshipColors.red },
});

function getHtml(uri?: string) {
  return uri
    ? `<!DOCTYPE html>
    <html lang="en">
      <body style="height: 100vh; display: flex; align-items: flex-start; justify-content: center; background: #F2F2F7; padding: 16px">
        <img alt="" style="box-shadow: 0 0 8px #333c; max-width: 100%;" src="${uri}" />
      </body>
    </html>`
    : '';
}

function getFilenameToDownload(mimeType: string | undefined, year: string, month: string) {
  if (!mimeType) {
    return null;
  }

  try {
    return `${format(new Date(Number(year), Number(month) - 1), 'y-MM')}.${mimeType.split('/')[1]}`;
  } catch (error) {
    logger.warn(`Failed generating LNW file name: ${mimeType}, ${year}, ${month}`);

    return 'leistungsnachweise';
  }
}

export default function ServiceDocumentationPeriod({
  fragment,
}: {
  fragment: ServiceDocumentationPeriodScreen_serviceDocumentationQuery$key;
}) {
  const [{ lastUploadedFile, serviceDocumentationId, month, year }, refetch] =
    useRefetchableFragment(
      graphql`
        fragment ServiceDocumentationPeriodScreen_serviceDocumentationQuery on ServiceDocumentation
        @refetchable(queryName: "serviceDocumentationRefetchQuery") {
          serviceDocumentationId
          month
          year
          lastUploadedFile {
            mimeType
            pathInStorage
          }
        }
      `,
      fragment,
    );

  const filenameToDownload = getFilenameToDownload(lastUploadedFile?.mimeType, year, month);

  const [uri, setUri] = useState<string | undefined>(undefined);

  const { generateUrl } = useServiceDocumentationDownloadUrl(serviceDocumentationId);

  const progressPercentage = useRef(new Animated.Value(0)).current;

  const setFileForPreview = useCallback(async () => {
    const generatedUrl = await generateUrl();

    setUri(generatedUrl);
  }, [generateUrl]);

  const { status, showPickerAndUploadFile, progress } = usePickAndUploadFile(
    `/caregiver-app/service-documentation/${serviceDocumentationId}/files`,
    () => {
      progressPercentage.setValue(0);

      void setFileForPreview();

      return refetch({}, { fetchPolicy: 'store-and-network' });
    },
  );

  const { download, isDownloadInProgress, isDownloadLoading } = useDownloadFile();

  useEffect(() => {
    Animated.spring(progressPercentage, { toValue: progress, useNativeDriver: false }).start();

    if (uri) {
      return;
    }

    void setFileForPreview();
  }, [progress, progressPercentage, uri, setFileForPreview]);

  const navigation = useNavigation<CustomerStackNavigationProp<'ServiceDocumentationPeriod'>>();

  const handleFileDownload = useCallback<() => void>(async () => {
    if (!filenameToDownload) {
      logger.warn(
        `No file name to download for the service documentation ${serviceDocumentationId}`,
      );

      return;
    }

    const uriForDownload = await generateUrl();

    if (!uriForDownload) {
      logger.warn(
        `No generated download uri for the service documentation ${serviceDocumentationId}`,
      );

      return;
    }

    await download(filenameToDownload, uriForDownload);
  }, [download, filenameToDownload, generateUrl, serviceDocumentationId]);

  const handleUploadPress = useCallback(() => {
    void showPickerAndUploadFile();
  }, [showPickerAndUploadFile]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <IconButton
          onPress={handleFileDownload}
          style={{ margin: -8 }}
          disabled={isDownloadInProgress || isDownloadLoading || !filenameToDownload}
          icon={
            isDownloadLoading
              ? () => <ActivityIndicator color={CSTheme.colors.background} />
              : () => <DownloadIcon color={CareshipColors.white} />
          }
        />
      ),
    });
  }, [
    navigation,
    download,
    isDownloadInProgress,
    isDownloadLoading,
    handleFileDownload,
    filenameToDownload,
  ]);

  if (!uri) {
    return null;
  }

  return (
    <View style={styles.container}>
      {(status === 'progress' || status === 'failed') && (
        <View style={styles.overlay}>
          {status === 'progress' && (
            <ProgressBar backgroundColor="white" progress={progressPercentage} />
          )}
          {status === 'failed' && (
            <Text style={styles.errorMessage}>
              <FormattedMessage id="SERVICE_DOCUMENTATION_UPLOAD_FAILED" />
            </Text>
          )}
        </View>
      )}
      {lastUploadedFile?.mimeType === 'application/pdf' && (
        <PdfReader
          source={{
            uri,
          }}
        />
      )}
      {lastUploadedFile?.mimeType.startsWith('image/') && (
        <WebView
          style={styles.browser}
          source={{
            html: getHtml(uri),
          }}
        />
      )}
      <View style={styles.buttonBar}>
        <Button size="sm" disabled={status === 'progress'} onPress={handleUploadPress}>
          <FormattedMessage id="SERVICE_DOCUMENTATION_UPLOAD_REPLACE_CURRENT" />
        </Button>
      </View>
    </View>
  );
}

import React, { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { FormattedMessage } from 'react-intl';
import { CareshipColors } from './CSTheme';
import Text from './Text';

type TagProps = {
  mode: 'dot' | 'text' | 'specialDot';
  color?: string;
  children?: ReactNode;
  style?: StyleProp<ViewStyle>;
};

const styles = StyleSheet.create({
  dotView: {
    backgroundColor: CareshipColors.slate400,
    width: 16,
    height: 16,
    borderRadius: 8,
    marginRight: 8,
    overflow: 'hidden', // Ensure child views are clipped to the circle
    position: 'relative',
  },
  outerView: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    height: 22,
  },
  dot: {},
  specialDot: {},
  solid: {
    borderRadius: 16,
    paddingVertical: 4,
    paddingHorizontal: 12,
  },
  text: {},
  halfCircle: {
    width: 16,
    height: 16,
  },
  leftHalf: {
    position:"absolute",
    left: 0,
    backgroundColor: CareshipColors.blue, // Left half color
    borderBottomLeftRadius: 100,
    borderTopLeftRadius: 100,
    width: 8
  },
  rightHalf: {
    position:"absolute",
    right: 0,
    backgroundColor: CareshipColors.red, // Right half color
    borderBottomRightRadius: 100,
    borderTopRightRadius: 100,
    width: 8
  },
});
export default function CellLabel({
  color = CareshipColors.slate400,
  mode = 'dot',
  style,
  children
}: TagProps) {
  return (
    <View style={[styles.outerView, styles[mode], style]}>
      {mode === 'dot' && <View style={[styles.dotView, { backgroundColor: color }]} />}
      {mode === 'specialDot' && (
        <View style={[styles.dotView]}>
          <View style={[styles.halfCircle, styles.leftHalf]} />
          <View style={[styles.halfCircle, styles.rightHalf]} />
      </View>)}
      <Text style={[{ color }]} uppercase bold size="small">
         {children}
      </Text>
    </View>
  );
}

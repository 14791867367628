/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useCallback } from 'react';
import { View, StyleSheet, ScrollView, Platform, useWindowDimensions } from 'react-native';
import Modal from 'react-native-modal';
import styled from '@emotion/native';
import { FormattedMessage } from 'react-intl';
import { useNavigation } from '@react-navigation/native';

import { Button, Text } from '../../Component';
import BookingListItem from '../../Booking/Component/BookingListItem/BookingListItem';
import { events, trackEvent } from '../../../Infrastructure/Tracking/Tracking';
import {BookingContractTuple} from "../../../Context/Booking/Hook/useBookings";
import PlatformLocalStorage from "../../../Infrastructure/PlatformLocalStorage/PlatformLocalStorage";


const ActionButton = styled(Button)({
  borderRadius: 10,
  alignContent: 'center',
  marginRight: 10,
  width: Platform.OS !== 'web' ? 170 : null,
});

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 30,
    borderRadius: 10,
    height: 150,
    alignSelf: 'center',
    maxHeight: 700,
    minHeight: 200,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: '700',
    marginBottom: 40,
    marginTop: 20,
  },
  modalSubTitle: {
    fontSize: 15,
    fontStyle: 'italic',
  },
  modalContent: {
    width: '100%',
    marginTop: 20,
  },
  modalButtons: {
    flexDirection: 'row',
    marginTop: 20,
  },
});

type Props = {
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
  pastBookings: BookingContractTuple[];
};

// TODO: this solution must be refactor.
//       Use mobX, or React Context-Api


let hideModalUntil = {'hideModalUntil': 0};
PlatformLocalStorage.getItem('hideModalUntil', hideModalUntil)

const MINUTE_MS_SHOW_MODAL = 86400000;
const DocumentModal: React.FC<Props> = ({ showModal, setShowModal, pastBookings }) => {
  const { width } = useWindowDimensions();
  const navigation = useNavigation();
  const handleNavigate = () => {
    navigation.navigate('BookingListTab', {
      screen: 'Diary',
      params: {
        isPastBooking: true,
      },
    });
  };

  const handleHideModal = () => {
    // Only non-employed-caregiver can dismiss the Dialog.
    hideModalUntil.hideModalUntil = Date.now() + MINUTE_MS_SHOW_MODAL;
    PlatformLocalStorage.setItem('hideModalUntil', hideModalUntil.hideModalUntil)
    setShowModal(false)
  }

  const handleBookingSelected = useCallback(
    (bookingId: string) => {
      setShowModal(false);

      navigation.navigate('BookingListTab', {
        screen: 'BookingDetail',
        initial: false,
        params: {
          bookingId,
          isPastBooking: true,
        },
      });

      trackEvent(events.DASHBOARD_OPEN_BOOKING);
    },
    [setShowModal, navigation],
  );

  if ( (Number(hideModalUntil.hideModalUntil) > Date.now()) || pastBookings.length === 0 ) {

    return null
  }

  return (
    <Modal
      isVisible={showModal}
      onBackdropPress={() => {
          setShowModal(false);
      }}
    >
      <View style={{ ...styles.modal, width: Platform.OS === 'web' ? null : width - 20 }}>
        <Text style={{ ...styles.modalTitle, fontSize: Platform.OS === 'web' ? 26 : 20 }}>
          <FormattedMessage id="UNDOCUMENTED_MODAL_TITLE" />
        </Text>
        <Text style={styles.modalSubTitle}>
          <FormattedMessage id="UNDOCUMENTED_MODAL_DESCRIPTION" />
        </Text>
        <ScrollView style={styles.modalContent} showsHorizontalScrollIndicator={false}>
          {pastBookings.length !== 0 && (
            <>
              {pastBookings.slice(0, 7).map(({ booking, contract }) => (
                <BookingListItem
                  key={booking?.bookingId}
                  booking={booking}
                  contract={contract}
                  showMonth
                  onPress={handleBookingSelected}
                />
              ))}
            </>
          )}
        </ScrollView>
        <View
          style={{ ...styles.modalButtons, alignSelf: Platform.OS === 'web' ? 'flex-end' : null }}
        >
          <ActionButton
            mode="outlined"
            onPress={handleHideModal}
          >
            <FormattedMessage id="DOCUMENT_LATER_MODAL" />
          </ActionButton>
          <ActionButton
            onPress={() => {
              setShowModal(false);
              handleNavigate();
            }}
          >
            <FormattedMessage id="DOCUMENT_NOW_MODAL" />
          </ActionButton>
        </View>
      </View>
    </Modal>
  );
};

export default DocumentModal;

import AsyncStorage from '@react-native-async-storage/async-storage';
import stores, { StoreName } from './stores';
import { logger } from '../Service';

export default async function asyncResetStores() {
  try {
    const storeKeys = Object.keys(stores) as string[];
    // Reset each store
    storeKeys.forEach((s) => {
      stores[s as StoreName].reset();
    });

    // Remove multiple items from AsyncStorage if storeKeys is an array
    if (Array.isArray(storeKeys) && storeKeys.length > 0) {
      await AsyncStorage.multiRemove(storeKeys);
    }
  } catch (e) {
    logger.error('Failed to reset stores', e);
  }
}
